
import BaseSelect from "./BaseSelect";
import personService from '../../services/PersonService';
import optionsService from '../../services/OptionsService';

class PersonSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	fetchOptions() {
		if (this.props.contractor) {
			return personService.options(this.props.contractor);
		}
		return Promise.resolve();
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.contractor?.id !== this.props.contractor?.id) {
			this.loaded = false;
			this.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default PersonSelect;