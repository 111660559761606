import {axios} from "../../utils/api/axios";

const BASE_PATH = "api/task-tracker/comment";

class CommentService {
    async save(comment) {
        const response = await axios.post(`${BASE_PATH}`, comment);
        return response.data;
    }

    async delete(id) {
        const response = await axios.delete(`${BASE_PATH}/${id}`);
        return response.data;
    }
}

export default new CommentService();