import PropTypes from 'prop-types';
import BaseSelect from "./BaseSelect";
import optionsService from '../../services/OptionsService';
import Enums, { APPROVAL_STATUSES } from '../../utils/consts/Enums';

class EnumSelect extends BaseSelect {

	constructor(props) {
		super(props);
	}

	renderLabel(value) {
		if (Enums[this.props.optionsType] && Enums[this.props.optionsType][value]) {
			return Enums[this.props.optionsType][value].label;
		}
		return value;
	}

	fetchOptions() {
		return this.props.fetchOptions || optionsService.load(this.props.optionsType);
	}

	getSingleValueStyleOverrides() {
		if (APPROVAL_STATUSES === Enums[this.props.optionsType]) {
			const statuses = [APPROVAL_STATUSES.NOT_APPROVED.value, APPROVAL_STATUSES.BLACK_LIST.value];
			if (this.props.value && statuses.includes(this.props.value.value)) {
				return {
					color: "red",
				};
			}
		}
	}
}

EnumSelect.propTypes = {
	optionsType: PropTypes.string.isRequired
};

export default EnumSelect;