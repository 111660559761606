import {axios} from '../../utils/api/axios';

const BASE_PATH = "api/report/task";

class ReportService {

    async linearReport(params) {
        const response = await axios.post(`${BASE_PATH}/linear`, params);
        return response.data;
    }
}

export default new ReportService();
