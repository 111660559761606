import {axios} from '../../utils/api/axios';

const BASE_PATH = "api/task-tracker/event";

class EventService {

    async list(params) {
        const response = await axios.post(`${BASE_PATH}/list`, params);
        return response.data;
    }
}

export default new EventService();