import {Component, ReactNode, ErrorInfo} from 'react';
import {ErrorPage} from "../../pages/ErrorPage/ErrorPage";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../reducers";

interface ErrorBoundaryProps extends PropsFromRedux {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    // Update state so the next render shows the fallback UI
    static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
        return {
            hasError: true,
            error: error.message
        };
    }

    componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>) {
        if (prevProps.criticalError !== this.props.criticalError && this.props.criticalError) {
            this.setState({
                hasError: true,
                error: this.props.criticalError
            })
        }
    }

    // Log the error or error details
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by Error Boundary: ", error, errorInfo, 123);
        // You can also report this error to an error reporting service if needed
    }

    render() {
        const {hasError, error} = this.state;
        const {children, userRoles} = this.props;
        const isAdmin = userRoles?.includes("ADMIN");
        return hasError
            ? <ErrorPage isShowError={isAdmin} error={error ?? "An unknown error occurred"}/>
            : children;
    }
}

const mapStateToProps = (state: RootState) => ({
    userRoles: state.auth?.user?.roles ?? [],
    criticalError: state.criticalError?.error ?? null,
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ErrorBoundary);