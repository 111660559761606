import {axios} from '../utils/api/axios';

const GENERAL = "users";
const EXTERNAL = "external-users";

class UserService {

	async loadByRole(role, activeOnly) {
		if(typeof activeOnly === 'undefined')
			activeOnly=true;
		const response = await axios.get(`/api/users/user/by-role/${role}?activeOnly=${activeOnly}`);
		return response.data;
	}
	
	async read(id) {
		const response = await axios.get(`/api/control-panel/users/${id}`);
		return response.data;
	}

	async list(params) {
		const response = await axios.post("/api/control-panel/users/list", params);
		return response.data;
	}
	
	async save(user) {
		const call = user.id ? axios.put : axios.post,
			response = await call("/api/control-panel/users", user);
		return response.data;
	}

	async createUserForDriver(user) {
		const response = await axios.post("/api/users/user/driver", user);
		return response.data;
	}

	async changePassword(params) {
		const response = await axios.put("/api/users/user/change-password", params);
		return response.data;
	}

	async isEmailUnique(id, email) {
		const response = await axios.post(`/api/users/email/unique`, {id, value: email});
		return response.data;
	}

	async isPhoneUnique(id, phone) {
		const response = await axios.post(`/api/users/phone/unique`, {id, value: phone});
		return response.data;
	}

	async readByDepartment(department) {
		const response = await axios.get(`/api/users/user/by-department/${department}`);
		return response.data;
	}

	async subscribeToMailing(subscribe) {
		const response = await axios.put(`/api/users/user/subscribe-to-mailing/${subscribe}`);
		return response.data;
	}
}

const userService = new UserService();

export {userService, GENERAL, EXTERNAL};