import {axios} from '../utils/api/axios';
import optionsService from './OptionsService';

class VehicleService {

	typeToLabel(vtype) {
		switch (vtype) {
			case 'TRUCK':
				return "Тягач";
			case 'TRAILER':
				return "Полуприцеп";
			case 'VAN':
				return "Фургон";
			case 'BUS':
				return "Автобус";
		}
		return vtype;
	}
	
	async read(id) {
		const response = await axios.get(`/api/control-panel/vehicles/${id}`);
		return response.data;
	}
	
	async list(params) {
		const response = await axios.post("/api/control-panel/vehicles/list", params);
		return response.data;
	}
	
	async save(vehicle) {
		const call = vehicle.id ? axios.put : axios.post,
			response = await call("/api/control-panel/vehicles", vehicle);
		return response.data;
	}

	async options(params) {
		const response = await axios.post("/api/vehicles/options", {...params, pageSize: 0, sort: {field: "model"}});
		const data = response.data.data || [];
		if (params.vtype === 'TRAILER') {
			data.unshift(optionsService.createEmptyOption());
		}
		return data;
	}

	async isUniqueNumber(id, number) {
		const response = await axios.post(`/api/control-panel/vehicles/unique-number`, {id, number: number});
		return response.data;
	}
	
	async isUniqueVin(id, vin) {
		const response = await axios.post(`/api/control-panel/vehicles/unique-vin`, {id, vin: vin});
		return response.data;
	}

	async isNotApproved(truckId, trailerId) {
		const response = await axios.get(`/api/vehicles/is-not-approved/${truckId}/${trailerId}`);
		return response.data;
	}
	async isNotApprovedTruckOnly(truckId) {
		const response = await axios.get(`/api/vehicles/is-not-approved/${truckId}`);
		return response.data;
	}
}

export default new VehicleService();