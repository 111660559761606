import cls from './Toast.module.css'

export interface ToastProps {
    title: string;
    message: string;
}

export const Toast = (props: ToastProps) => {
    const {
        title,
        message
    } = props;

    return (
        <div>
            <h2 className={cls.toastTitle}>{title}</h2>
            <p className={cls.text}>{message}</p>
        </div>
    )
}
