import React from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import { requiredValidator } from "../../validators/simpleValidators";
import ValidationErrors from "../../validators/ValidationErrors";
import BaseForm from '../form/BaseForm';

import { connect } from "react-redux";
import { register, confirmCode, smsRequest } from "../../actions/auth";
import { NavLink } from "react-router-dom";
import { setNotification } from "../../actions/notification";
import FormValidator from "../../validators/FormValidator";
import "./Registration.css"
import PhoneInput from "react-phone-input-2";

class Registration extends BaseForm {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			firstName: '',
			password: '',
			phone: '',
			lastName: '',
			middleName: '',
			email: '',
			id: '',
			code: '',
			privacy: false,
			errors: {},
			seconds: 60,
			showPassword: false
		};

		this.startTimer = this.startTimer.bind(this);

		this.handleRegistration = this.handleRegistration.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.handleShowPassword = this.handleShowPassword.bind(this);

		this.validator.validationMap = {
			phone: () => requiredValidator(this.state.phone),
			password: () => requiredValidator(this.state.password),
			firstName: () => requiredValidator(this.state.firstName),
			lastName: () => requiredValidator(this.state.lastName),
			middleName: () => requiredValidator(this.state.middleName),
		};
		this.validator.addValidator('phone', this.phoneValidator.bind(this));
	}

	phoneValidator() {
		const valid = this.state.phone.match(/^(7\d{10}|77\d{9}|375\d{9})$/);
		return valid ? FormValidator.OK : "некорректный номер телефона";
	}

	handleRegistration(e) {
		e.preventDefault();
		this.submit();
	}

	submit() {
		this.setSending(true);
		this.validator.validate().then(valid => {
			if (valid) {
				const { dispatch } = this.props;
				dispatch(register({
					username: this.state.phone.replace(/[\s-()+]/g, ''),
					password: this.state.password,
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					middleName: this.state.middleName,
					email: this.state.email,
				}))
					.then((res) => {
						this.setSending(false);
						this.setState({ id: res?.id });
					})
					.catch(() => this.setSending(false));
			} else {
				this.setSending(false);
			}
		})
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			errors: { [e.target.name]: null },
		});
	}

	onChangeCheckbox(e) {
		this.setState({ privacy: e.target.checked });
	}

	confirmCode() {
		if (this.state.code.length !== 6) {
			return this.setState({ errors: { code: 'Введите 6 цифр' } });
		}
		this.setSending(true);
		const { dispatch } = this.props;
		dispatch(confirmCode({
			id: this.state.id,
			code: this.state.code,
		}))
			.then(() => {
				dispatch(setNotification('Регистрация прошла успешно. Вы можете заполнить анкету для получения роли перевозчика.'));
				this.props.history.push('/login');
			})
			.catch(() => this.setSending(false));
	}

	smsRequest() {
		const {dispatch} = this.props;
		dispatch(smsRequest({id: this.state.id}))
			.then(() => {
				this.setState({seconds: 60})
				this.startTimer();
			})
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.setState(prevState => ({seconds: prevState.seconds - 1}));
		}, 1000);
	}

	renderForm() {
		const { message } = this.props;
		const {showPassword} = this.state;
		const linkSES = <NavLink to="/simple-electronic-signature" className="ses" target="_blank">Соглашения об использовании простой электронной подписи</NavLink>;
		const linkPrivacy = <NavLink to="/privacy-policy" className="privacy" target="_blank">Политикой конфиденциальности</NavLink>;
		return (
			<div className="col-md-12 registration">
				<div className="card card-container">
					<img
						src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
						alt="profile-img"
						className="profile-img-card"
					/>

					<Form onSubmit={this.handleRegistration}>
						<div className="d-flex">
							<div className="w-50 mr-4">
								<div className="form-group">
									<label htmlFor="firstName">Имя</label>
									<Form.Control
										placeholder="Имя"
										className="form-control"
										name="firstName"
										value={this.state.firstName}
										onChange={this.onChange}
										isInvalid={this.validator.isInvalid('firstName')}
									/>
									<ValidationErrors errors={this.validator.results?.['firstName']?.[0]}/>
								</div>

								<div className="form-group">
									<label htmlFor="lastName">Фамилия</label>
									<Form.Control
										placeholder="Фамилия"
										className="form-control"
										name="lastName"
										value={this.state.lastName}
										onChange={this.onChange}
										isInvalid={this.validator.isInvalid('lastName')}
									/>
									<ValidationErrors errors={this.validator.results?.['lastName']?.[0]}/>
								</div>

								<div className="form-group">
									<label htmlFor="middleName">Отчество</label>
									<Form.Control
										placeholder="Отчество"
										className="form-control"
										name="middleName"
										value={this.state.middleName}
										onChange={this.onChange}
										isInvalid={this.validator.isInvalid('middleName')}
									/>
									<ValidationErrors errors={this.validator.results?.['middleName']?.[0]}/>
								</div>
							</div>
							<div className="w-50">
								<div className="form-group">
									<label htmlFor="phone">Номер телефона</label>
									<PhoneInput
										specialLabel={''}
										onlyCountries={['ru', 'kz', 'by']}
										country={'ru'}
										value={this.state.phone}
										onChange={e => this.onChange({target: {name: "phone", value: e}})}
										autoFormat={true}
										isInvalid={this.validator.isInvalid('phone')}
										inputStyle={{
											width: '100%',
											height: "38px"
										}}
									/>
									<ValidationErrors errors={this.validator.results?.['phone']?.[0]}/>
								</div>

								<div className="form-group">
									<label htmlFor="password">Придумайте пароль</label>
									<InputGroup>
										<Form.Control
											type={showPassword ? "text" : "password"}
											className="form-control"
											name="password"
											value={this.state.password}
											onChange={this.onChange}
											isInvalid={this.validator.isInvalid('password')}
										/>
										<InputGroup.Append>
											<Button variant="outline-secondary" className="show-password-btn" onClick={this.handleShowPassword}>
												<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
											</Button>
										</InputGroup.Append>
									</InputGroup>
									<ValidationErrors errors={this.validator.results?.['password']?.[0]}/>
								</div>

								<div className="form-group">
									<label htmlFor="email">Email</label>
									<Form.Control
										placeholder="E-mail"
										className="form-control"
										name="email"
										value={this.state.email}
										onChange={this.onChange}
										isInvalid={this.validator.isInvalid('email')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group d-flex align-items-center">
							<Form.Check
								id="privacy"
								name="privacy"
								defaultChecked={false}
								value={this.state.privacy}
								onClick={(e) => this.onChangeCheckbox(e)}
							/>
							<NavLink to="/privacy-policy" target="_blank">Согласие с политикой конфиденциальности</NavLink>
						</div>

						{
							!this.state.id &&
							<div className="form-group">
								<button
									className="btn btn-primary btn-block"
									disabled={this.state.sending || !this.state.privacy}
									onClick={() => {
										this.startTimer()
									}}
								>
									{this.state.sending && (
										<span className="spinner-border spinner-border-sm"></span>
									)}
									<span>Зарегистрироваться</span>
								</button>
							</div>
						}

						<div className="form-group">
							Нажимая кнопку «Зарегистрироваться» я подтверждаю,
							что ознакомлен и согласен с условиями {linkSES} и {linkPrivacy},
							а так же согласен на получение информационной рассылки
						</div>

						{
							this.state.id &&
							<React.Fragment>
								<div className="form-group">
									<div className="d-flex">
										<Form.Control
											placeholder="Введите код из СМС"
											className="form-control mr-4"
											name="code"
											value={this.state.code}
											onChange={this.onChange}
											isInvalid={this.state.errors['code']}
											disabled={this.state.sending}
										/>

										<button
											className="btn btn-primary btn-block w-50"
											type="button"
											disabled={this.state.seconds > 0}
											onClick={() => this.smsRequest()}
										>
											<span>{this.state.seconds > 0 ? this.state.seconds : "Получить код"}</span>
										</button>
									</div>

									<ValidationErrors errors={this.state.errors['code']}/>
								</div>

								<div className="form-group">
									<button
										className="btn btn-primary btn-block"
										type="button"
										onClick={() => this.confirmCode()}
									>
										{this.state.sending && (
											<span className="spinner-border spinner-border-sm"></span>
										)}
										<span>Отправить</span>
									</button>
								</div>
							</React.Fragment>
						}

						<ValidationErrors errors={message}/>
					</Form>

					<NavLink to="/login">Авторизация</NavLink>
				</div>
			</div>
		);
	}

	handleShowPassword() {
		const {showPassword} = this.state;
		this.setState({showPassword: !showPassword});
	}
}

function mapStateToProps(state) {
	const { isLoggedIn } = state.auth;
	const { message } = state.message;
	return {
		isLoggedIn,
		message
	};
}

export default connect(mapStateToProps)(Registration);
