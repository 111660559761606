import {axios} from '../utils/api/axios';
import { Option } from "../utils/consts/Const";

class OptionsService {
	constructor(server) {
		this.cache = new Map();
		this.server = server;
	}

	static BASE_PATH = "/api/options";

	getBasePath() {
		return OptionsService.BASE_PATH;
	}

	createEmptyOption() {
		return {id: '', value: Option.EMPTY_VALUE};
	}

	async loadByValue(type, value) {
		if (this.cache.get(type)) {
			return this.cache.get(type).find(option => option.value === value);
		}
		const response = await axios.get(this.getBasePath() + `/${type}/${value}`);
		return response.data;
	}

	async load(type, activeOnly) {
		if (typeof activeOnly === 'undefined') {
			activeOnly = true;
		}
		const cacheKey = type + "?" + activeOnly;
		return this.loadAndCache(cacheKey, `${this.getBasePath()}/${type}?activeOnly=${activeOnly}`);
	}

	async loadContractorOptionsByOrganizationId(type, orgId) {
		const cacheKey = type + orgId;
		return this.loadAndCache(cacheKey, `${this.getBasePath()}/contractor/${type}/${orgId}`, false);
	}

	async loadDriverOptionsByContractorId(type, contractorId) {
		const cacheKey = type + contractorId;
		return this.loadAndCache(cacheKey, `${this.getBasePath()}/driver/${contractorId}`);
	}

	async loadModelOptionsByMarkId(type, markId) {
		const cacheKey = type + markId;
		return this.loadAndCache(cacheKey, `${this.getBasePath()}/model/${markId}`);
	}

	async loadAndCache(cacheKey, url, isNeedCache) {
		if (typeof isNeedCache === "undefined") {
			isNeedCache = true;
		}
		if (isNeedCache && this.cache.get(cacheKey)) {
			return this.cache.get(cacheKey);
		}
		const response = await axios.get(url);
		if (isNeedCache) {
			this.cache.set(cacheKey, response.data);
		}
		return response.data;
	}

}

export default new OptionsService();