import {axios} from '../utils/api/axios';

class ReportService {

	async ssp(dt, quoteStatusIdList) {
		const response = await axios.post('/api/report/ssp', {dt, quoteStatusIdList});
		return response.data;
	}

	async setPlan(department, userId, period, counter, value, quoteStatusIdList) {
		const response = await axios.put('/api/report/ssp/plan', {department, userId, period, counter, value, quoteStatusIdList});
		return response.data;
	}

	async sspYear(dt) {
		const response = await axios.post('/api/report/ssp/year', {dt});
		return response.data;
	}

	async sspYearMonth(dt,quoteStatusIdList) {
		const response = await axios.post('/api/report/ssp/year/month', {dt,quoteStatusIdList});
		return response.data;
	}

	async setYearPlan(department, userId, period, counter, value) {
		const response = await axios.put('/api/report/ssp/year/plan', {department, userId, period, counter, value});
		return response.data;
	}
}

export default new ReportService();