import store from "../../store";
import {logout} from "../../actions/auth";
import {AxiosError, AxiosResponse} from "axios";
import {setCriticalError} from "../../store/CriticalErrorStore/actions";
import {ToastService} from "../../services/ToastService/ToastService";

const standardMessage = "Произошла ошибка, перезагрузите страницу или попробуйте позже";

interface ConstructMessageArguments {
    errorMessage?: string;
    url?: string;
    data?: { error?: string, message?: string, messages?: string[] };
}

interface ErrorResponse {
    message?: string;
    subMessage?: string;
    subMessages?: string[]
}

export class ApiErrorHandler {
    static isAdmin(): boolean {
        const userRoles: string[] = store.getState()?.auth?.user?.roles ?? [];
        return userRoles.includes("ADMIN");
    }

    static constructMessage(args: ConstructMessageArguments): ErrorResponse {
        const {
            errorMessage,
            data,
            url
        } = args;

        if(!(this.isAdmin() && errorMessage)) {
            return {message: `${standardMessage};`};
        }

        let message = `Message: ${errorMessage};\n\n`;
        if (data?.error) {
            message += data?.error ? `Error type: ${data.error};\n\n` : "";
        }
        if (data?.message) {
            message += `${data?.message}\n\n`;
        }
        if (data?.messages) {
            message += `${data?.messages.join("\n")}\n\n`;
        }
        if (url) {
            message += `Url: ${url};`;
        }

        return {
            message: message,
            subMessage: data?.message,
            subMessages: data?.messages,
        };
    }

    static handleHttpError(httpError: AxiosError) {
        const response = httpError?.response as AxiosResponse<{ error: string }> | undefined;
        const status = response?.status;
        const message = httpError.message || "Unknown error occurred";
        const url = httpError?.config?.url || "Unknown URL";
        const data = response?.data || {};

        const errorResponse = this.constructMessage({
            errorMessage: message,
            data,
            url
        });

        switch (status) {
            case 401:
                this.handleUnauthorizedError(errorResponse.message);
                return;
            case 400:
            case 403:
            case 404:
            case 405:
            case 409:
            case 422:
            case 423:
                this.handlerBadRequestError(errorResponse);
                return;
            case 500:
                this.handleServerError(errorResponse.message);
                return;
            default:
                this.handlerDefaultError(errorResponse.message)
        }
    }

    static handleUnauthorizedError(errorMessage: string) {
        store.dispatch(logout());
    }

    static handlerBadRequestError(error : unknown) {
        const throwError = (message: string) => {
            ToastService.error({message: message});
        };

        const {subMessage, subMessages}: ErrorResponse = error as ErrorResponse;
        if (subMessage) {
            throwError(subMessage);
        }

        if (subMessages) {
            subMessages.forEach(it => throwError(it));
        }
    }

    static handleServerError(errorMessage: string) {
        store.dispatch(setCriticalError(errorMessage));
    }

    static handlerDefaultError(errorMessage: string) {
        store.dispatch(setCriticalError(errorMessage));
    }
}