
import BaseSelect from "./BaseSelect";
import { userService } from '../../services/UserService';

class UserSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	load() {
		this.setState({ loading: true });
		const fetch = this.props.fetchOptions ? this.props.fetchOptions() : this.fetchOptions();
		fetch.then(options => {
			options = this.props.filteredOptions || options || this.props.defaultOptions;
			const data = this.props.all ? [{id: -1, value: this.props.all}, ...options] : options;
			this.setState({ loading: false, options: this.props.optionsFilter ? data.filter(this.props.optionsFilter) : data });
		});
	}

	fetchOptions() {
		return this.props.role ? userService.loadByRole(this.props.role, this.props.activeOnly) : userService.load();	
	}
}

export default UserSelect;