import {axios} from '../utils/api/axios';

class ContactService {

	async list(params) {
		const response = await axios.post("/api/control-panel/contacts/list", params);
		return response.data;
	}

    async save(contact) {
		const call = contact.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/contacts", contact);
 		return response.data;
    }

	async delete(id, contractorId) {
		const response = await axios.post(`/api/control-panel/contacts/${id}/${contractorId}`);
 		return response.data;
    }

	async read(id) {
		const response = await axios.get(`/api/control-panel/contacts/${id}`);
		return response.data;
	}

    async readByPhone(phone) {
		const response = await axios.get(`/api/control-panel/contacts/phone/${phone}`);
		return response.data;
	}
    
}

export default new ContactService();