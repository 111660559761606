import React from "react";
import Dropzone from 'react-dropzone';
import fileStorageService, { initFileStorageService } from "../services/FileStorageService";

import { securityService } from '../services/SecurityService';
import AttachmentsTable from '../components/AttachmentsTable';
import { connect } from "react-redux";
import { v4 as uuid } from 'uuid';
import { createCancelToken } from '../utils/api/axios';
import moment from 'moment';
import { setValue } from '../actions/form';
import StoreWrapper from '../components/form/StoreWrapper';
import { Form } from "react-bootstrap";
import Util from '../utils/util/Util';

function getDisplayedName(attachment, index, fileNameFunction) {
	let displayedName = attachment.displayedName;
	if ((!displayedName || !displayedName.length) && attachment.originalName) {
		if (fileNameFunction) {
			displayedName = fileNameFunction(attachment, index);
		} else {
			displayedName = attachment.originalName;
		}
	}
	return displayedName;
}

function getAttachmentsFilter(props) {
	return a => {
		return (props.type ? a.type === props.type : true) && !a.removed;
	};
}

function getWithUpdatedNames(props, attachments) {
	let groupIndex = 0;
	return attachments.map((attachment) => {
		return getAttachmentsFilter(props)(attachment) ? ({...attachment, displayedName: getDisplayedName({...attachment, displayedName: null}, groupIndex++, props.fileNameFunction)}) : attachment;
	});
}

function mapStateToProps(state, ownProps) {
	let attachments = typeof ownProps.attachments !== 'undefined' ? ownProps.attachments || [] : StoreWrapper.getValue(state.model, ownProps.name);
	return {
		attachments: getWithUpdatedNames(ownProps, attachments?.sort((a, b) => {
			const dtA = new Date(a.dt),
				dtB = new Date(b.dt);
			return dtA > dtB ? 1 : (dtA < dtB ? -1 : (a.fileName > b.fileName ? 1 : -1));
		}) || [])
	};
}

class FileUploader extends React.PureComponent {

	static MAX_FILE_SIZE_MB = 19;
	static MAX_FILE_SIZE_BYTES = FileUploader.MAX_FILE_SIZE_MB * 1024 * 1024;
	static MAX_FILES_READ_ONLY = -1;
	static MAX_FILES_PER_QUOTE = 150;
	static MAX_FILES_PER_ENTITY = 50;
	static FILE_TYPES = {
		'image/*': [],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document':  ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 
		'application/pdf': ['.pdf']
	};

	static TO_ATTACHMENT(file, type) {
		return {
			dt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
			fileName: uuid(),
			displayedName: null,
			originalName: file.name,
			creator: securityService.getUser(),
			type: type,
			documentType: null,
			removed: false
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			fileItems: []
		}
		this.cancelTokenSource = createCancelToken();
		this.onChange = this.onChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.removeAttachment = this.removeAttachment.bind(this);
		this.removePending = this.removePending.bind(this);
	}

	componentWillUnmount() {
		this.state.currentUploadPromise ? this.state.currentUploadPromise.cancel() : null;
		this.cancelTokenSource ? this.cancelTokenSource.cancel() : null;
	}

	uploadNext() {
		if (this.state.fileItems && this.state.fileItems.length && this.state.currentUploadPromise == null) {
			const fileItem = this.state.fileItems.find(f => f.error == null && f.loaded == null);
			if (fileItem) {
				this.setState({
					currentUploadPromise: this.getCancelableUploadPromise(fileItem)
				});
			}
		}
	}

	getCancelableUploadPromise(fileItem) {
		const cancelablePromise = Util.makeCancelablePromise(fileStorageService.upload(fileItem.file, {
			cancelToken: this.cancelTokenSource.token,
			onUploadProgress: ({ loaded }) => this.updateFileItem(fileItem, { loaded: loaded }),
			onResponse: (res) => {
				if (res && res.data && res.data.endsWith(fileItem.attachment.fileName)) {
					this.onChange([
						...this.props.attachments,
						fileItem.attachment
					]);
					this.removePending(fileItem, () => Promise.resolve());
				} else {
					this.updateFileItem(fileItem, { error: "Ошибка загрузки" }, () => Promise.resolve());
				}
			},
		}));
		cancelablePromise.promise.then(() => {
			// this will be executed only if upper cancelable promise is not canceled in componentWillUnmount()
			this.setState({ currentUploadPromise: null }, this.uploadNext)
		});
		return cancelablePromise;
	}

	updateFileItem(fileItem, newData, callback) {
		this.setState((state) => ({
			fileItems: state.fileItems.map(f => f.attachment.fileName === fileItem.attachment.fileName ?
				{
					...f,
					...newData
				} : f)
		}), callback);
	}

	onChange(value) {
		const attachments = [...getWithUpdatedNames(this.props, value)];
		this.props.store.dispatch(setValue(this.props.name, attachments));
	}
	
	onAttachmentChange(value) {
		this.onChange(this.props.attachments.map(a => a.fileName === value.fileName ? {...a, ...value} : a));
	}

	removeAttachment(attachment) {
		this.onChange(this.props.attachments ? this.props.attachments.map(a => a.fileName === attachment.fileName ? {...a, removed: true} : a) : []);
	}

	removePending(fileItem, callback) {
		this.setState((state) => ({
			fileItems: state.fileItems.filter(f => f.attachment.fileName != fileItem.attachment.fileName)
		}), callback);
	}

	getFilesLimit() {
		return this.props.filesLimit || FileUploader.MAX_FILES_PER_ENTITY;
	}

	getFilesLimitLeft() {
		const limitLeft = this.getFilesLimit() - this.getAttachmentsLength() - this.state.fileItems.filter(f => f.error == null).length;
		return limitLeft >= 0 ? limitLeft : 0;
	}

	onDrop(files) {
		if (files && files.length) {
			files = files.filter(f => f.size <= FileUploader.MAX_FILE_SIZE_BYTES).slice(0, this.getFilesLimitLeft());
			this.setState((state) => ({
				fileItems: [
					...state.fileItems,
					...files.map(file => {
						const attachment = FileUploader.TO_ATTACHMENT(file, this.props.type);
						return {
							attachment: attachment,
							file: new File([file], attachment.fileName, { type: file.type, lastModified: file.lastModified }),
						}
					})
				]
			}), this.uploadNext);
		}
	}

	getAttachmentsLength() {
		let attachments = this.props.attachments.filter(a => !a.removed);
		return attachments.length;
	}

	getAttachments() {
		return this.props.attachments.filter(getAttachmentsFilter(this.props)).map((a, i) => ({ "sequentialNumber": i, "attachment": a }));
	}

	render() {
		const fileStorageFunctions = {
			download: fileStorageService.download,
			preview: fileStorageService.preview,
			getDirectLink: fileStorageService.getDirectLink
		};
		const showDropzone = this.props.hideDropzpne === undefined || this.props.hideDropzpne === false;
		return (
			<Form.Group>
				{this.props.title && <Form.Label>{this.props.title}</Form.Label>}
				<AttachmentsTable
					fileStorageService={fileStorageFunctions}
					removeRow={this.removeAttachment}
					removePending={this.removePending}
					attachments={this.getAttachments()}
					onChange={(value) => this.onAttachmentChange(value)}
					pendingFiles={this.state.fileItems}
					showFileName={this.props.showFileName}
					showDownloadLink={this.props.showDownloadLink}
					optionsType={this.props.optionsType}
					showType={this.props.showType}
					showDocumentType={this.props.showDocumentType}
					hideRemove={this.props.hideRemove}
					server={this.props.server}
				/>
				{showDropzone && (!this.getFilesLimit() || this.getFilesLimitLeft() > 0) &&
					<Dropzone onDrop={this.onDrop} accept={this.props.acceptedFileTypes || FileUploader.FILE_TYPES} useFsAccessApi={false} disabled={this.props.disabled}>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()}  />
								<div className="drop-tip">Перетащите файлы или нажмите, чтобы добавить</div>
								<div>Доступно для загрузки еще {`${this.getFilesLimitLeft()}`} файлов. Макс. размер файла {FileUploader.MAX_FILE_SIZE_MB}MB</div>
							</div>
						)}
					</Dropzone>
				}
			</Form.Group>
		);
	}

}

export default connect(mapStateToProps)(FileUploader);