import React from "react";
import {connect} from 'react-redux';
import { Form, Col } from "react-bootstrap";
import ValidationErrors from "../../validators/ValidationErrors";
import DictionarySelect from "./DictionarySelect";
import DtPicker from "./DtPicker";
import UserSelect from "./UserSelect";
import PersonSelect from "./PersonSelect";
import DriverSelect from "./DriverSelect";
import RequestStatusSelect from "./RequestStatusSelect";
import VehicleSelect from "./VehicleSelect";
import AddressSelect from "./AddressSelect";
import PaymentAccountSelect from "./PaymentAccountSelect";
import EnumSelect from "./EnumSelect";
import { setValue } from '../../actions/form';
import StoreWrapper from './StoreWrapper';
import ContractSelect from "./ContractSelect";
import CitySelect from "./CitySelect";
import TaskSelect from "./tasktracker/TaskSelect";
import TimePicker from "./TimePicker";
import Select from "react-select";
import VehicleMarkModelSelect from "../control-panel/vehiclemarkmodel/VehicleMarkModelSelect";
import ContractorSelect from "./ContractorSelect";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import MaskedInput from "react-maskedinput"

function mapStateToProps(state, ownProps) {
	if (state && ownProps.name) {
		const value = StoreWrapper.getValue(state.model, ownProps.name);
		return {
			model: state.model,
			value: value !== undefined ? value : '',
			errors: state.errors[ownProps.name],
			user: state.user
		}
	} else {
		return {value: ownProps.value || '', errors: []};
	}
}

class FormGroup extends React.PureComponent {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.renderPhone = this.renderPhone.bind(this)
		this.state= {
			isCleanPhone: false
		}
	}

	onChange(value) {
		const inputValue = !!value ? value : null;
		if (this.props.store) {
			this.props.store.dispatch(setValue(this.props.name, inputValue));
		}
		if (this.props.onChangeHandler) {
			this.props.onChangeHandler(inputValue);
		}
	}

	renderFormControl() {
		switch (this.props.type) {
			case 'checkbox':
				return this.renderCheckbox();
			case 'dictionary':
				return this.renderDictionarySelect();
			case 'datetime':
				return this.renderDatePicker(true);
			case 'date':
				return this.renderDatePicker(false);
			case 'time':
				return this.renderTimePicker();
			case 'user':
				return this.renderUserSelect();
			case 'person':
				return this.renderPersonSelect();
			case 'driver':
				return this.renderDriverSelect();
			case 'vehicle':
				return this.renderVehicleSelect();
			case 'vehicleMarkModel':
				return this.renderVehicleMarkModelSelect();
			case 'city':
				return this.renderCitySelect();
			case 'address':
				return this.renderAddressSelect();
			case 'enum':
				return this.renderEnumSelect();
			case 'status':
				return this.renderStatusSelect();
			case 'paymentAccount':
				return this.renderPaymentAccountSelect();
			case 'textarea':
				return this.renderTextArea();
			case 'money':
			case 'decimal':
			case 'number':
				return this.renderText("number");
			case 'email':
				return this.renderText("email");
			case 'phone':
				return this.renderPhone();
			case 'contract':
				return this.renderContractSelect();
			case 'task':
				return this.renderTaskSelect();
			case 'contractor':
				return this.renderContractorSelect();
			case 'plainText':
				return this.renderPlainText();
			case 'yes-no':
				return this.renderYesOrNoSelect();
			default:
				return this.renderText();
		}
	}

	renderPlainText() {
		return (
			<div>{this.props.value}</div>
		);
	}

	renderPhone() {
		this.props.value && this.onChange(this.props.value.replace(/\D/g, ''))
		return <PhoneInput
			specialLabel={''}
			onlyCountries={['ru', 'kz', 'by']}
			disabled={this.props.readOnly}
			country={'ru'}
			value={this.props.value}
			placeholder={this.props.placeholder || "Введите номер телефона"}
			onChange={e => this.onChange(e)}
			autoFormat={true}
			isValid={(value, country) => {
				if (value.match(/12345/)) {
					return false;
				} else if (value.match(/1234/)) {
					return false;
				} else {
					return true;
				}
			}}
			inputStyle={{
				width: '100%'
			}}
		/>
	}

	renderText(type) {
		const onChange = (value) => {this.onChange(value && type === "email" ? value.toLowerCase() : value)};
		return (
			<React.Fragment>
				{this.props.renderCustomButton && this.renderCustomButton()}
				<Form.Control as={this.props.mask && MaskedInput} mask={this.props.mask} size="sm" type={type || "text"}
							  name={this.props.name}
							  onChange={e => onChange(e.target.value)}
							  placeholder={this.props.placeholder}
							  value={(this.props.value ? this.props.value : (this.props.defaultValue || ""))}
							  readOnly={this.props.readOnly}
							  onInput={(e) => e.target.value = e.target.value.slice(0, this.props.maxLength || 100)}
							  maxLength={this.props.maxLength || 100}
				/>
			</React.Fragment>
		);
	}


	renderCustomButton() {
		return <React.Fragment>
			&nbsp;{this.props.renderCustomButton()}
		</React.Fragment>;
	}

	renderTextArea() {
		return (
			<Form.Control className="form-control form-control-sm" as="textarea" onChange={e => this.onChange(e.target.value)}
			  	rows={this.props.rows} name={this.props.name}
				value={this.props.value} readOnly={this.props.readOnly} />
		);
	}

	renderYesOrNoSelect() {
		const yes = { value: true, label: "Да" };
		const no = { value: false, label: "Нет" };

		let value;
		switch (this.props.value) {
			case true: value = yes; break;
			case false: value = no; break;
			default: value = null;
		}

		return <Select
			name={this.props.name}
			placeholder={this.props.placeholder || "(выбрать)"}
			onChange={e => this.onChange(e.value)}
			readOnly={this.props.readOnly}
			value={value}
			options={[ yes, no ]}
		/>
	}

	renderCheckbox() {
		return (
			{...!this.props.readOnly
				? <Form.Check
						id={this.props.id}
						name={this.props.name}
						defaultChecked={this.props.defaultChecked}
						onClick={this.props.onClick}
				  />
				: <div>{this.props.defaultChecked ? "да" : "нет"}</div>
			}
		);
	}

	renderDatePicker(showTimeSelect) {
		return (
			<DtPicker
				showTimeSelect={showTimeSelect}
				value={this.props.value}
				isClearable={this.props.isClearable}
				placeholder={this.props.placeholder}
				name={this.props.name}
				onChange={this.onChange}
				readOnly={this.props.readOnly}
				isInvalid={this.isInvalid()} />
		);
	}

	renderTimePicker() {
		return (
			<TimePicker
				value={this.props.value}
				placeholder={this.props.placeholder}
				name={this.props.name}
				onChange={this.props.onChange}
				readOnly={this.props.readOnly} />
		);
	}

	renderDictionarySelect() {
		return (
			<DictionarySelect
				all={this.props.all}
				placeholder={this.props.placeholder}
				name={this.props.name}
				onChange={this.onChange}
				optionsType={this.props.optionsType}
				optionsFilter={this.props.optionsFilter}
				filteredOptions={this.props.filteredOptions}
				fetchOptions={this.props.fetchOptions}
				defaultOptions={this.props.defaultOptions}
				isInvalid={this.isInvalid()}
				showViewIcon={this.props.showViewIcon}
				showClearIcon={this.props.showClearIcon}
				renderCustomButton={this.props.renderCustomButton}
				value={this.props.value}
				multiSelect={this.props.multiSelect}
				readOnly={this.props.readOnly}
				activeOnly={this.props.activeOnly} />
		);
	}

	renderUserSelect() {
		return (
			<UserSelect
				placeholder={this.props.placeholder}
				name={this.props.name}
				onChange={this.onChange}
				role={this.props.role}
				isInvalid={this.isInvalid()}
				optionsFilter={this.props.optionsFilter}
				value={this.props.value}
				multiSelect={this.props.multiSelect}
				showClearIcon={this.props.showClearIcon}
				readOnly={this.props.readOnly} />
		);
	}

	renderPersonSelect() {
		return (
			<PersonSelect
				name={this.props.name}
				onChange={this.onChange}
				contractor={this.props.contractor}
				isInvalid={this.isInvalid()}
				value={this.props.value}
				readOnly={this.props.readOnly} />
		);
	}

	renderDriverSelect() {
		const contractor = this.props.model.carrier || this.props.model.contractor;
		return (
			<DriverSelect
				name={this.props.name}
				optionsType={this.props.optionsType}
				contractor={contractor}
				onChange={this.props.onChange || this.onChange}
				isInvalid={this.isInvalid()}
				value={this.props.value}
				showViewIcon={this.props.showViewIcon}
				showClearIcon={this.props.showClearIcon}
				readOnly={this.props.readOnly} />
		);
	}

	renderVehicleSelect() {
		const contractor = this.props.model.carrier || this.props.model.contractor;
		return (
			<VehicleSelect
				name={this.props.name}
				contractor={contractor}
				vtype={this.props.vtype}
				onChange={this.onChange}
				isInvalid={this.isInvalid()}
				value={this.props.value}
				showViewIcon={this.props.showViewIcon}
				showClearIcon={this.props.showClearIcon}
				readOnly={this.props.readOnly} />
		);
	}

	renderCitySelect() {
		return (
			<CitySelect
				name={this.props.name}
				menuPosition={"fixed"}
				closeMenuOnScroll={true}
				contractor={this.props.contractor}
				onChange={this.props.onChange}
				readOnly={this.props.readOnly}
				value={this.props.value}
			/>
		);
	}

	renderAddressSelect() {
		return (
			<AddressSelect
				name={this.props.name}
				menuPosition={"fixed"}
				closeMenuOnScroll={true}
				readOnly={!this.props.city?.id}
				contractor={this.props.contractor}
				city={this.props.city}
				onChange={this.props.onChange}
				value={this.props.value}
			/>
		);
	}

	renderPaymentAccountSelect() {
		return (
			<PaymentAccountSelect
				name={this.props.name}
				onChange={this.onChange}
				owner={this.props.params.owner}
				field={this.props.params.field}
				isInvalid={this.isInvalid()}
				value={this.props.value}
				readOnly={this.props.readOnly} />
		);
	}

	renderEnumSelect() {
		return (
			<EnumSelect
				name={this.props.name}
				placeholder={this.props.placeholder}
				onChange={this.onChange}
				isInvalid={this.isInvalid()}
				optionsType={this.props.optionsType}
				fetchOptions={this.props.fetchOptions}
				readOnly={this.props.readOnly}
				value={this.props.value}
				multiSelect={this.props.multiSelect}
				showClearIcon={this.props.showClearIcon}/>
		);
	}

	renderStatusSelect() {
		return (
			<RequestStatusSelect
				all={this.props.all}
				placeholder={this.props.placeholder}
				user={this.props.user}
				model={this.props.model}
				name={this.props.name}
				onChange={this.onChange}
				isInvalid={this.isInvalid()}
				showViewIcon={this.props.showViewIcon}
				filteredOptions={this.props.filteredOptions}
				readOnly={this.props.readOnly}
				value={this.props.value} />
		);
	}

	renderContractSelect() {
		return (
			<ContractSelect
				name={this.props.name}
				onChange={this.onChange}
				contractor={this.props.contractor}
				contractorTypeId={this.props.contractorTypeId}
				organization={this.props.organization}
				isInvalid={this.isInvalid()}
				value={this.props.value}
				readOnly={this.props.readOnly} />
		);
	}

	renderTaskSelect() {
		return (
			<TaskSelect
				name={this.props.name}
				onChange={this.onChange}
				value={this.props.value}
				readOnly={this.props.readOnly}
				id={this.props.id}
				parentTaskId={this.props.parentTaskId}
				currentTaskId={this.props.currentTaskId}
				taskType={this.props.taskType}
				showViewIcon={this.props.showViewIcon}
				showClearIcon={this.props.showClearIcon} />
		);
	}

	renderVehicleMarkModelSelect() {
		return (
			<VehicleMarkModelSelect
				name={this.props.name}
				onChange={this.onChange}
				optionsType={this.props.optionsType}
				fetchOptions={this.props.fetchOptions}
				value={this.props.value}
				markId={this.props.markId}
				vehicleMark={this.props.vehicleMark}
				readOnly={this.props.readOnly} />
		);
	}

	renderContractorSelect() {
		return (
			<ContractorSelect
				name={this.props.name}
				placeholder={this.props.placeholder}
				onChange={this.onChange}
				value={this.props.value}
				multiSelect={this.props.multiSelect}
				readOnly={this.props.readOnly}
			/>
		);
	}

	isInvalid() {
		const errors = this.getErrors();
		return 	errors && errors.length > 0;
	}
	
	getErrors() {
		return this.props.errors || [];
	}

	asRow() {
		return this.props.asRow === true;
	}

	boxLeftSide() {
		return this.props.boxLeftSide === true;
	}

	renderRequired() {
		return this.props.required && <span className="required-field" />;
	}

	renderCopyIcon() {
		return this.props.showCopyIcon && <i className={"fas fa-solid fa-copy copy-icon"} onClick={this.props.copyProcess}></i>
	}

	renderClearIcon() {
		return this.props.showClearIcon && this.props.value && <i className={"fa fa-times clear-icon"} onClick={() => this.onChange(null)}></i>
	}

	renderAsRow() {
		return (
			<div>
				{this.boxLeftSide() ? <Form.Group>
					<div className={"d-flex"}>
						<div className={"flex-column"}>
							{this.renderFormControl()}
							<ValidationErrors errors={this.getErrors()} />
						</div>
						&nbsp;&nbsp;
						<span className={this.getErrors().length > 0 ? "align-self-start" : "align-self-center"}>{this.props.title}</span>
						&nbsp;
						{this.renderClearIcon()}
						{this.renderRequired()}
					</div>
				</Form.Group> :
					<Form.Group>
						<div className={"d-flex"}>
							<span className={this.getErrors().length > 0 ? "align-self-start" : "align-self-center"}>{this.props.title}</span>
							&nbsp;&nbsp;
							<div className={"flex-column"}>
								{this.renderFormControl()}
								<ValidationErrors errors={this.getErrors()} />
							</div>
							&nbsp;
							{this.renderClearIcon()}
							{this.renderRequired()}
						</div>
					</Form.Group>
				}
			</div>
		);
	}

	renderDefault() {
		const {className} = this.props;
		return (
			<Form.Group className={className}>
				{this.props.title &&
					<Form.Label>
						{this.props.title}
						&nbsp;
						{this.renderCopyIcon()}
						&nbsp;
						{this.renderClearIcon()}
					</Form.Label>}
				{this.renderRequired()}
				{this.renderFormControl()}
				<ValidationErrors errors={this.getErrors()} />
			</Form.Group>
		)
	}

	render() {
		return (
			<Col>
				{this.asRow() ? this.renderAsRow() : this.renderDefault()}
			</Col>
		);
	}
}

export default connect(mapStateToProps)(FormGroup);