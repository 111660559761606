import {axios} from "../utils/api/axios";
import settingsService from "./SettingsService";
import dateTimeService from "./DateTimeService";

class MapService {

    async getLatestDriversGeolocation(data) {
        const response = await axios.post(`/api/maps/drivers/last-geolocation`, data);
        return response.data;
    }

    async getActualRoute(data) {
        const response = await axios.post(`/api/maps/driver/actual-route`, data);
        return response.data;
    }

    async getScoutActualRoute(data) {
        const response = await axios.post(`/api/maps/driver/scout-actual-route`, data);
        return response.data;
    }

    async getPlannedRoute(data) {
        const response = await axios.post(`/api/maps/driver/planned-route`, data);
        return response.data;
    }

    setScriptToHtmlPage() {
        return settingsService.get("yandexMapApiKey")
            .then(key => {
                return new Promise((resolve, reject) => {
                    const script = document.createElement("script");
                    script.src = `https://api-maps.yandex.ru/2.1.79/?apikey=${key}&lang=ru_RU`;
                    script.onload = resolve;
                    script.onerror = reject;
                    document.head.appendChild(script);
                });
            });
    }

    async getQuotesList(data) {
        const response = await axios.post(`/api/maps/quotes`, data);
        return response.data;
    }

    async getQuoteAddress(quoteId) {
        const response = await axios.get(`/api/maps/quote/${quoteId}`);
        return response.data;
    }

    async getDriverActualRouteByQuoteId(data) {
        const response = await axios.post(`/api/maps/quote/actual-route`, data);
        return response.data;
    }

    async getQuoteStatusesChanges(quoteId) {
        const response = await axios.get(`/api/maps/quote/status-changes/${quoteId}`);
        return response.data;
    }

    async getUserLastGeolocationByQuoteId(quoteId) {
        const response = await axios.get(`/api/maps/quote/last-geolocation/${quoteId}`);
        return response.data;
    }

    isActive(dt) {
        return new Date(dt) > dateTimeService.subtractHours(new Date(), 1);
    }
}

export default new MapService();