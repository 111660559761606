import React from "react";
import { connect } from 'react-redux';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import approvalService from '../../../services/ApprovalService';
import DataTable from '../../table/DataTable';
import { APPROVAL_ENTITIES, APPROVAL_STATUSES, ENUM_NAME } from '../../../utils/consts/Enums';
import TableCell from "../../table/TableCell";

class Approval extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			entity: APPROVAL_ENTITIES.CONTRACTOR,
			counts: {}
		}
		this.openEditForm = this.openEditForm.bind(this);
		this.renderLinkRow = this.renderLinkRow.bind(this);
	}

	componentDidMount() {
		this.setEntityFromLocation();
		this.fetchCounts();
	}

	setEntityFromLocation() {
		const entity = APPROVAL_ENTITIES[new URLSearchParams(this.props.location.search).get("entity")];
		if (entity) {
			this.setState({
				entity: entity
			});
		}
	}

	async fetchCounts() {
		const {counts} = this.props;
		if (counts) {
			this.setState({counts: counts});
			return;
		}

		const result = await approvalService.counts();
		this.setState({
			counts: result.reduce((counts, count) => ({
				...counts,
				[count.entity]: {totalCount: count.totalCount, countOfNew: count.countOfNew}
			}), {})
		});
	}

	fetch(params) {
		return approvalService.list({
			...params,
			entity: this.state.entity.value
		});
	}

	openEditForm(row) {
		const backUrl = encodeURIComponent(`${this.props.location.pathname}?entity=${this.state.entity.value}`);
		switch (this.state.entity) {
			case APPROVAL_ENTITIES.CONTRACTOR:
				this.props.history.push({ pathname: `/control-panel/contractors/contractor?backUrl=${backUrl}`, state: { id: row.id } });
				location.reload();
				return;
			case APPROVAL_ENTITIES.DRIVER:
				this.props.history.push({ pathname: `/control-panel/drivers/driver?backUrl=${backUrl}`, state: { id: row.id } });
				location.reload();
				return;
			case APPROVAL_ENTITIES.VEHICLE:
				this.props.history.push({ pathname: `/control-panel/vehicles/vehicle?backUrl=${backUrl}`, state: { id: row.id } });
				location.reload();
				return;
		}
	}

	renderLinkRow(value, row) {
		return (
			<a href={""} onClick={() => this.openEditForm(row)}>
				{value}
			</a>
		);
	}

	render() {
		const {counts} = this.state;
		return (
			<div>
				<ToggleButtonGroup className="mb-2"
					size="sm"
					name="entity"
					value={this.state.entity.value}
					type="radio">
					{Object.keys(APPROVAL_ENTITIES).map((key) => (
						<ToggleButton
							key={`entity${key}`}
							value={key}
							variant="outline-primary"
							checked={this.state.entity.value === key}
							onChange={() => this.setState({
								entity: APPROVAL_ENTITIES[key]
							})}
						>
							{APPROVAL_ENTITIES[key].label} {this.formatLabel(counts[key])}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				<DataTable {...this.props}
					title = {`Согласование - ${this.state.entity.label}`} // different names to store different state in local storage 
					sort = {{field: 'name', dir: 'asc'}}
					openEditForm = {this.openEditForm.bind(this)}
					fetch = {this.fetch.bind(this)}
					columns = {[
						{ title: "Название", field: "name", filter: DataTable.STRING_FILTER, formatter: this.renderLinkRow },
						{ title: "Статус согласования", field: "approvalStatus", filter: DataTable.ENUM_FILTER, 
							optionsType: ENUM_NAME.APPROVAL_STATUS,
							formatter: TableCell.enumFormatter(APPROVAL_STATUSES) }
					]}
				/>
			</div>
		);
	}

	formatLabel(value) {
		return value ? `(${value.totalCount}) +${value.countOfNew}` : "";
	}
	
}

export default connect(DataTable.mapStateToProps)(Approval);
