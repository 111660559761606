import BaseForm from "../../form/BaseForm";
import React from "react";
import FormGroup from "../../form/FormGroup";
import {Col, Modal, Form, Button} from "react-bootstrap";
import taskService from "../../../services/tasktracker/TaskService";
import {requiredValidator} from "../../../validators/simpleValidators";
import StoreWrapper from "../../form/StoreWrapper";
import {connect} from "react-redux";
import FormValidator from "../../../validators/FormValidator";
import {TaskTopic} from "../../../utils/consts/Const";
import TinyEditor from "../TinyEditor";
import {ENUM_NAME} from "../../../utils/consts/Enums";

class TaskModal extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <TaskModalInnerConnected {...this.props} store={this.store} />
        );
    }

}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        user: state.user
    };
}

class TaskModalInner extends BaseForm {

    constructor(props) {
        super(props);
        this.configureValidators();
        this.initDefault();
        this.handleEditDescription = this.handleEditDescription.bind(this)
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "parentTask", "status", "type", "topic", "assignees", "reporters", "department", "division");
        this.validator.addValidator('title', this.titleValidator.bind(this));
    }

    titleValidator() {
        return (this.props.model.topic?.id === TaskTopic.OTHER().id) && !this.props.model.title ? "обязательное поле" : FormValidator.OK;
    }

    initDefault() {
        this.onChange("department", this.props.department);
        this.onChange("parentTask", this.props.parentTask);
        this.onChange("status", this.props.status);
        this.onChange("type", this.props.type);
    }

    handleEditDescription = (content, editor) => {
        this.onChange("description", content);
    };

    renderFields() {
        return(
            <Form style={{}}>
                <Col>
                    <FormGroup title="Подразделение"
                               name="department"
                               type="dictionary"
                               optionsType="DEPARTMENT"
                               store={this.props.store}
                               activeOnly={true}
                               required
                    />
                    <FormGroup title="Отдел"
                               name="division"
                               type="dictionary"
                               optionsType="DIVISION"
                               store={this.props.store}
                               activeOnly={true}
                               required
                    />
                    <FormGroup title="Родительская задача"
                               name="parentTask"
                               type="task"
                               taskType="parent"
                               store={this.props.store}
                               readOnly={true}
                               required
                    />
                    <FormGroup title="Выполнить после"
                               name="previousTask"
                               type="task"
                               taskType="previous"
                               store={this.props.store}
                               id={this.props.model.previousTask?.id}
                               parentTaskId={this.props.model.parentTask?.id}
                               currentTaskId={this.props.model?.id}
                               showViewIcon={true}
                               showClearIcon={true}
                    />
                    <FormGroup title="Статус"
                               name="status"
                               type="enum"
                               optionsType={ENUM_NAME.TASK_STATUS}
                               store={this.props.store}
                               readOnly={true}
                               required
                    />
                    <FormGroup title="Тип"
                               name="type"
                               type="dictionary"
                               optionsType="TASK_TYPE"
                               store={this.props.store}
                               readOnly={true}
                               required
                    />
                    <FormGroup title="Тема"
                               name="topic"
                               type="dictionary"
                               optionsType="TOPIC"
                               store={this.props.store}
                               required
                    />
                    {this.props.model.topic?.id === TaskTopic.OTHER().id &&
                        <FormGroup title="Название задачи"
                                   name="title"
                                   type="text"
                                   store={this.props.store}
                                   required
                        />
                    }
                    <FormGroup title="Ответственные"
                               name="assignees"
                               type="dictionary"
                               optionsType="CRM_USER"
                               store={this.props.store}
                               multiSelect={true}
                               activeOnly={true}
                               required
                    />
                    <FormGroup title="Проверяющие"
                               name="reporters"
                               type="dictionary"
                               optionsType="CRM_USER"
                               store={this.props.store}
                               multiSelect={true}
                               activeOnly={true}
                               required
                    />
                    <TinyEditor
                        title={"Описание"}
                        handleEdit={this.handleEditDescription}
                        value={this.props.model.description}
                        styles={{padding: "0 10px"}}
                    />
                </Col>
            </Form>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setSending(true);
        this.submit(() =>
            taskService.save(this.props.model)
                .then(response => {
                    this.onChange("id", response.id)
                    this.setSending(false);
                    this.props.onAddTask(response);
                    this.props.onShow(false);
                })
                .catch(() => this.setSending(false)));
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>Добавление подзадачи</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderFields()}
                </Modal.Body>
                <Modal.Footer className={"justify-content-center"}>
                    <Button variant="primary" size="sm" disabled={this.state.sending} onClick={(e) => this.handleSubmit(e)}>Сохранить</Button>
                    <Button variant="secondary" size="sm" disabled={this.state.sending} onClick={() => this.props.onShow(false)}>Выйти</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const TaskModalInnerConnected = connect(mapStateToProps)(TaskModalInner);
export default  connect(mapStateToProps)(TaskModal);