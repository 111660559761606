import {axios} from '../utils/api/axios';

class ConsolidateReport {

    async reportLogistic(dt,quoteStatusIdList) {
        const response = await axios.post('/api/consolidated/reports/logisticReport', {dt,quoteStatusIdList});
        return response.data;
    }

    async reportCommercial(dt, quoteStatusIdList,usersLogistic,usersCommercial,department) {
        const response = await axios.post('/api/consolidated/reports/commercialReport', {dt,quoteStatusIdList,
            usersLogistic,usersCommercial,department});
        return response.data;
    }

}

export default new ConsolidateReport();