import {axios} from '../utils/api/axios';

class ExportImportService {

	static BASE_PATH = "/api/export-import";
	static TIMEOUT = 1000 * 60 * 60;

	static prepareFormData(file) {
		const formData = new FormData();
		formData.append('file', file);
		return formData;
	}

	static prepareMultipartConfig(progressHandler) {
		return {
			headers: {
				'content-type': 'multipart/form-data',
			},
			onUploadProgress: progressHandler,
		}
	}

	async status(isFull, directions) {
		const directionsQueryParam = directions && ("directions=" + directions.join(','));
		const response = await axios.get(`${ExportImportService.BASE_PATH}/status${isFull ? "/full" + ( directionsQueryParam ? "?" + directionsQueryParam : "" ) : ""}`);
		return response.data;
	}

	async saveSchedule(time) {
		await axios.get(`${ExportImportService.BASE_PATH}/export/schedule?time=${time}`);
	}

	async downloadXLSFile(direction, fileName) {
		const response = await axios(`${ExportImportService.BASE_PATH}/${direction}/download/${fileName}`, {
			method: 'GET',
			responseType: 'blob'
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
		})
	}

	download(response) {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'выгрузка.xlsx');
		document.body.appendChild(link);
		link.click();
	}

	async slImportFile(type, file, progressHandler) {
		const response = await axios.post(`${ExportImportService.BASE_PATH}/import/${type}`, ExportImportService.prepareFormData(file), {
			...ExportImportService.prepareMultipartConfig(progressHandler),
			timeout: ExportImportService.TIMEOUT,
		}).catch((err) => console.log("ERR in request", err));
		return response ? response.data : null;
	}

	async slQuoteExport() {
		const response = await axios.get(`${ExportImportService.BASE_PATH}/export/quote`);
		return response ? response.data : null;
	}

	async xlsQuoteExport(params) {
		const response = await axios.post(`${ExportImportService.BASE_PATH}/export/quote/xls`,
			params, {responseType: 'blob'});
		this.download(response)
		return response ? response.data : null;
	}

	async xlsQuoteRouteExport(params) {
		const response = await axios.post(`${ExportImportService.BASE_PATH}/export/quote-route/xls`, params);
		return response ? response.data : null;
	}

	async loadQuoteListBySupplyFile(file) {
		const response = await axios.post(`${ExportImportService.BASE_PATH}/load-quotes/supply`,
			ExportImportService.prepareFormData(file));
		return response ? response.data : null;
	}

	async importClientPriceFromSupplies(supplies) {
		const response = await axios.post(`${ExportImportService.BASE_PATH}/import/supply`, supplies);
		return response ? response.data : null;
	}

}

export default new ExportImportService();