import {PureComponent} from "react";

class PinIcon extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="15" height="15" x="0" y="0"
                 viewBox="0 0 64 64" style={{verticalAlign: "baseline"}}>
                <g>
                    <path
                        d="m48.83 24.39-9.1-8.95-.32-.31a8.063 8.063 0 0 1-1.01-1.19l-10.81 7.8a12.397 12.397 0 0 1 2.34 1.82l10.58 10.39c.23.23.45.46.66.7a14.235 14.235 0 0 1 1.25 1.67l7.94-10.62a9.972 9.972 0 0 1-1.53-1.31zM41.44 37.62c-.817-2.046-10.968-11.216-12.56-12.99a11.759 11.759 0 0 0-16.37-.01l-2.26 2.23a1.01 1.01 0 0 0 0 1.43c7.273 7.149 18.264 17.956 25.54 25.1a1.005 1.005 0 0 0 1.41 0l2.26-2.23a11.296 11.296 0 0 0 1.98-13.53zM60.18 22.27l-18.32-18a1.002 1.002 0 0 0-1.4 0 6.947 6.947 0 0 0 .5 10.28c1.043.946 9.34 9.374 10.29 9.95a7.1 7.1 0 0 0 8.93-.81.998.998 0 0 0 0-1.42zM21.33 41.27l-1.43-1.4L6.19 53.34a1.101 1.101 0 0 0-.2.28l-2.38 4.94a1.01 1.01 0 0 0 1.33 1.34l5.02-2.34a1.31 1.31 0 0 0 .28-.2l13.73-13.49c-.668-.635-1.98-1.968-2.64-2.6z"
                        fill="#192744" opacity="1">
                    </path>
                </g>
            </svg>
        );
    }
}

export default PinIcon;
