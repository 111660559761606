import cls from "./ErrorPage.module.css"
import ErrorIcon from "../../assets/img/error.png"
import {useMemo} from "react";

interface ErrorPageProps {
    error: string;
    isShowError: boolean;
}

export const ErrorPage = (props: ErrorPageProps) => {
    const {
        error,
        isShowError
    } = props;

    const errorMessage = useMemo(() => {
        return isShowError
            ? error || "Unknown error occurred"
            : "Произошла критическая ошибка, попробуйте перезагрузить страницу или попробуйте позже.";
    }, [error, isShowError]);

    return (
        <div className={cls.page}>
            <img src={ErrorIcon as string} alt="Error icon representing an issue"/>

            <div className={cls.info}>
                <h3 className={cls.title}>Ой, что-то пошло не так...</h3>

                <p className={cls.text}>
                    {errorMessage}
                </p>
            </div>
        </div>
    )
}