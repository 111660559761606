import {axios} from '../utils/api/axios';

class PlanSalesService {

    async setStatus(period,statusPlan,quoteStatusIdList) {
        await axios.post('/api/planSales/setStatus', {period,statusPlan,quoteStatusIdList});
    }

    async setFuturePlanSales(dt) {
        await axios.post('/api/planSales/setFuturePlanSales', {dt});
    }

    async updatePlanSales(dt, quoteStatusIdList, checkPlan) {
        const response = await axios.post('/api/planSales/updatePlanSales', {dt,quoteStatusIdList,checkPlan});
        return response.data;
    }

    async setFutureSsp(dt) {
        await axios.post('/api/planSales/setFutureSsp', {dt});
    }

    async planSales(dt, quoteStatusIdList,checkPlan) {
        const response = await axios.post('/api/planSales/plan', {dt, quoteStatusIdList,checkPlan});
        return response.data;
    }

    async planSalesDepartment(dt, contractorId, department, quoteStatusIdList, checkPlan) {
        const response = await axios.post('/api/planSales/plan/department', {dt, contractorId, department, quoteStatusIdList, checkPlan});
        return response.data;
    }

    async setContractorDatePlan(contractorId, period, value, quoteStatusIdList,statusPlan) {
        const response = await axios.put('/api/planSales/setPlan/contractor', {contractorId, period, value, quoteStatusIdList,statusPlan});
        return response.data;
    }

    async setDepartmentByContractorDatePlan(contractorId, department, period, value, quoteStatusIdList,statusPlan) {
        const response = await axios.put('/api/planSales/setPlan/department', {contractorId, department, period, value, quoteStatusIdList,statusPlan});
        return response.data;
    }

    async setLogisticByContractorDatePlan(contractorId, crmUserId, period, value,department, quoteStatusIdList,statusPlan) {
        const response = await axios.put('/api/planSales/setPlan/logistic', {contractorId, crmUserId, period, value, department, quoteStatusIdList,statusPlan});
        return response.data;
    }
}

export default new PlanSalesService();