import {axios} from "../utils/api/axios";

class ConfirmationCodeService {

    async sendCodeForQuote(quoteId, confirmationType) {
        const body = {
            quoteId,
            confirmationType
        }

        const response = await axios.post(`/api/v1/confirmation-code/send-for-quote`, body);
        return response.data;
    }

    async submitCodeAndSaveQuote(editDto, code, confirmationType) {
        const body = {
            editDto,
            code,
            confirmationType
        }

        const response = await axios.post(`/api/v1/confirmation-code/change-quote`, body);
        return response.data;
    }
}

export default new ConfirmationCodeService();