import {axios} from '../utils/api/axios';
import {GENERAL, EXTERNAL} from "./UserService";

const ADMIN = "ADMIN";
const ANGEL = "ANGEL";
const COMMERCIAL = "COMMERCIAL";
const LOGISTIC = "LOGISTIC";
const HEAD_OF_DEPARTMENT = "HEAD_OF_DEPARTMENT";
const DOCUMENTS = "DOCUMENTS";
const APPROVAL = "APPROVAL";
const SUPERVISOR = "SUPERVISOR";
const REGIONAL_DIRECTOR = "REGIONAL_DIRECTOR";

const CLIENT = "CLIENT";
const CARRIER = "CARRIER";
const DRIVER = "DRIVER";
const GUEST = "GUEST";

class SecurityService {
	async login(username, password) {
		const response = await axios.post("/auth/signin", { username, password });
		if (response.data.accessToken) {
			localStorage.setItem("user", JSON.stringify(response.data));
		}
		return response.data;
	}

	logout() {
		localStorage.removeItem("user");
	}

	getUser() {
		const stored = localStorage.getItem("user")
		return stored ? JSON.parse(stored) : {};
	}

	isAdmin(user) {
		return this.hasRole(user, ADMIN);
	}

	isSupervisor(user) {
		return this.hasRole(user, SUPERVISOR);
	}

	isLogistic(user) {
		return this.hasRoleButAngel(user || this.getUser(), LOGISTIC);
	}

	isRegionalDirector(user) {
		return this.hasRoleButAngel(user || this.getUser(), REGIONAL_DIRECTOR);
	}

	isHeadOfDepartment(user) {
		return this.hasRoleButAngel(user || this.getUser(), HEAD_OF_DEPARTMENT);
	}

	isCommercial(user) {
		return this.hasRoleButAngel(user || this.getUser(), COMMERCIAL);
	}

	isClient(user) {
		return this.hasRoleButAngel(user || this.getUser(), CLIENT) || this.hasRoleButAngel(user || this.getUser(), CLIENT.toUpperCase());
	}

	isCarrier(user) {
		return this.hasRoleButAngel(user || this.getUser(), CARRIER);
	}

	isDriver(user) {
		return this.hasRoleButAngel(user || this.getUser(), DRIVER);
	}

	isExternal(user) {
		return this.getRolesByType(EXTERNAL).some(role => this.hasRoleButAngel(user, role));
	}

	isGuest(user) {
		return this.hasRoleButAngel(user || this.getUser(), GUEST);
	}

	forwarderIsArttek(forwarder) {
		const userForwarder = this.getUser().forwarder;
		return !forwarder || forwarder.id === userForwarder?.id;
	}

	anyRoles(user, ...roles) {
		return roles.some(role => this.hasRole(user, role));
	}

	anyRolesButAngel(user, ...roles) {
		return roles.some(role => this.hasRoleButAngel(user, role));
	}

	hasRole(user, role) {
		const roles = this.getUserRoles(user);
		return roles.indexOf(role) >= 0 || roles.indexOf(ANGEL) >= 0;
	}

	hasRoleButAngel(user, role) {
		const roles = this.getUserRoles(user);
		return roles.indexOf(role) >= 0;
	}

	hasSingleRole(user, role) {
		const roles = this.getUserRoles(user);
		return roles.length === 1 && roles[0] === role;
	}

	hasOnlyRoles(user, ...roles) {
		const uroles = this.getUserRoles(user);
		return uroles.every(role => roles.indexOf(role) >= 0);
	}

	getUserRoles(user) {
		return (user || this.getUser()).roles || [];
	}

	getRoleName(role) {
		switch(role) {
			case ADMIN:
				return "Администратор";
				break;
			case ANGEL:
				return "";
				break;
			case COMMERCIAL:
				return "Менеджер КО";
				break;
			case LOGISTIC:
				return "Менеджер ОЛ";
				break;
			case HEAD_OF_DEPARTMENT:
				return "Руководитель подразделения";
				break;
			case DOCUMENTS:
				return "Менеджер ОД";
				break;
			case APPROVAL:
				return "Менеджер СБ";
				break;
			case CLIENT:
				return "Заказчик";
			case CARRIER:
				return "Перевозчик";
				break;
			case DRIVER:
				return "Водитель";
				break;
			case SUPERVISOR:
				return "Супервайзер";
				break;
			case REGIONAL_DIRECTOR:
				return "Региональный директор";
				break;
			case GUEST:
				return "Гость";
			default:
				return role;
				break;
		}
	}

	getRoles() {
		return [ADMIN, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, DOCUMENTS, APPROVAL, SUPERVISOR, REGIONAL_DIRECTOR, CLIENT, CARRIER, DRIVER, GUEST];
	}

	getRolesByType(type) {
		switch (type) {
			case GENERAL:
				return [ADMIN, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, DOCUMENTS, APPROVAL, SUPERVISOR, REGIONAL_DIRECTOR];
			case EXTERNAL:
				return [CLIENT, CARRIER, DRIVER, GUEST];
			default:
				return this.getRoles();
		}
	}
}
const securityService = new SecurityService();

export {securityService, ADMIN, ANGEL, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, DOCUMENTS, APPROVAL, SUPERVISOR, REGIONAL_DIRECTOR, CLIENT, CARRIER, DRIVER, GUEST};
