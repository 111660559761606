import {axios} from '../../utils/api/axios';
import docService from "../DocService";

class SurveyAttachmentService {

    static BASE_PATH = "/api/v1/survey/attachment";

    constructor(server) {
        this.server = server;
    }

    async getAttachmentsBySurveyId(surveyId) {
        const response = await axios.get(SurveyAttachmentService.BASE_PATH + `/${surveyId}`);
        return response.data;
    }

    upload(files, params) {
        const url = SurveyAttachmentService.BASE_PATH + "/upload";

        const formData = new FormData();
        if (Array.isArray(files)) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`file${i + 1}`, files[i]);
            }
        } else {
            formData.append('file', files);
        }

        return axios.post(url, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress: params?.onUploadProgress,
            cancelToken: params?.cancelToken,
        }).then(params?.onResponse, () => Promise.resolve());
    }

    download(attachment) {
        const id = attachment.id;
        if (!id) {
            return;
        }
        return axios.get(`${SurveyAttachmentService.BASE_PATH}/download/${id}`, {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment.originalName);
                document.body.appendChild(link);
                link.click();
            });
    }

    preview(id, displayedName) {
        docService.previewByUrl(`${SurveyAttachmentService.BASE_PATH}/download/${id}`, displayedName);
    }

    async remove(surveyId, attachId) {
        const response = await axios.delete(`${SurveyAttachmentService.BASE_PATH}/remove/${surveyId}/${attachId}`);
        return response.data;
    }
}

export default new SurveyAttachmentService();
