import {axios} from '../utils/api/axios';

class CommercialCommercialService {

    static BASE_PATH = "/api/audit/list";

    async list(contractorId) {
        const response = await axios.get(`/api/v1/commercial/${contractorId}`);
        return response.data
    }

    async create(contractorId, rows) {
        const response = await axios.post(`/api/v1/commercial/create/${contractorId}`, {rows: rows});
        return response.data;
    }

    async delete(contractorId, commercialId) {
        const response = await axios.delete(`/api/v1/commercial/${contractorId}/${commercialId}`);
        return response.data;
    }

}

export default new CommercialCommercialService();
