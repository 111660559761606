import React, {PureComponent} from "react";
import cls from "./TheSelect.module.css";
import {Form} from "react-bootstrap";
import {classNames} from "../../../helpers/classNames";
import Select from "react-select";
import FieldErrors from "../FieldError/FieldError";

export class TheSelect extends PureComponent {
    constructor(props) {
        super(props);

        this.customStyles = {
            menu: (base) => ({
                ...base,
                width: "max-content",
                maxWidth: "380px",
                minWidth: "100%"
            }),
            singleValue: (provided, state) => ({
                ...provided,
                opacity: 1,
                color: state.isDisabled ? 'hsl(0,0%, 50%)' : 'black',
                transition: 'opacity 300ms',
            }),
            ...({
                control: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    height: 'calc(1.5em + .5rem + 2px)',
                    padding: '.25rem .5rem',
                    fontSize: '.75rem',
                    borderRadius: '.2rem',
                    minHeight: 'auto',
                    border: state.selectProps.isInvalid ? 'solid 1px red' : provided.border
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    top: '-8px',
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    position: 'relative',
                    top: '-8px',
                })
            })
        };
    }

    render() {
        const {
            label,
            className,
            options,
            value,
            onChange,
            errors,
            name,
            placeholder = "Выберите..."
        } = this.props;

        return (
            <div className={classNames(cls.select, {}, [className])}>
                <Form.Label>{label}</Form.Label>

                <Select
                    value={value}
                    isClearable={true}
                    name={name}
                    classNamePrefix="react-select"
                    placeholder={placeholder}
                    styles={this.customStyles}
                    options={options}
                    onChange={onChange}
                />

                {(!!errors?.length) && <FieldErrors errors={errors}/>}
            </div>
        )
    }
}