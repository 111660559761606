import React from "react";
import { connect } from 'react-redux';

import contractorService from '../../../services/ContractorService';
import DataTable from '../../table/DataTable';
import TableCell from '../../table/TableCell';
import {COMMERCIAL, LOGISTIC, securityService} from "../../../services/SecurityService";
import {EXTERNAL} from "../../../services/UserService";

class Contractors extends React.PureComponent {

	fetch(params) {
		return contractorService.list(params);
	}

	openAddForm() {
		window.open("/control-panel/contractors/contractor")
	}

	openEditForm(row) {
		window.open(`/control-panel/contractors/contractor?id=${row.id}`, "_blank")
	}

	checkMinimalSearch() {
		return securityService.hasOnlyRoles(this.props.user, COMMERCIAL);
	}

	checkIfCanAdd() {
		const user = securityService.getUser();
		const roles = securityService.getRolesByType(EXTERNAL);
		return !roles.some(role => securityService.hasRole(user, role));
	}

	hidePageSizeOptions() {
		return securityService.hasOnlyRoles(this.props.user, LOGISTIC);
	}

	render() {
		return (
				<DataTable {...this.props}
					title = {this.props.title || "Контрагенты"}
					sort = {{field: 'name', dir: 'asc'}}
					openAddForm = {this.openAddForm.bind(this)}
					openEditForm = {this.openEditForm.bind(this)}
					fetch = {this.fetch.bind(this)}
					features = {{
						checkMinimalSearch: this.checkMinimalSearch(),
						canAdd: this.checkIfCanAdd(),
						hidePageSizeOptions: this.hidePageSizeOptions()
					}}
					columns = {[
						{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
						{ title: "Название", field: "name", filter: DataTable.STRING_FILTER },
						{ title: "ИНН", field: "inn", filter: DataTable.STRING_FILTER },
						{ title: "КПП", field: "kpp", filter: DataTable.STRING_FILTER },
						{ title: "Тип", field: "contractorType", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_TYPE" },
						{ title: "Менеджер КО", field: "commercialDepManager", role: "COMMERCIAL", activeOnly: false, sortable: false },
						{ title: "Регион", field: "regions", filter: DataTable.DICTIONARY_FILTER,
							formatter: value => this.formatRegionAndCarcass(value), optionsType: "REGION", multiSelect: true, sortable: false },
						{ title: "Тип транспорта", field: "carcasses", filter: DataTable.DICTIONARY_FILTER,
							formatter: value => this.formatRegionAndCarcass(value), optionsType: "CARCASS", multiSelect: true, sortable: false },
						{ title: "Активен", field: "active", className: "yes-no",
							formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER }
					]}
				/>	
		);
	}

	formatRegionAndCarcass(params){
		const options = params || [];
		return TableCell.arrayFormatter(options.length > 0 ? options.map(it => it?.value) : options);
	}
}
export default connect(DataTable.mapStateToProps)(Contractors);
