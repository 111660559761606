import {axios} from '../utils/api/axios';

class AuditService {

	static BASE_PATH = "/api/audit/list";

	async list(params) {
		const response = await axios.post(AuditService.BASE_PATH, params);
		return response.data;
	}

}

export default new AuditService();