import {axios} from '../../utils/api/axios';

const BASE_PATH = "api/task-tracker/task";

class TaskService {

    async list(params) {
        const response = await axios.post(`${BASE_PATH}/list`, params);
        return response?.data;
    }

    async read(id) {
        const response = await axios.get(`${BASE_PATH}/${id}`);
        return response?.data;
    }

    async save(task) {
        const response = await axios.post(`${BASE_PATH}`, task);
        return response?.data;
    }

    async removeTask(id) {
        const response = await axios.delete(`${BASE_PATH}/${id}`);
        return response?.data;
    }

    async options() {
        const response = await axios.get(`${BASE_PATH}/options`);
        return response?.data;
    }

    async loadParentSubtasks(currentTaskId, parentTaskId) {
        const response = await axios.post(`${BASE_PATH}/parent-subtasks`,{currentTaskId, parentTaskId});
        return response?.data;
    }

    async numberOfTasks(userId) {
        const response = await axios.get(`${BASE_PATH}/count/${userId}`);
        return response?.data;
    }

    async findOverdueTasks(userId) {
        const response = await axios.get(`${BASE_PATH}/overdue/${userId}`);
        return response?.data;
    }

    async activateTemplate(task, activate) {
        const response = await axios.post(`${BASE_PATH}/apply/template?activate=${activate}`, task);
        return response?.data;
    }

    async formatNextActivationDt(task) {
        const response = await axios.post(`${BASE_PATH}/format/activation-dt`, task);
        return response?.data;
    }

    async verifyTask(taskId, verification) {
        const response = await axios.post(`${BASE_PATH}/verify/${taskId}`, verification);
        return response?.data;
    }
}

export default new TaskService();