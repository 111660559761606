
import BaseSelect from "./BaseSelect";
import addressService from '../../services/AddressService';
import optionsService from '../../services/OptionsService';

class CitySelect extends BaseSelect {

	fetchOptions() {
		return addressService.regions({contractorId: this.props.contractor.id});
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.contractor?.id !== this.props.contractor?.id) {
			this.loaded = false;
			this.props.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default CitySelect;