import {axios} from '../utils/api/axios';

class CarcassService {

    constructor(server) {
        this.server = server;
    }

    async list(params) {
        const response = await axios.post("/api/v1/carcass/list", params);
        return response.data;
    }

    async read(id) {
        const response = await axios.get(`/api/v1/carcass/${id}`);
        return response.data;
    }

    async save(dto) {
        const call = dto.id ? axios.put : axios.post;
        const response = await call("/api/v1/carcass", dto);
        return response.data;
    }

    async isValueUnique(request) {
        const response = await axios.post("api/v1/carcass/unique", request);
        return response.data;
    }
}

export default new CarcassService();