import BaseForm from "../../form/BaseForm";
import {Col, Row, Tab} from "react-bootstrap";
import React from "react";
import {connect} from "react-redux";
import StoreWrapper from "../../form/StoreWrapper";
import UpdateForm from "./UpdateForm";
import quoteService from "../../../services/QuoteService";
import FormGroup from "../../form/FormGroup";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import settingsService from "../../../services/SettingsService";

class QuoteProcess extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <QuoteProcessInnerConnected {...this.props} store={this.store} />
        );
    }

}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
    };
}
class QuoteProcessInner extends BaseForm {

    constructor(props) {
        super(props);
        this.state = {showModal: false, notifyMessage: "", monitoringMode: false};
    }

    getTabs() {
        return [
            this.getUpdateCommercialTab(),
            this.getMonitoringTab()
        ];
    }

    getFormTabTitle() {
        return "Подразделение";
    }

    getUpdateCommercialTab() {
        const updateCommercialForm = this.updateCommercialForm();
        return (
            <Tab key="updateCommercial" eventKey="updateCommercial" title="Менеджер КО">
                {this.state.activeTab === "updateCommercial" &&
                    <UpdateForm {...this.props}
                                children={updateCommercialForm}
                                updateFunction={quoteService.updateCommercial}
                                renderSaveButton ={true}/>}
            </Tab>
        );
    }

    renderDateRow() {
        return (
            <Row>
                <FormGroup title="Начальная дата (включая)"
                           name="from"
                           type="datetime"
                           store={this.props.store}
                           required
                />
                <FormGroup title="Конечная дата (исключая)"
                           name="to"
                           type="datetime"
                           store={this.props.store}
                           required
                />
            </Row>
        );
    }

    updateDepartmentForm() {
        return (
            <Col lg={6}>
                <Row>
                    <FormGroup title="Менеджеры ОЛ"
                               name="users"
                               type="dictionary"
                               optionsType="LOGISTIC"
                               multiSelect={true}
                               store={this.props.store}
                               required
                    />
                </Row>
                <Row>
                    <FormGroup title="Подразделение"
                               name="department"
                               type="dictionary"
                               optionsType="DEPARTMENT"
                               store={this.props.store}
                               activeOnly={false}
                               required
                    />
                </Row>
                {this.renderDateRow()}
            </Col>
        );
    }

    renderMonitoringButton(){
        return (
                <div className="nav-link" >
                   <span className="d-block mb-2 font-weight-bold h6" >
                       Лимит по активным заявкам
                   </span>
                    <BootstrapSwitchButton
                        checked={this.state.monitoringMode}
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        size="sm"
                        onChange={value => this.setMonitoringMode(value)}
                    />
                    <span className="d-block mt-1 h6" >
                        Запрещает создавать логисту новые заявки если есть более 10 активных и по дате они должны были разгрузиться неделю назад
                    </span>
                </div>
        );
    }
    setMonitoringMode(mode) {
        this.setState({ monitoringMode: mode });
        settingsService.saveOne("monitoringMode", mode);
    }
    async updateMonitoringMode() {
        settingsService.get("monitoringMode").then(response => {this.setState({ monitoringMode: response })});
        await quoteService.setMonitoringModeInLocalStorage();
    }
    async componentDidMount() {
        await this.updateMonitoringMode();
    }
    async componentDidUpdate() {
        await this.updateMonitoringMode();
    }

    updateCommercialForm() {
        return (
            <Col lg={6}>
                {this.renderDateRow()}
            </Col>
        );
    }
    getMonitoringTab() {
        return (
            <Tab key="renderMonitoringButton" eventKey="renderMonitoringButton" title="Мониторинг">
                {this.state.activeTab === "renderMonitoringButton" && <UpdateForm children={this.renderMonitoringButton()} />}
            </Tab>
        );
    }
    renderForm() {
        const updateDepartmentForm = this.updateDepartmentForm();
        return <UpdateForm {...this.props} children={updateDepartmentForm} updateFunction={quoteService.updateDepartmentByUsers} renderSaveButton ={true}/>
    }

}
const QuoteProcessInnerConnected = connect(mapStateToProps)(QuoteProcessInner);
export default QuoteProcess;