import {axios} from '../utils/api/axios';

class OriginalAddressService {

	async read(id) {
		const response = await axios.get(`/api/control-panel/original-addresses/${id}`);
		return response.data;
	}

	async list(params) {
		const response = await axios.post("/api/control-panel/original-addresses/list", params);
		return response.data;
	}

	async save(originalAddress) {
       	const response = await axios.put("/api/control-panel/original-addresses", originalAddress);
 		return response.data;
    }

	async deleteById(id) {
		await axios.delete(`/api/control-panel/original-addresses/${id}`);
	}

	async deleteByCityAndFullAddress(city, fullAddress) {
		await axios.delete(`/api/control-panel/original-addresses/${city}/${fullAddress}`);
	}
}

export default new OriginalAddressService();