import React from "react";
import {securityService} from "../services/SecurityService";
import {userService} from "../services/UserService";
import {Card, Col, Form, Row, Table} from "react-bootstrap";
import FormGroup from "./form/FormGroup";
import {connect} from "react-redux";
import StoreWrapper from "./form/StoreWrapper";
import {setData} from "../actions/form";
import BaseForm from "./form/BaseForm";
import {setToastObjAC} from "../reducers/toastObj";
import {emailValidator, phoneValidator, requiredValidator} from "../validators/simpleValidators";
import FormValidator from "../validators/FormValidator";

class UserProfile extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <UserProfileConnected {...this.props} store={this.store} />
        );
    }
}

class UserProfileInner extends BaseForm {
    constructor(props) {
        super(props);
        this.configureValidators();

        this.state = {user: null}
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "lastName");
        this.useValidatorFor(this.applyInternalValidator(emailValidator.bind(this)), "email");
        this.useValidatorFor(this.applyInternalValidator(phoneValidator.bind(this)), "phone");
        this.validator.addValidator('email', this.emailOrPhoneRequiredValidator.bind(this));
    }

    applyInternalValidator(validator) {
        return value => this.execInternalValidator(validator, value);
    }

    async execInternalValidator(validator, value) {
        return validator(value);
    }

    emailOrPhoneRequiredValidator() {
        const isValid = !!this.props.model.email || !!this.props.model.phone;
        const isExternal = !!this.props.model.roles &&
            (this.props.model.roles.includes('CARRIER') || this.props.model.roles.includes('DRIVER'));
        return isExternal
            ? !!this.props.model.email ? FormValidator.OK : "обязательное поле"
            : Promise.resolve(isValid ? FormValidator.OK : "укажите email или телефон");
    }

    componentDidMount() {
        const userId = securityService.getUser()?.id;

        if (userId) {
            userService.read(userId)
                .then(user => {
                    this.setState({user})
                    this.props.store.dispatch(setData(user, this.props.location.state?.action));
                });
        }
    }

    afterSubmit(user) {
        this.props.store.dispatch(setData(user, this.props.location.state?.action));

        this.props.setToastObjAC({show: true, textHeader: "Пользователь сохранен!", delay: 3000});
    }

    handleSubmit(e) {
        e.preventDefault()
        this.submit(() => {
            userService.save(this.props.model)
                .then(user => this.afterSubmit(user))
                .finally(() => this.setSending(false));
        });
    }

    render() {
        const user = this.state.user;
        return user &&
            <Card>

                <Card.Header className="flex-space-between">
                    <span>{user?.viewName}</span>
                    <span>id: {user?.id}</span>
                </Card.Header>

                <div className="flex-space-between">
                    <Card.Body>
                        <b>Контактная информация:</b>
                        <Row style={{borderTop: "1px solid #dee2e6", margin: 0}}>
                            <Col lg={6} md={12} style={{padding: "10px 0 0 0 "}}>
                                <FormGroup title="Номер телефона" type="phone" name="phone" store={this.props.store}/>
                            </Col>
                            <Col lg={6} md={12} style={{padding: 0}}>
                                <FormGroup title="Email" type="email" name="email" store={this.props.store}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </div>
                <div style={{margin: "0 auto"}}>
                    {this.renderSaveButton()}
                </div>

                <div className="flex-space-between">
                    {user?.departments.length > 0 && <Card.Body style={{width: "50%"}}>
                        <b>Подразделения:</b>
                        {
                            <Table>{user?.departments.map(d => <tr>
                                <td>{d.value}</td>
                            </tr>)}</Table>
                        }
                    </Card.Body>}

                    {user?.roles.length > 0 && <Card.Body style={{width: "50%"}}>
                        <b>Роли:</b>
                        {
                            <Table>{user?.roles.map(r => <tr><td>{securityService.getRoleName(r)}</td></tr>)}</Table>
                        }
                    </Card.Body>}
                </div>

                <div>
                    <Card.Body>
                        <Form.Check
                            id={"subscribeToMailing"}
                            type="switch"
                            label="Подписка на рассылку эл. писем"
                            defaultChecked={user.subscribeToMailing}
                            onClick={(e) => this.subscribeToMailing(e.target.checked)}
                        />
                    </Card.Body>
                </div>

            </Card>
    }

    subscribeToMailing(subscribe) {
        userService.subscribeToMailing(subscribe).then(user => this.setState({user}));
    }
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

function mapGlobalStateToProps(state) {
    return {};
}

const UserProfileConnected = connect(mapStateToProps)(UserProfileInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(UserProfile);