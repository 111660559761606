import {combineReducers} from "redux";
import auth from "./auth";
import message from "./message";
import exceptions from "./exceptions";
import toastObj from "./toastObj";
import notification from "./notification";
import showWelcomeModal from "./showWelcomeModal";
import criticalErrorReducer from "../store/CriticalErrorStore/reducer"
import auctionFormReducer from "../store/AuctionFormStore/auctionFormReducer";
import auctionReducer from "../store/AuctionStore/auctionReducer";
import socketReducer from "../store/SocketStore/socketReducer";
import bidsHistoryReducer from "../store/BidsHistoryStore/bidsHistoryReducer";

const rootState = combineReducers({
    auth,
    message,
    exceptions,
    toastObj,
    notification,
    showWelcomeModal,
    criticalError: criticalErrorReducer,
    auctionForm: auctionFormReducer,
    auction: auctionReducer,
    socket: socketReducer,
    bidsHistory: bidsHistoryReducer
});

export default rootState;

export type RootState = ReturnType<typeof rootState>;


