import React from "react";
import {connect} from 'react-redux';
import DataTable from '../table/DataTable';
import Util from "../../utils/util/Util";
import consolidateReport from "../../services/ConsolidateReport";
import './reportStyle.css';
import {Button, Col, Collapse, Container, Row, Table} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {ADMIN, COMMERCIAL, HEAD_OF_DEPARTMENT, LOGISTIC, REGIONAL_DIRECTOR, securityService, SUPERVISOR} from "../../services/SecurityService";
import {RequestStatus} from "../../utils/consts/Const";

class ConsolidateReportLogistic extends React.PureComponent {
    constructor(props) {

        super(props);

        const now = new Date(),
            dt = new Date(now.getFullYear(), now.getMonth(), 1),
            offset = dt.getTimezoneOffset();
        dt.setTime(dt.getTime() - offset * 60000);

        this.state = {
            editCell: {},
            tableHeight: this.getTableHeight(),
            toggle: false,
            dt
        };
    }

    static HEIGHT = 1700;

    getTableHeight() {
        return window.innerHeight + this.HEIGHT;
    }

    static get REQUEST_STATUS_APPROVED() {
        return 2
    };

    static get REQUEST_STATUS_UNLOADED() {
        return 8
    };

    static get REQUEST_STATUS_LOADED() {
        return 9
    };

    static get REQUEST_STATUS_ON_LOADING() {
        return 10
    };

    static get REQUEST_STATUS_ON_WAY() {
        return 11
    };

    static get REQUEST_STATUS_ON_UNLOADING() {
        return 12
    };

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    async componentDidMount() {
        const result = await this.fetch();
        this.setState({
            data: result.data,
            revenuePlan: result.revenuePlan,
            marginPlan: result.marginPlan,
            revenueFact: result.revenueFact,
            marginFact: result.marginFact,
            relativeDeviationByMargin: result.relativeDeviationByMargin,
            relativeDeviationByMarginCurrentDay: result.relativeDeviationByMarginCurrentDay,
            relativeDeviationByRevenue: result.relativeDeviationByRevenue,
            relativeDeviationByRevenueCurrentDay: result.relativeDeviationByRevenueCurrentDay,
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.toggle !== this.state.toggle) {
            const result = await this.fetch();
            this.setState({
                data: result.data,
                revenuePlan: result.revenuePlan,
                marginPlan: result.marginPlan,
                revenueFact: result.revenueFact,
                marginFact: result.marginFact,
                relativeDeviationByMargin: result.relativeDeviationByMargin,
                relativeDeviationByMarginCurrentDay: result.relativeDeviationByMarginCurrentDay,
                relativeDeviationByRevenue: result.relativeDeviationByRevenue,
                relativeDeviationByRevenueCurrentDay: result.relativeDeviationByRevenueCurrentDay,
            });
        }
    }

    async fetch(dt) {
        const quoteStatusIdList = RequestStatus.getQuoteStatusIdsByToggleButton(this.state.toggle);
        return await consolidateReport.reportLogistic(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList).catch(() => {
            dt.setMonth(dt.getMonth() - 1)
            return consolidateReport.reportLogistic(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList);
        });
    }

    async changeYear(e, step) {
        e.preventDefault();
        const dt = this.state.dt;
        dt.setMonth(dt.getMonth() + step);
        const date = new Date(dt.getTime()),
            result = await this.fetch(date);

        this.setState({
            dt: date,
            data: result.data,
            revenuePlan: result.revenuePlan,
            marginPlan: result.marginPlan,
            revenueFact: result.revenueFact,
            marginFact: result.marginFact,
            relativeDeviationByMargin: result.relativeDeviationByMargin,
            relativeDeviationByMarginCurrentDay: result.relativeDeviationByMarginCurrentDay,
            relativeDeviationByRevenue: result.relativeDeviationByRevenue,
            relativeDeviationByRevenueCurrentDay: result.relativeDeviationByRevenueCurrentDay,
        });
    }

    formatYear() {
        const date = Util.formatIsoDate(this.state.dt), parts = date.split('-');
        return parts[0] + '-' + parts[1];
    }

    render() {
        return (
            <div className="tableFixHead">
                <Container fluid>
                    {this.renderTitle()}
                </Container>
                <table className="tableSsp">
                    <thead className={"thead"}>
                    {this.renderBodyTitle()}
                    </thead>
                    <tbody>
                    {this.renderBody()}
                    </tbody>
                </table>
            </div>
        );
    }


    renderBodyTitle() {
        return (
            <>
                <td colSpan="9" style={{backgroundColor: '#f8f9fc'}}>
                    <Col sm>
                        <Table striped bordered hover className={"tableCon"}>
                            <thead>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Выручка: ' + Number(this.state.revenuePlan).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Маржа: ' + Number(this.state.marginPlan).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Факт по выручке: ' + Number(this.state.revenueFact).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Факт по марже (Пролив ГСМ): ' + Number(this.state.marginFact).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. по выручке тек. день %: ' + Number(this.state.relativeDeviationByRevenueCurrentDay).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. по марже тек. день %: ' + Number(this.state.relativeDeviationByMarginCurrentDay).toLocaleString('ru')}</span>
                            </td>

                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. выручки %: ' + Number(this.state.relativeDeviationByRevenue).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}
                                title={"Относительное отклонение по марже"}>
                                {'Откл. маржи %: ' + Number(this.state.relativeDeviationByMargin).toLocaleString('ru')}</span>
                            </td>
                            </thead>
                        </Table>
                    </Col>
                </td>
                <tr style={{position: "relative", top: "-1px", backgroundColor: "#d3d3d3"}}>
                    <td className={"tdTitle tdAll"}>
                        <span className={"font-title"} style={{marginLeft: "30px"}}>Подразделения</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>План по выручке</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>План по марже</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт по выручке</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт по марже (Пролив ГСМ)</span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по выручке"}>
                        <span className={"font-p"}>Откл. по выручке тек. день %</span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по марже(Лимит)"}>
                        <span className={"font-p"}>Откл. по марже тек. день % </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по выручке"}>
                        <span className={"font-p"}>Отн. откл. по выручке %</span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по марже"}>
                        <span className={"font-p"}>Отн. откл. по марже %</span>
                    </td>
                </tr>
            </>
        );
    }

    renderTitle() {
        return (
            <Row>
                <Col className="mx-auto my-4 text-left" sm>
                    <span>Расчет по заявкам в процессе </span>
                    <BootstrapSwitchButton
                        toggle={this.state.toggle}
                        onstyle="outline-primary"
                        offstyle="outline-secondary"
                        size="sm"
                        onChange={value => this.setState({toggle: value})}
                    />
                </Col>
                <Col className="mx-auto my-4 text-center" sm>
                    <a href="#" onClick={e => this.changeYear(e, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                    <span className={"font-title"}>{"  " + " Сводный отчет ОЛ " + this.formatYear() + "  "}</span>
                    <a href="#" onClick={e => this.changeYear(e, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                </Col>
                <Col sm>
                </Col>
            </Row>
        );
    }


    renderBody() {
        return (
            <>
                {this.state.data && this.state.data.map((row) => (
                    <RowsLogistic key={row.departmentPlan.department} departmentPlan={row}
                                  getColor={this.getColor}
                                  getBackgroundColor={this.getBackgroundColor}/>
                ))}
            </>
        );
    }

    getColor(row) {
        return (
            <>{row.toLocaleString('ru')}</>
        )
    }

    getBackgroundColor(row) {
        if (row > 0) {
            return '#ceffbc';
        }
        if (row < 0) {
            return '#FF9999';
        }
        return '#F5F5F5';
    }

}

class RowsLogistic extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
        };

    }

    render() {
        const {departmentPlan} = this.props;
        return (
            <>
                <tr className={"rowColor"} style={{backgroundColor: "#d3d3d3"}}>
                    <td className="tdTitle tdAll">
                        <Button
                            className={"buttonPlanContractor"}
                            size="sm"
                            onClick={() => this.setState({
                                open: !this.state.open,
                                isShowTable: !this.state.isShowTable
                            })}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}>{" " + departmentPlan.departmentPlan.department}</span>
                    </td>
                    {this.getDepartmentRow(departmentPlan.departmentPlan)}
                </tr>
                {this.getLogisticRow(departmentPlan.logisticByContractors)}
            </>
        );
    }

    getLogisticRow(logistics) {
        return this.state.isShowTable && logistics.map((logistic) => (
            <>
                {/*Если только LOGISTIC*/}
                {securityService.isLogistic(this.state.crmUser) && !securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR, COMMERCIAL) &&
                    <Collapse in={this.state.open}>
                        {<PersonalReportPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
                {/*Если только COMMERCIAL*/}
                {securityService.isCommercial(this.state.crmUser) && !securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR, LOGISTIC) &&
                    <Collapse in={this.state.open}>
                        {<RowsContractorPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
                {securityService.isLogistic(this.state.crmUser) && securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR) &&
                    <Collapse in={this.state.open}>
                        {<RowsContractorPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
                {securityService.isCommercial(this.state.crmUser) && securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR) &&
                    <Collapse in={this.state.open}>
                        {<RowsContractorPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
                {/*Если только ADMIN SUPERVISOR  HEAD_OF_DEPARTMENT*/}
                {(securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR, ADMIN)) &&
                    !securityService.isLogistic(this.state.crmUser) && !securityService.isCommercial(this.state.crmUser) &&
                    <Collapse in={this.state.open}>
                        {<RowsContractorPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
                {/*Если только COMMERCIAL и Logistic*/}
                {securityService.isLogistic(this.state.crmUser) && securityService.isCommercial(this.state.crmUser) &&
                    <Collapse in={this.state.open}>
                        {<RowsContractorPlan key={logistic.crmUser} logisticPlan={logistic}
                                             getColor={this.props.getColor}
                                             getBackgroundColor={this.props.getBackgroundColor}/>}
                    </Collapse>}
            </>

        ))
    }


    getDepartmentRow(row) {
        return (
            <>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.revenuePlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.marginPlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.revenueFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.marginFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByRevenueCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(row.relativeDeviationByRevenueCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByMarginCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(row.relativeDeviationByMarginCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByRevenue)}}>
                    <span className={"font-p"}> {Number(row.relativeDeviationByRevenue).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByMargin)}}>
                    <span className={"font-p"}> {Number(row.relativeDeviationByMargin).toLocaleString('ru')}</span></td>
            </>
        )
    }

}

class RowsContractorPlan extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
        };

    }

    render() {
        const {logisticPlan} = this.props;
        return (
            <>
                <tr className={"rowColorMarginOrGsm"} style={{backgroundColor:"#e5e2e2"}}>
                    <td className="tdTitle tdAll">
                        <Button
                            className={"buttonPlanDepartment"}
                            size="sm"
                            onClick={() => this.setState({
                                open: !this.state.open,
                                isShowTable: !this.state.isShowTable
                            })}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}>{logisticPlan.crmUser}</span>
                    </td>
                    {this.getLogisticRow(logisticPlan)}
                </tr>
                {this.getContractorRow(logisticPlan.contractorPlanByLogistic)}
            </>
        )
    }

    getLogisticRow(row) {
        return (
            <>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.revenuePlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.marginPlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.revenueFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(row.marginFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByRevenueCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(row.relativeDeviationByRevenueCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByMarginCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(row.relativeDeviationByMarginCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByRevenue)}}>
                    <span className={"font-p"}> {Number(row.relativeDeviationByRevenue).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(row.relativeDeviationByMargin)}}>
                    <span className={"font-p"}> {Number(row.relativeDeviationByMargin).toLocaleString('ru')}</span></td>
            </>
        )
    }

    getContractorRow(row) {
        return this.state.isShowTable && row.map((contr) => (
            <tr>
                <td className={"tdTitle tdAll"}><span className={"font-p"}>{contr.contractor}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.revenuePlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.marginPlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.revenueFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.marginFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByRevenueCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(contr.relativeDeviationByRevenueCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByMarginCurrentDay)}}>
                    <span
                        className={"font-p"}> {Number(contr.relativeDeviationByMarginCurrentDay).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByRevenue)}}>
                    <span className={"font-p"}>{Number(contr.relativeDeviationByRevenue).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByMargin)}}>
                    <span className={"font-p"}>{Number(contr.relativeDeviationByMargin).toLocaleString('ru')}</span>
                </td>
            </tr>
        ))
    }


}

class PersonalReportPlan extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
        };

    }

    render() {
        const {logisticPlan} = this.props;
        return logisticPlan.contractorPlanByLogistic.map((contr) => (
            <tr>
                <td className={"tdTitle tdAll"}><span className={"font-p"}>{contr.contractor}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.revenuePlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.marginPlan).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.revenueFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{Number(contr.marginFact).toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByRevenue)}}>
                    <span className={"font-p"}>{Number(contr.relativeDeviationByRevenue).toLocaleString('ru')}</span>
                </td>
                <td className={"tdCell tdAll"}
                    style={{backgroundColor: this.props.getBackgroundColor(contr.relativeDeviationByMargin)}}>
                    <span className={"font-p"}>{Number(contr.relativeDeviationByMargin).toLocaleString('ru')}</span>
                </td>
            </tr>
        ))
    }


}


export default connect(DataTable.mapStateToProps)(ConsolidateReportLogistic);