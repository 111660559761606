import BaseForm from "../../form/BaseForm";
import React from "react";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import StoreWrapper from "../../form/StoreWrapper";
import {connect} from "react-redux";
import {setToastObjAC} from "../../../reducers/toastObj";
import FormGroup from "../../form/FormGroup";
import {setData} from "../../../actions/form";
import commercialCommercialService from "../../../services/CommercialCommercialService";
import {requiredValidator} from "../../../validators/simpleValidators";
import moment from "moment";

class ContractorCommercialTab extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static get FIELD_COMMERCIAL_DEP_MANAGER() {
        return 'commercialDepManager'
    };

    static get FIELD_OF() {
        return 'of'
    };

    render() {
        return (
            <ContractorCommercialTabInnerConnected {...this.props}
                                                   store={this.store}
                                                   contractorId={this.props.contractorId}/>
        );
    }
}

function mapGlobalStateToProps(state) {
    return {
        toastObj2: state.toastObj
    };
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        toastObj: state.toastObj
    };
}

class ContractorCommercialTabInner extends BaseForm {

    constructor(props) {
        super(props);

        this.addRow = this.addRow.bind(this);
    }

    render() {
        return (
            <Form>
                <Row>
                    <Col>
                        {this.renderTable()}
                    </Col>
                </Row>
            </Form>
        );
    }

    renderTable() {
        const {rows} = this.props.model;
        return (
            <Card className="m-1 bg-light merge">
                <Card.Header></Card.Header>
                <Card.Body>
                    <div>
                        <Table striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th className="col-6">Менджер КО</th>
                                <th className="col-6">От</th>
                                <th className="col-1">Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows && rows.map(this.renderRow.bind(this))}
                            </tbody>
                        </Table>
                    </div>
                    <div className={"text-center"}>
                        {<Button className="mr-4" variant="outline-primary"
                                 size="sm" onClick={this.addRow}>добавить</Button>}
                        {this.renderSaveButton("Сохранить")}
                    </div>
                </Card.Body>
            </Card>
        );
    }

    renderRow(row, index) {
        return (
            <tr key={`table-row-${index}`} style={{whiteSpace: "nowrap"}}>
                <td onClick={(e) => this.setCellEditor(e, row)}>
                    {this.formatCell(index, row, ContractorCommercialTab.FIELD_COMMERCIAL_DEP_MANAGER, row?.isNew)}
                </td>
                <td>
                    {this.formatCell(index, row, ContractorCommercialTab.FIELD_OF, row?.isNew)}
                </td>
                <td className="text-center">
                    <Button variant="danger" size="sm" onClick={() => this.deleteRow(row.id)}>
                        Удалить
                    </Button>
                </td>
            </tr>
        );
    }

    setCellEditor(e, row) {
        e.preventDefault();
        this.setState({
            editCell: {row: row}
        });
    }

    formatCell(index, row, field, isNew) {
        const value = row[field];
        return this.formatValue(index, field, value, isNew);
    }

    formatValue(index, field, value, isNew) {
        switch (field) {
            case ContractorCommercialTab.FIELD_COMMERCIAL_DEP_MANAGER: {
                return (
                    <FormGroup
                        className="mb-0"
                        readOnly={!isNew}
                        name={this.formatFieldName(index, field)}
                        optionsFilter={option => this.optionsFilter(option)}
                        type="user"
                        role="COMMERCIAL"
                        store={this.props.store}
                    />
                );
            }
            case ContractorCommercialTab.FIELD_OF: {
                return (
                    <FormGroup
                        className="mb-0"
                        readOnly={!isNew}
                        name={this.formatFieldName(index, field)}
                        type="date"
                        store={this.props.store}
                    />
                );
            }
        }
        return "";
    }

    optionsFilter(option) {
        const model = this.props.model.rows;
        const selectedUserIds = model.map(row => row.commercialDepManager?.id);
        return !selectedUserIds.includes(option?.id);
    }

    formatFieldName(index, field) {
        return `${this.getFieldNamePrefix(index)}.${field}`;
    }

    getFieldNamePrefix(index) {
        return `rows.${index}`;
    }

    addRow() {
        const {rows} = this.props.model;
        const currentDt = new Date();
        rows.push(
            {
                commercialDepManager: null,
                of: currentDt,
                isNew: true
            }
        );

        this.onChange("rows", [...rows]);
        this.configureValidators(rows.length - 1);
    }

    deleteRow = async (id) => {
        const row = this.props.model.rows.find(row => row.id === id);
        if (row && !row.isNew) {
            const {contractorId} = this.props
            const result = await commercialCommercialService.delete(contractorId, row.id);
            if (result) {
                await this.load();
            }
        }
    };

    handleSubmit(e) {
        e.preventDefault();
        this.submit(async () => {
            const {contractorId} = this.props
            const rowsWithValidDates = this.props.model.rows
                .filter(row => row.isNew === true)
                .map(row => {
                    return {...row, of: moment(row.of).toISOString()};
                });

            const list = await commercialCommercialService.create(contractorId, rowsWithValidDates);
            const oldRows = this.props.model.rows.filter(row => !!row.id);
            this.onChange("rows", oldRows.concat(list));
        });
    }

    async load() {
        const {contractorId} = this.props
        const value = await commercialCommercialService.list(contractorId);
        await value && value.length && value.forEach((value, i) => {
            this.configureValidators(i)
        });
        this.props.store.dispatch(setData({rows: value}, this.props.location.state?.action));
    }

    configureValidators(index) {
        this.useValidatorFor(
            requiredValidator,
            this.formatFieldName(index, ContractorCommercialTab.FIELD_COMMERCIAL_DEP_MANAGER),
            this.formatFieldName(index, ContractorCommercialTab.FIELD_OF)
        )
    }

}

const ContractorCommercialTabInnerConnected = connect(mapStateToProps, {setToastObjAC})(ContractorCommercialTabInner);
export default connect(mapGlobalStateToProps)(ContractorCommercialTab);
