import Util from "../utils/util/Util";

class DateTimeService {

    calculateRemainingSeconds(dt) {
        const from = new Date(),
            to = Util.safeDate(dt);

        if (!from || !to || from >= to) {
            return '';
        }

        const msInSecond = 1000,
            msInMinute = 1000 * 60,
            diff = to - from,
            seconds = Math.floor(diff % msInMinute / msInSecond);
        return seconds + " с.";
    }

    createDateAsUTC(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
            date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);
        return date;
    }

    subtractHours(date, hours) {
        date.setHours(date.getHours() - hours);
        return date;
    }

    plusHours(date, hours) {
        date.setHours(date.getHours() + hours);
        return date;
    }

    setStartOfMonth(date) {
        date.setDate(1);
        return date;
    }

    setStartOfTheDay(date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);

        return date;
    }

    setEndOfTheDay(date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);

        return date;
    }
}

export default new DateTimeService();