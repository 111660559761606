import React, {Fragment} from "react";
import DataTable from "../table/DataTable";
import {connect} from "react-redux";
import Enums from "../../utils/consts/Enums";
import Drivers from "../control-panel/drivers/Drivers";
import Vehicles from "../control-panel/vehicles/Vehicles";
import Surveys from "./Surveys";
import ContactTable from "../control-panel/contractors/contact/ContactTable";
import Contractors from "../control-panel/contractors/Contractors";
import {Form, Tab} from "react-bootstrap";
import Approval from "../control-panel/approval/Approval";
import BaseForm from "../form/BaseForm";
import approvalService from "../../services/ApprovalService";
import {APPROVAL, securityService} from "../../services/SecurityService";

class EntitySurveyForm extends BaseForm {

    constructor(props) {
        super(props);

        this.state = { totalCount: 0, totalCountOfNew: 0 };
    }

    componentDidMount() {
        this.fetchCounts();
    }

    async fetchCounts() {
        const user = securityService.getUser();
        const isSuperUser = securityService.isAdmin(user) || securityService.hasRole(user, APPROVAL);
        if (!isSuperUser) {
            return;
        }

        const result = await approvalService.counts();
        let totalCountOfNew = 0;

        const totalCount = result.reduce((counts, count) => {
            totalCountOfNew += count.countOfNew;
            return ({
                ...counts,
                [count.entity]: {totalCount: count.totalCount, countOfNew: count.countOfNew}
            })
        }, {});
        this.setState({totalCount: totalCount, totalCountOfNew: totalCountOfNew});
    }

    getFormTabTitle() {
        return "Анкеты";
    }

    getTabs() {
        const user = securityService.getUser();
        const isSuperUser = securityService.isAdmin(user) || securityService.hasRole(user, APPROVAL);
        if (isSuperUser) {
            return [
                this.getApprovalTab()
            ];
        }
        return [];
    }

    getApprovalTab() {
        return (
            <Tab key="approval" eventKey="approval" title={`Согласование +${this.state.totalCountOfNew}`}>
                {this.state.activeTab === "approval" && <Approval {...this.props} counts={this.state.totalCount}/>}
            </Tab>
        );
    }

    renderForm() {
        return (
            <Form>
                {this.getEntityTable()}
                <br/>
                <br/>
                {this.getSurveyTable()}
            </Form>
        );
    }

    getEntityTable() {
        const {props} = this;
        switch (props.type) {
            case Enums.SURVEY_TYPE.ORGANIZATION: {
                return <Contractors {...props} title={"Организации"}/>
            }
            case Enums.SURVEY_TYPE.DRIVER: {
                return <Drivers {...props}/>
            }
            case Enums.SURVEY_TYPE.VEHICLE: {
                return <Vehicles {...props}/>
            }
            case Enums.SURVEY_TYPE.CONTACT_PERSON: {
                return <ContactTable {...props}/>
            }
            default: {
                return <Surveys {...props} title={props.title}/>
            }
        }
    }

    getSurveyTable() {
        const {type, title} = this.props;
        if (!type) {
            return <Fragment/>;
        }

        return (
            <Surveys {...this.props} title={title}/>
        );
    }
}

export default connect(DataTable.mapStateToProps)(EntitySurveyForm);