import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import FormGroup from "../form/FormGroup";
import StoreWrapper from "../form/StoreWrapper";
import BaseForm from "../form/BaseForm";
import FileUploader from "../FileUploader";
import Util from "../../utils/util/Util";
import productService from "../../services/ProductService";
import {setData} from "../../actions/form";
import {connect} from "react-redux";
import {setToastObjAC} from "../../reducers/toastObj";
import {requiredValidator} from "../../validators/simpleValidators";

function mapGlobalStateToProps() {
    return {}
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class Product extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ProductInnerConnected {...this.props} store={this.store} />
        );
    }
}

class ProductInner extends BaseForm {
    constructor(props) {
        super(props);

        this.onChange("active", false);
        this.useValidatorFor(requiredValidator , "productType", "name", "price");
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(() => productService.save(this.props.model)
            .then(product => this.afterSubmit(product))
        );
    }

    onCancel() {
        this.redirectToBackOrDefaultUrl("/control-panel/products")
    }

    afterSubmit(res) {
        const data = {show: true, textHeader: "Товар сохранен!", delay: 3000};
        if(!this.getProductId()) {
            this.redirectToBackOrDefaultUrl({payload: "/control-panel/products/product", state:{id: res.id}})
            data.textHeader = "Товар добавлен!"
        }
        this.load()
        this.onChange('version', res.version);
        this.props.setToastObjAC(data);
    }

    getProductId() {
        return this.props.location.state?.id
            || this.props.model?.id
            || new URLSearchParams(this.props.location.search).get('id');
    }

    load() {
        const id = this.getProductId();
        if (id) {
            productService.read(id).then(data => {
                this.props.store.dispatch(setData(data, this.props.location.state?.action));
            });
        }
    }

    render() {
        return <Card>
            <Card.Header>
                {this.getProductId() ? "Изменить товар" : "Добавить новый товар"}
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={3} md={12}>
                        <FormGroup title="Категороия товара"
                                   placeholder="Категороия товара"
                                   name="productType"
                                   type="dictionary"
                                   optionsType="PRODUCT_TYPE"
                                   store={this.props.store}
                                   required
                        />
                    </Col>
                    <Col lg={3} md={12}>
                        <FormGroup title="Наименование"
                                   name="name"
                                   store={this.props.store}
                                   required
                        />
                    </Col>
                    <Col lg={3} md={12}>
                        <FormGroup title="Цена"
                                   name="price"
                                   type="decimal"
                                   store={this.props.store}
                                   required
                        />
                    </Col>
                    <Col lg={3} md={12}>
                        <FormGroup title="Опубликовать"
                                   name="active"
                                   type="checkbox"
                                   store={this.props.store}
                                   defaultChecked={this.props.model.active}
                                   onClick={(e) => this.onChange("active", e.target.checked)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <FileUploader
                            store={this.props.store}
                            name="attachments"
                            title="Изображение"
                            fileNameFunction={(attachment, index) => Util.formatFileName(attachment.originalName, "PRODUCT", index)}
                        />
                    </Col>
                    <Col lg={6} md={12}>
                        <FormGroup title="Описание"
                                   name="description"
                                   type="textarea"
                                   rows={6}
                                   store={this.props.store}
                        />
                    </Col>
                </Row>

                <div className="text-center">
                    {this.renderSaveButton()}
                    &nbsp;
                    {this.renderCancelButton()}
                </div>
            </Card.Body>
        </Card>
    }
}

const ProductInnerConnected = connect(mapStateToProps)(ProductInner);
export default connect(mapGlobalStateToProps, {setToastObjAC})(Product);