import StoreWrapper from "../../form/StoreWrapper";
import React from "react";
import BaseForm from "../../form/BaseForm";
import {TaskTopic, TaskType} from "../../../utils/consts/Const";
import {connect} from "react-redux";
import {requiredValidator} from "../../../validators/simpleValidators";
import FormValidator from "../../../validators/FormValidator";
import {DAY, MONTH, YEAR} from "../../../utils/consts/Enums";
import taskService from "../../../services/tasktracker/TaskService";
import moment from "moment";
import Template from "./Template";
import TaskForm from "./TaskForm";
import {Button, Card, Col, Form, ListGroup, Row, Tab} from "react-bootstrap";
import Notification from "../../Notification";
import CommentForm from "../comment/CommentForm";
import {
    ADMIN,
    DOCUMENTS,
    HEAD_OF_DEPARTMENT,
    REGIONAL_DIRECTOR,
    securityService
} from "../../../services/SecurityService";
import {setData} from "../../../actions/form";
import {NavLink} from "react-router-dom";
import quoteService from "../../../services/QuoteService";
import EventTable from "../event/EventTable";
import {userService} from "../../../services/UserService";
import positionHistoryService from "../../../services/PositionHistoryService";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {setToastObjAC} from "../../../reducers/toastObj";
import {TASK} from "../../AuditTable/AuditTable";

function mapGlobalStateToProps(state) {
    return {
        user: state.auth.user
    };
}

class Task extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static get FIELD_IS_TEMPLATE() { return "isTemplate" };
    static get FIELD_ID() { return "id" };
    static get FIELD_OWNER() { return "owner" };
    static get FIELD_TYPE() { return "type" };
    static get FIELD_TITLE() { return "title" };
    static get FIELD_STATUS() { return "status" };
    static get FIELD_START_DATE() { return "startDate" };
    static get FIELD_DUE_DATE() { return "dueDate" };
    static get FIELD_DEPARTMENT() { return "department" };
    static get FIELD_DIVISION() { return "division" };
    static get FIELD_QUOTE_NUMBER() { return "quoteNumber" };
    static get FIELD_REPORTERS() { return "reporters" };
    static get FIELD_ASSIGNEES() { return "assignees" };
    static get FIELD_COMMERCIAL() { return "commercialDepManager" };
    static get FIELD_CLIENT() { return "client" };
    static get FIELD_CARRIER() { return "carrier" };
    static get FIELD_DESCRIPTION() { return "description" };

    render() {
        return (
            <TaskInnerConnected {...this.props} store={this.store} />
        );
    }

}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        user: state.user
    };
}

class TaskInner extends BaseForm {
    constructor(props) {
        super(props);

        this.configureValidators();
        this.configureTemplateValidators();

        this.state = { isClosedQuoteInput: true,
            showTaskModal: false,
            showNotify: false,
            notifyMessage: "",
            stashedVerifications: [],
            isUrlId: false
        };
    }

    load(taskId) {
        this.handleChangeReporters(this.props.store.getState().model.reporters);
        const id = taskId || this.getTaskId();
        if (id) {
            taskService.read(id).then(data => {
                this.props.store.dispatch(setData(data, this.props.location.state?.action));
                this.setState({stashedVerifications: this.props.model.verifications || []});
                this.setState({"isUrlId": true})
                this.onChange("description", this.props.model.description)
            })
        }
    }

    isEpicTemplate() {
        return  this.props.model.type?.id === TaskType.TYPE_EPIC_TEMPLATE().id;
    }

    isTemplate() {
        const typeId =  this.props.model.type?.id;

        return (new URLSearchParams(this.props.location.search).get("isTemplate") === "true") || this.fromLocationState(Task.FIELD_IS_TEMPLATE) || typeId === TaskType.TYPE_EPIC_TEMPLATE().id
            || typeId === TaskType.TYPE_TEMPLATE().id;
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "owner", "type", "status", "department", "division");
        this.useValidatorFor(this.applyTaskFormValidator(requiredValidator), "startDate", "dueDate");
        this.useValidatorFor(this.applyTaskFormValidator(this.participantsValidator.bind(this)), "assignees", "reporters");
        this.validator.addValidator('startDate', this.datesValidator.bind(this));
        this.validator.addValidator('title', this.titleValidator.bind(this));
        this.validator.addValidator('topic', this.topicValidator.bind(this));
        this.validator.addValidator('parentTask', this.parentTaskValidator.bind(this));
    }

    configureTemplateValidators() {
        this.useValidatorFor(this.applyTemplateValidator(this.participantsValidator.bind(this)), "assignees", "reporters");
        this.useValidatorFor(this.periodicityValidator.bind(this), "periodicityType", "activationTime", "activationBeginDt", "activationEndDt");
        this.validator.addValidator('periodicityType', this.periodicityIntervalValidator.bind(this));
        this.validator.addValidator('activationBeginDt', this.activationDatesValidator.bind(this));
    }

    applyTaskFormValidator(validator) {
        return value => this.taskFormValidator(validator, value);
    }

    taskFormValidator(validator, value) {
        return !this.isTemplate() ? validator(value) : FormValidator.OK;
    }

    applyTemplateValidator(validator) {
        return value => this.templateValidator(validator, value);
    }

    templateValidator(validator, value) {
        return !this.isEpicTemplate() ? validator(value) : FormValidator.OK;
    }

    participantsValidator(values) {
        return values && values.length > 0 ? FormValidator.OK : "обязательное поле";
    }

    datesValidator() {
        if (this.props.model.startDate > this.props.model.dueDate) {
            return "Дата начала не может быть позже даты конца";
        }
        const task = this.props.model;
        if (task.previousTask) {
            return taskService.read(task.previousTask?.id).then(response =>
                new Date(task.startDate) < new Date(response.dueDate)
                    ? `Дата начала должна быть позже даты конца предыдущей задачи ${moment(response.dueDate).format("DD.MM.YYYY HH:mm:ss")}`
                    : FormValidator.OK)
        }
        return FormValidator.OK;
    }

    titleValidator() {
        return (this.isEpicTemplate() || this.props.model.topic?.id === TaskTopic.OTHER().id) && !this.props.model.title ? "обязательное поле" : FormValidator.OK;
    }

    topicValidator() {
        return (!this.isEpicTemplate() && !this.props.model.topic) ? "обязательное поле" : FormValidator.OK;
    }

    parentTaskValidator() {
        const task = this.props.model,
            subtasks = this.props.model.subtasks || [],
            parentTask = this.props.model.parentTask,
            parentIsSubtask = subtasks.some(it => it.id === parentTask?.id);
        if (parentIsSubtask) {
            return "Подзадача не может быть родительской";
        }
        if (task?.id && task?.id === parentTask?.id) {
            return "Задача не может быть родительской сама для себя";
        }
        return FormValidator.OK;
    }

    periodicityValidator() {
        const periodicityFields = [
            this.props.model.periodicityType,
            this.props.model.activationTime,
            this.props.model.activationBeginDt,
            this.props.model.activationEndDt
        ];
        const anyFieldIsNotNull = periodicityFields.some(field => !!field);
        if (anyFieldIsNotNull) {
            return periodicityFields.every(field => !!field) ? FormValidator.OK : "Заполните или очистите поле"
        }
        return FormValidator.OK;
    }

    activationDatesValidator() {
        if (this.isTemplate()) {
            if (this.props.model.activationBeginDt > this.props.model.activationEndDt) {
                return "Дата начала не может быть позже даты окончания";
            }
        }
        return FormValidator.OK;
    }

    periodicityIntervalValidator() {
        switch (this.props.model.periodicityType?.value) {
            case DAY:
                return this.props.model?.daysOfWeek ? FormValidator.OK : "Заполните поле 'Дни недели'";
            case MONTH:
                if (!this.props.model.activationDay) {
                    return "Заполните поле 'Число'";
                } else if (this.props.model.activationDay < 0 || this.props.model.activationDay > 30) {
                    return "Число не может быть меньше 0 или больше 30"
                } else {
                    return FormValidator.OK;
                }
            case YEAR:
                if (!this.props.model.activationDt) {
                    return "Заполните поле 'Дата'";
                } else if (this.props.model.activationDt > this.props.model.activationEndDt) {
                    return "Дата активации не может быть позже даты окончания";
                } else if (this.props.model.activationDt < this.props.model.activationBeginDt) {
                    return "Дата активации не может быть раньше даты начала";
                } else {
                    return FormValidator.OK;
                }
        }
    }

    fromLocationState(type) {
        return this.props.location.state?.data?.[type];
    }

    getTaskId() {
        return new URLSearchParams(this.props.location.search).get(Task.FIELD_ID)
            || this.fromLocationState(Task.FIELD_ID)
            || this.props.model.id;
    }

    initDefaultDepartmentAndDivision(department) {
        userService.read(this.props.user.id)
            .then(user => this.onChange(Task.FIELD_DIVISION, user.divisions.length === 1 ? user.divisions[0] : null));

        if (department) {
            this.onChange(Task.FIELD_DEPARTMENT, department);
        } else {
            positionHistoryService.getCurrentUserDepartment(this.props.user.id)
                .then(department => this.onChange(Task.FIELD_DEPARTMENT, !!department ? department : null));
        }
    }

    parseQuoteId(number) {
        if (!number) return '';
        const index = number.indexOf(';');
        return number.substring(0, index);
    }

    isHeadOfDocumentDepartment() {
        const user = this.props.user;
        return securityService.hasRole(user, DOCUMENTS) && securityService.isHeadOfDepartment(user);
    }

    isSuperUser() {
        const isAdmin = securityService.isAdmin();
        return isAdmin || this.isHeadOfDocumentDepartment();
    }

    isSuperUserOrOwner() {
        const isSuperUser = this.isSuperUser();
        let isOwner = false;
        if (this.props.model.owner) {
            isOwner = this.props.user.id === this.props.model.owner?.id;
        }
        return isSuperUser || isOwner;
    }

    isSuperUserOrParticipant() {
        const isSuperUser = this.isSuperUser();
        const userId = this.props.user.id,
            ownerId = this.props.model.owner?.id;
        const isParticipant = (ownerId && userId === ownerId) || this.isAssignee() || this.isReporter();
        return isSuperUser || isParticipant;
    }

    isAssignee() {
        const userId = this.props.user.id;
        const assignees = this.props.model.assignees || [];
        return assignees.some(assignee => assignee.id === userId);
    }

    isReporter() {
        const userId = this.props.user.id;
        const reporters = this.props.model.reporters || [];
        return reporters.some(reporter => reporter?.id === userId);
    }

    isPeriodicityAct() {
        const periodicityType = this.props.model.periodicityType;
        const periodicityAct = this.props.model.periodicityActivation;
        if (periodicityType && periodicityAct) {
            return true
        }
    }

    onShowNotify(show) {
        this.setState({showNotify: show})
    }

    activateTemplate(activate) {
        this.setSending(true);
        this.submit(() => {
            taskService.activateTemplate(this.props.model, activate)
                .then((message) => {
                    this.setSending(false);
                    this.onShowNotify(activate);
                    this.setState({notifyMessage: message});
                    this.onChange("periodicityActivation", activate);
                })
                .catch(() => this.setSending(false));
        });
    }

    async setAssignees() {
        const assignees = this.props.model.assignees || [];
        assignees.push({ id: this.props.user.id, value: this.props.user.fio })
        await this.onChange('assignees', null);
        await this.onChange('assignees', assignees);
    }

    async setReporters() {
        const reporters = this.props.model.reporters || [];
        reporters.push({id: this.props.user.id, value: this.props.user.fio});
        this.handleChangeReporters(reporters);
        await this.onChange('reporters', null);
        await this.onChange('reporters', reporters);
    }

    setDepartmentByUserId(values) {
        if (values?.[0]) {
            positionHistoryService.getCurrentUserDepartment(values[0].id)
                .then(department => this.onChange(Task.FIELD_DEPARTMENT, !!department ? department : null));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setSending(true);
        if (this.props.model.description && typeof this.props.model.description != "string") {
            this.onChange("description", draftToHtml(convertToRaw(this.props.model.description.getCurrentContent())))
        }
        this.submit(() => {
                taskService.save(this.props.model)
                    .then(response => {
                        this.afterSubmit(response);
                    })
                    .catch(() => this.setSending(false));
        });
    }

    afterSubmit(task) {
        this.onChange("id", task.id);
        this.onChange('version', task.version);
        this.onChange("comments", task.comments);
        this.onChange("attachments", task.attachments);
        this.onChange("createdAt", task.createdAt);
        this.setSending(false);
        if (this.getTaskId() > 0 && !this.state.isUrlId) {
            this.redirectToBackOrDefaultUrl(`/task-tracker/task?id=${task.id}`)
        }
        this.load(task.id)
        this.props.setToastObjAC({show: true, textHeader: "Задача сохранена!", delay: 3000});
    }

    handleRemoveTask() {
        const choice = confirm("Вы уверены, что хотите удалить задачу?");
        if (choice) {
            this.setSending(true);
            taskService.removeTask(this.getTaskId())
                .then(() => {
                    this.setSending(false)
                    this.redirectToBackOrDefaultUrl(`/task-tracker`);
                })
                .catch(() => this.setSending(false));
        }
    }

    handleChangeReporters(reporters) {
        const stashedVerifications = this.state.stashedVerifications;
        if (!reporters) {
            this.onChange("verifications", []);
            return;
        }
        const oldVerifications = stashedVerifications.filter(v => reporters.some(reporter => reporter.id === v.reporter.id));
        let newVerifications = [...oldVerifications];
        const newReporters = reporters.filter(reporter => !stashedVerifications.some(v => v.reporter.id === reporter.id));
        newReporters.forEach(reporter => newVerifications.push({reporter: reporter, verified: false}));
        this.onChange("verifications", newVerifications);
    }

    async handleEditQuoteNumber(close) {
        this.setState({isClosedQuoteInput: close});
        if (!close) {
            return;
        }

        const number = this.parseQuoteId(this.props.model.quoteNumber);
        if (!number) {
            return;
        }

        const response = await quoteService.byId(number);
        const {data} = response;
        if (data) {
            this.afterEditQuoteNumber(data);
        }
    }

    afterEditQuoteNumber(data) {
        this.onChange('commercialDepManager', data.commercialDepManager);
        this.onChange('client', data.contractor);
        this.onChange('carrier', data.carrier);
    }

    renderMeButton(isAssignee) {
        return !this.isEpicTemplate() && this.isSuperUserOrOwner() &&
            <Button
                variant={"link"} size={"sm"} className={"shadow-none"} style={{fontSize:"10px"}}
                onClick={() => isAssignee ? this.setAssignees() : this.setReporters()}>
                Назначить себя
            </Button>
    }

    renderQuoteNumber() {
        return (
            <Col lg={4} className={"d-flex justify-content-end"}>
                <Form.Label className={"mr-3 mt-1 p-0"}>Номер заявки</Form.Label>
                <Form.Control size="sm"
                              type="text"
                              name="quoteNumber"
                              store={this.props.store}
                              onChange={e => this.onChange("quoteNumber", e.target.value)}
                              value={this.props.model.quoteNumber}
                              style={{maxWidth: "200px"}}
                              readOnly={this.state.isClosedQuoteInput}
                />
                {this.renderEditOkIcon()}
                {this.props.model.quoteNumber &&
                    <NavLink target={"_blank"} to={"/quotes/quote?id=" + this.parseQuoteId(this.props.model.quoteNumber)} >
                        <i className="link fas fa-fw fa-link mt-2 ml-2"></i></NavLink>
                }
            </Col>
        );
    }

    renderEditOkIcon() {
        return this.state.isClosedQuoteInput
            ?
            <i className="fa fa-pencil-alt icon-float icon-hover mt-2 ml-2"
               role={"button"}
               onClick={async () => this.isSuperUserOrParticipant() ? this.handleEditQuoteNumber(false) : null}>
            </i>

            :
            <Button onClick={async () => this.handleEditQuoteNumber(true)} size="sm" variant="primary" className="pull-right ml-2">
                <i className="fa fa-check"></i>
            </Button>
    }

    renderCardHeader() {
        return (
            <Card.Header>
                <Row>
                    <Col>
                    </Col>
                    {!this.isTemplate() && this.renderQuoteNumber()}
                </Row>
            </Card.Header>
        );
    }

    renderCancelRemoveButtons() {
        return (
            <div>
                {/*needed in the future*/}
                {/*{this.isAdminOrOwner() && this.renderRemoveButton()}*/}
                {/*&nbsp;*/}
                <NavLink to={this.isTemplate() ? "/task-tracker?activeTab=templates" : "/task-tracker"}>
                    <Button size="sm" variant="secondary" className="pull-right" disabled={this.state.sending}>Выйти</Button>
                </NavLink>
            </div>
        );
    }

    renderRemoveButton() {
        return (
            this.getTaskId() &&
            <Button onClick={this.handleRemoveTask.bind(this)} size="sm" variant="danger" className="pull-right" disabled={this.state.sending}>
                <span>Удалить</span>
            </Button>
        );
    }

    renderActivationButton() {
        if (this.isTemplate() && this.getTaskId() && !this.props.model.parentTask
            && securityService.anyRoles(this.props.user, ADMIN, REGIONAL_DIRECTOR, HEAD_OF_DEPARTMENT)) {
            const isPeriodicity = this.isPeriodicityAct()
            const isActivated = isPeriodicity ? this.props.model.periodicityActivation === true : false;
            return (
                <Button variant={isPeriodicity ? "outline-danger" : "outline-primary"} size="sm" onClick={() => this.activateTemplate(!isActivated)}>
                    <i className={"fas fa-cogs mr-1"}></i>
                    {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                    <span>{isPeriodicity ? 'Деактивировать': 'Активировать'}</span>
                </Button>
            );
        }
    }

    renderSubtasks() {
        const subtasks = this.props.model.subtasks || [];
        const columnCount = 3;
        const columns = subtasks.reduce((acc, subtask, index) => {
            const columnIdx = Math.floor(index / columnCount);
            if (!acc[columnIdx]) {
                acc[columnIdx] = [];
            }
            acc[columnIdx].push(subtask);
            return acc;
        }, []);
        return (
            <Row className={"mt-2"} style={{}}>
                {columns.map((column, columnIndex) => (
                    <Col key={columnIndex} className={"ml-2 mt-2"}>
                        <span>Подзадачи</span>
                        <ListGroup>
                            {column.map((task, taskIndex) => (
                                <ListGroup.Item key={taskIndex} className={"related-task p-0 mt-1"} style={{border: "none"}}>
                                    <NavLink target={"_blank"} to={"/task-tracker/task?id=" + task.id}>
                                        {task.value}
                                    </NavLink>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                ))}
            </Row>
        );
    }

    getTabs() {
        const tabs = [];
        if (this.getTaskId()) {
            tabs.push(this.getEventTab());
            tabs.push(this.getAuditTab(this.getTaskId(), TASK));
        }
        return tabs;
    }


    getFormTabTitle() {
        const taskType = this.isTemplate() ? "Шаблон" : "Задача";
        return this.getTaskId() > 0 ? `${taskType} №` + this.getTaskId() : "Предварительная задача ";
    }

    getEventTab() {
        return (
            <Tab key={"event"} eventKey={"event"} title={"События"} mountOnEnter unmountOnExit>
                <EventTable {... this.props} sourceId={this.getTaskId()} />
            </Tab>
        );
    }

    onEditorStateChange (editorState) {
        this.onChange("description", editorState)
    };

    renderForm() {
        const commonProps = {
            initDefaultDepartmentAndDivision: this.initDefaultDepartmentAndDivision.bind(this),
            isSuperUserOrOwner: this.isSuperUserOrOwner(),
            isSuperUserOrParticipant: this.isSuperUserOrParticipant(),
            isReporter: this.isReporter(),
            isAssignee: this.isAssignee(),
            isPeriodicityAct: this.isPeriodicityAct.bind(this),
            handleChangeReporters: this.handleChangeReporters.bind(this),
            renderMeButton: this.renderMeButton.bind(this),
            renderSubtasks: this.renderSubtasks.bind(this),
            setDepartmentByUserId: this.setDepartmentByUserId.bind(this),
            onEditorStateChange: this.onEditorStateChange.bind(this),
            convertDesc: this.state.convertDesc
        };
        return (
            <Card>
                <Notification show={this.state.showNotify} onClose={() => this.onShowNotify(false)} message={this.state.notifyMessage} />
                {!this.isTemplate() && this.renderCardHeader()}
                <Card.Body>
                    <Form>
                        {this.isTemplate()
                            ? <Template {...this.props} {...commonProps} />
                            : <TaskForm {...this.props} {...commonProps} />
                        }
                        <Row className={"d-flex justify-content-center"}>
                            {this.renderSaveButton()}
                            &nbsp;
                            {this.renderCancelRemoveButtons()}
                            &nbsp;
                            {this.renderActivationButton()}
                        </Row>
                    </Form>
                    <hr/>
                    {this.props.model?.id &&
                        <Row>
                            <CommentForm {...this.props} addComment={(comment) => this.addComment(comment)}/>
                        </Row>
                    }
                </Card.Body>
            </Card>
        );
    }
}

const TaskInnerConnected = connect(mapStateToProps)(TaskInner);
export default connect(mapGlobalStateToProps, {setToastObjAC})(Task);