import React, {Fragment, PureComponent} from "react";
import Enums from "../../../utils/consts/Enums";
import WarningMessage from "../../form/WarningMessage";
import "./SurveyAttachmentWarning.css"

class SurveyAttachmentWarning extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const type = this.props.surveyType;
        let message;
        switch (type?.value) {
            case Enums.SURVEY_TYPE.ORGANIZATION.value: {
                message = this.renderOrganizationMessage();
                break;
            }
            case Enums.SURVEY_TYPE.DRIVER.value: {
                message = this.renderDriverMessage();
                break;
            }
            case Enums.SURVEY_TYPE.VEHICLE.value: {
                message = this.renderVehicleMessage();
                break;
            }
            default: {
                message = null
            }
        }

        return message != null ? this.renderWarning(message) : <Fragment/>;
    }

    renderWarning(message) {
        return <WarningMessage variant="info" message={message}/>
    }

    renderOrganizationMessage() {
        return (
            <div className={"message"}>
                <p>Документы необходимые для проверки:</p>
                <span>Для ООО, ЗАО и т.п.:</span>
                <p>● Учредительные документы(устав, решение о назначении директора, решение о создании общества, свидетельство о регистрации ООО в налоговом органе, выписка из ЕГРЮЛ)</p>
                <span>Для ИП:</span>
                <p>● Паспорт гражданина, выписка из ЕГРИП, свидетельство о регистрации в качестве ИП</p>
            </div>
        );
    }

    renderDriverMessage() {
        return (
            <div className={"message"}>
                <span>Документы необходимые для проверки:</span>
                <p>● Паспорт 1-ая стр. и страница с регистрацией, водительское удостоверение, трудовой договор</p>
            </div>
        );
    }

    renderVehicleMessage() {
        return (
            <div className={"message"}>
                <p>Документы необходимые для проверки:</p>
                <span>Если машина в аренде:</span>
                <p>● Договор аренды, где должно быть указаны все реквизиты арендатора и арендодателя, а также данные о ТС и ПП согласно СТС (вин, марка, цвет, гос номер, год выпуска)</p>
            </div>
        )
    }
}
export default SurveyAttachmentWarning;