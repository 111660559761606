import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    MESSAGE_SET, REGISTRATION_SUCCESS, REGISTRATION_FAIL, CONFIRMATION_SUCCESS, CONFIRMATION_FAIL,
} from "./types";

import AuthService from "../services/AuthService";
import {localStorageService} from "../helpers/localStorageService/localStorageService";

export const login = (username, password) => async (dispatch) => {
    try {
        const data = await AuthService.login(username, password);
        const {accessToken} = data;
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {user: data}
        });

        if (accessToken) {
            // TODO get rid of the user inside LocalStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorageService.setTokens({accessToken});
        }

        return Promise.resolve();
    } catch (error) {
        const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        dispatch({
            type: LOGIN_FAIL
        });
        dispatch({
            type: MESSAGE_SET,
            payload: message
        });
        return Promise.reject();
    }
};

export const register = (form) => async (dispatch) => {
    try {
        const data = await AuthService.register(form);
        dispatch({
            type: REGISTRATION_SUCCESS,
        });
        return Promise.resolve(data);
    } catch (error) {
        const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        dispatch({
            type: REGISTRATION_FAIL
        });
        dispatch({
            type: MESSAGE_SET,
            payload: message
        });
        return Promise.reject();
    }
};

export const confirmCode = (form) => async (dispatch) => {
    try {
        await AuthService.confirmCode(form);
        dispatch({
            type: CONFIRMATION_SUCCESS,
        });
        return Promise.resolve();
    } catch (error) {
        const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        dispatch({
            type: CONFIRMATION_FAIL
        });
        dispatch({
            type: MESSAGE_SET,
            payload: message
        });
        return Promise.reject();
    }
};

export const smsRequest = (dataRequest) => async (dispatch) => {
    try {
        await AuthService.smsRequest(dataRequest);
        dispatch({
            type: CONFIRMATION_SUCCESS,
        });
        return Promise.resolve();
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        dispatch({
            type: CONFIRMATION_FAIL
        });
        dispatch({
            type: MESSAGE_SET,
            payload: message
        });
        return Promise.reject();
    }
};

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT
    });
};
