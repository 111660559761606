import React from "react";
import { connect } from 'react-redux';
import { Button, Row, Col, Table, Form, Container, Card, FormLabel } from "react-bootstrap";
import {decimalValidator, requiredValidator} from "../../../validators/simpleValidators";
import BaseForm from '../../form/BaseForm';
import { setValue } from '../../../actions/form';
import FormGroup from "../../form/FormGroup";
import TableCell from "../../table/TableCell";
import contractService from "../../../services/ContractService";
import organizationService from "../../../services/OrganizationService";
import Contractor from "./Contractor";
import FileUploader from '../../FileUploader';
import Util from "../../../utils/util/Util";
import docService, { AGENT, CARRIER, CLIENT } from '../../../services/DocService';
import {securityService} from "../../../services/SecurityService";
import FormValidator from "../../../validators/FormValidator";
import {convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../../tasktracker/DraftEditor/TextEditor";

function mapStateToProps(state, ownProps) {
	const user = {
		...state.user,
		isClient: securityService.isClient(state.user),
		isCarrier: securityService.isCarrier(state.user)
	}

	return {
		contracts: state.model.contracts,
		errors: state.errors,
		user: user,
		contractor: {
			id: state.model.id,
			value: state.model.name,
		},
		currentId: ownProps.currentId,
	};
}

class ContractsForm extends BaseForm {

	static get CONTRACT_TYPE_ID_CLIENT() { return 1 };
	static get CONTRACT_TYPE_ID_CARRIER() { return 2 };
	static get CONTRACT_TYPE_ID_CARRIER_AGENT() { return 3 };
	static get CONTRACT_TYPE_ID_CLIENT_AGENT() { return 6 };
	static get CONTRACT_TYPE_ID_CLIENT_DRAFT() { return 4 };
	static get CONTRACT_TYPE_ID_CARRIER_DRAFT() { return 5 };
	static get CLIENT_CONTRACT_TYPES() { return [ContractsForm.CONTRACT_TYPE_ID_CLIENT, ContractsForm.CONTRACT_TYPE_ID_CLIENT_DRAFT, ContractsForm.CONTRACT_TYPE_ID_CLIENT_AGENT] }
	static get DRAFT_CONTRACT_TYPES() { return [ContractsForm.CONTRACT_TYPE_ID_CLIENT_DRAFT, ContractsForm.CONTRACT_TYPE_ID_CARRIER_DRAFT] }
	static get DEFAULT_DRAFT_COUNTER() { return 5 };

	static IS_CARRIER_CONTRACT(contract) {
		const contractTypeId = contract[ContractsForm.FIELD_CONTRACT_TYPE]?.id;
		return contractTypeId && !ContractsForm.CLIENT_CONTRACT_TYPES.includes(contractTypeId);
	}

	static IS_DRAFT_CONTRACT(contract) {
		const contractTypeId = contract[ContractsForm.FIELD_CONTRACT_TYPE]?.id;
		return contractTypeId && this.DRAFT_CONTRACT_TYPES.includes(contractTypeId);
	}

	static get FIELD_ID() { return 'id' };
	static get FIELD_NUMBER() { return 'number' };
	static get FIELD_DT() { return 'dt' };
	static get FIELD_VALID_FROM() { return 'validFrom' };
	static get FIELD_VALID_TO() { return 'validTo' };
	static get FIELD_CONTRACT_TYPE() { return 'contractType' };
	static get FIELD_PAYMENT_DOCUMENTS() { return 'paymentDocuments' };
	static get FIELD_PAYMENT_DELAY() { return 'paymentDelay' };
	static get FIELD_PAYMENT_DELAY_TYPE() { return 'paymentDelayType' };
	static get FIELD_VAT_MODE() { return 'vatMode' };
	static get FIELD_PAYMENT_MODE() { return 'paymentMode' };
	static get FIELD_TTN_RETURN_DAYS() { return 'ttnReturnDays' };
	static get FIELD_SUPPLEMENTARY_AGREEMENT() { return 'supplementaryAgreement' };
	static get FIELD_CONTRACTOR() { return 'contractor' };
	static get FIELD_CONTRACTOR_AUTHORITY() { return 'contractorAuthority' };
	static get FIELD_CONTRACTOR_AUTHORITY_POSITION() { return 'contractorAuthorityPosition' };
	static get FIELD_CONTRACTOR_ACTION_BASIS() { return 'contractorActionBasis' };
	static get FIELD_ORGANIZATION() { return 'organization' };
	static get FIELD_ORGANIZATION_AUTHORITY() { return 'organizationAuthority' };
	static get FIELD_ORGANIZATION_AUTHORITY_POSITION() { return 'organizationAuthorityPosition' };
	static get FIELD_ORGANIZATION_ACTION_BASIS() { return 'organizationActionBasis' };
	static get FIELD_ORGANIZATION_PAYMENT_ACCOUNT() { return 'organizationPaymentAccount' };
	static get FIELD_PAYMENT_ACCOUNT() { return 'paymentAccount' };
	static get FIELD_MAIN_CONTRACT() { return 'mainContract' };
	static get FIELD_CONTRACT_STATUS() { return 'contractStatus' };
	static get FIELD_CONTRACT_ATTACHMENTS() { return 'attachments' };
	static get FIELD_AGREEMENT() { return 'agreement' };

	constructor(props) {
		super(props);
		this.state = {
			currentIndex: null,
			counter: ContractsForm.DEFAULT_DRAFT_COUNTER
		}
		this.onChange = this.onChange.bind(this);
		this.getFieldValue = this.getFieldValue.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.renderContractTable = this.renderContractTable.bind(this);
		this.renderContractTableRow = this.renderContractTableRow.bind(this);
		this.filterContractTypes = this.filterContractTypes.bind(this);
		this.onMainContractChange = this.onMainContractChange.bind(this);
		this.onChangeAgreement = this.onChangeAgreement.bind(this);
		this.onChangeOrganization = this.onChangeOrganization.bind(this);
	}

	configureValidators(index) {
		this.useValidatorFor(
			requiredValidator,	
			this.formatFieldName(ContractsForm.FIELD_NUMBER, index),
			this.formatFieldName(ContractsForm.FIELD_DT, index),
			this.formatFieldName(ContractsForm.FIELD_CONTRACT_TYPE, index),
			this.formatFieldName(ContractsForm.FIELD_CONTRACTOR, index),
			this.formatFieldName(ContractsForm.FIELD_ORGANIZATION, index),
			this.formatFieldName(ContractsForm.FIELD_PAYMENT_DELAY_TYPE, index),
			this.formatFieldName(ContractsForm.FIELD_PAYMENT_MODE, index),
			this.formatFieldName(ContractsForm.FIELD_VAT_MODE, index),
			this.formatFieldName(ContractsForm.FIELD_CONTRACTOR_AUTHORITY, index),
			this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_AUTHORITY, index),
			this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_PAYMENT_ACCOUNT, index),
			this.formatFieldName(ContractsForm.FIELD_PAYMENT_ACCOUNT, index),
		);
		this.useValidatorFor(decimalValidator, this.formatFieldName(ContractsForm.FIELD_PAYMENT_DELAY, index));
		this.validator.addValidator(this.formatFieldName(ContractsForm.FIELD_PAYMENT_DELAY, index), this.paymentDelayValidator.bind(this, index));
	}

	paymentDelayValidator(index) {
		const paymentDelay = this.props.contracts[index][ContractsForm.FIELD_PAYMENT_DELAY];
		return paymentDelay === undefined || paymentDelay === '' ? "обязательное поле" : FormValidator.OK;
	}

	async componentDidMount() {
		const contracts = this.props.contracts;
		await contracts && contracts.length && contracts.forEach((value, i) => {this.configure(i)});
		const agreement = this.getFieldValue(ContractsForm.FIELD_AGREEMENT) ? this.getFieldValue(ContractsForm.FIELD_AGREEMENT) : '<p></p>';
		if (contracts && contracts.length) {
			this.onChangeAgreement(agreement);
		}
	}

	configure(index) {
		const firstContractIndex = 0;
		const currentIndex = this.props.currentId != null
			? this.getContractIndexById(this.props.currentId)
			: this.getIndexMainContract() > 0 ? this.getIndexMainContract() : firstContractIndex;
		this.setState({currentIndex: currentIndex}, () => this.configureValidators(index));
	}

	getIndexMainContract() {
		const contracts = this.props.contracts || [];
		const filteredContracts = contracts.filter(it => it.mainContract);
		return contracts.indexOf(filteredContracts[0]);
	}

	handleAdd() {
		const contracts = this.props.contracts;
		contracts.push({
			[ContractsForm.FIELD_DT]: new Date(),
			[ContractsForm.FIELD_CONTRACTOR]: this.props.contractor,
			[ContractsForm.FIELD_CONTRACTOR_AUTHORITY]: this.props.model.authority,
			[ContractsForm.FIELD_CONTRACTOR_AUTHORITY_POSITION]: this.props.model.authorityPosition,
			[ContractsForm.FIELD_PAYMENT_DELAY]: this.props.model.paymentDelay,
		});
		this.onChange("contracts", [...contracts]);
		this.configure(contracts.length - 1);
		this.selectContractIndex(contracts.length - 1)
		contractService.getInitNumber().then((number) => {
			this.onChange(this.formatFieldName(ContractsForm.FIELD_NUMBER), number + 1);
		});
		this.onChangeOrganization();		
	}

	handleRemove(index) {
		const contracts = this.props.contracts;
		if (contracts[index].id) {
			this.setSending(true);
			contractService.delete(contracts[index].id)
				.then(() => {
					contracts.removeAt(index);
					this.onChange("contracts", [...contracts]);
					this.setSending(false);
				}).catch(() => this.setSending(false));
		}
	}

	async handleSubmit(e) {
		e.preventDefault();
		let index = 0
		this.props.contracts.map(contract => {
				if (contract.agreement && typeof contract.agreement != "string") {
					const newValue = draftToHtml(convertToRaw(contract.agreement.getCurrentContent()))
					this.onChange(`contracts.${index}.agreement`, newValue)
				}
				index ++
		})
		this.submit(() => contractService.saveAll(this.props.contracts)
			.then(response => {
				this.props.setToastObjAC({show: true, textHeader: "Договор сохранен!", delay: 3000})
				this.setSending(false);
				if (response) {
					this.onChange("contracts", [...response]);
				}
			}));
	}

	handleClear(e) {
		e.preventDefault();
		const currentContract = this.getCurrentContract();
		Object.keys(currentContract).forEach(key => {
			if (key !== ContractsForm.FIELD_ID && key !== ContractsForm.FIELD_CONTRACTOR) {
				this.onChange(this.formatFieldName(key), null);
			}
		});
	}

	onChange(field, value) {
		this.props.store.dispatch(setValue(field, value));
	}
	
	onChangeOrganization(value) {
		if (!value) {
			return;
		}

		organizationService.read((value && value.id) || 1).then((organization) => {
			if (!value) {
				this.onChange(this.formatFieldName(ContractsForm.FIELD_ORGANIZATION), {id: organization.id, value: organization.value});
			}
			this.onChange(this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_AUTHORITY), organization.authority);
			this.onChange(this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_AUTHORITY_POSITION), organization.authorityPosition);
			this.onChange(this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_PAYMENT_ACCOUNT), {id: organization.paymentAccounts[0]?.id, value: organization.paymentAccounts[0]?.account});
			this.onMainContractChange(this.getFieldValue(ContractsForm.FIELD_MAIN_CONTRACT));
		});
	}
	
	onMainContractChange(isMain) {
		if (isMain) {
			const currentContract = this.getCurrentContract();
			const currentOrgId = currentContract[ContractsForm.FIELD_ORGANIZATION].id;
			const isCarrierOrAgent = ContractsForm.IS_CARRIER_CONTRACT(currentContract);
			let contracts = [];
			for (let i = 0; i < this.props.contracts.length; i++) {
				const contract = this.props.contracts[i];
				if (contract[ContractsForm.FIELD_ID] !== currentContract[ContractsForm.FIELD_ID]) {
					const checkOff = isCarrierOrAgent === ContractsForm.IS_CARRIER_CONTRACT(contract) && currentOrgId === contract[ContractsForm.FIELD_ORGANIZATION].id;
					if (checkOff && contract[ContractsForm.FIELD_MAIN_CONTRACT] !== false) {
						contract[ContractsForm.FIELD_MAIN_CONTRACT] = false;
					}
				}
				contracts.push(contract);
			}
			if (contracts) {
				this.onChange("contracts", contracts);
			}
		}
		this.onChange(this.formatFieldName(ContractsForm.FIELD_MAIN_CONTRACT), isMain);
	}
	
	onChangeAgreement(value) {
		this.onChange(this.formatFieldName(ContractsForm.FIELD_AGREEMENT), value);
	}

	formatFieldName(field, index) {
		return `${this.getFieldNamePrefix(index)}.${field}`;
	}

	getFieldNamePrefix(index) {
		return `contracts.${index || this.state.currentIndex}`;
	}

	selectContractIndex(index) {
		this.setState({
			contract: this.props.contracts[index],
			currentIndex: index,
		});
	}

	getContractIndexById(id) {
		const contracts = this.props.contracts || [];
		const contract = contracts.find(c => c.id === id);
		return contracts.indexOf(contract);
	}

	getCurrentContract() {
		const contracts = this.props.contracts;
		let contract;
		if (contracts && this.state.currentIndex !== null) {
			contract = contracts[this.state.currentIndex];
		} else if (contracts && this.props.currentId !== null) {
			const index = this.getContractIndexById(this.props.currentId);
			this.selectContractIndex(index);
			contract = contracts[index];
		}

		return contract;
	}

	getFieldValue(field) {
		const contract = this.getCurrentContract();
		let value;
		if (contract) {
			value = contract[field];
		}
		return value === null || value === undefined ? "" : value;
	}

	getContractName(contract) {
		const typeId = contract[ContractsForm.FIELD_CONTRACT_TYPE] ? +contract[ContractsForm.FIELD_CONTRACT_TYPE].id : null;
		return typeId
			? ContractsForm.CLIENT_CONTRACT_TYPES.includes(typeId) ? "Договор с Заказчиком" : "Договор с Перевозчиком"
			: "";
	}

	filterContractTypes(option) {
		const optionId = option?.id;
		const isContractorCarrier = Contractor.CONTRACTOR_IS_CARRIER(this.props.model);
		const isContractorClient = Contractor.CONTRACTOR_IS_CLIENT(this.props.model);
		return isContractorClient && isContractorCarrier || (isContractorClient
			? ContractsForm.CLIENT_CONTRACT_TYPES.includes(optionId)
			: !ContractsForm.CLIENT_CONTRACT_TYPES.includes(optionId));
	}

	isClientContract(contract) {
		const contractTypeId = contract[ContractsForm.FIELD_CONTRACT_TYPE]?.id;
		return contractTypeId && ContractsForm.CLIENT_CONTRACT_TYPES.includes(contractTypeId) && Contractor.CONTRACTOR_IS_CLIENT(this.props.model);
	}

	render() {
		return (
			<Form>
				<Container fluid>
					<Row className="text-right">
						<Col>
							{this.renderContractTable()}
						</Col>
					</Row>
					<br />
					<Row>
						<Col>
							{this.renderContractForm()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	renderContractTable() {
		const {contracts} = this.props;
		if (!contracts || !contracts.length) {
			return (
					<Row className="text-center">
						<Col className="form-group">
							<div className="mb-2">Договоры отсутствуют</div>
							<div>
								<Button onClick={this.handleAdd} size="sm" variant="primary">
									<span>добавить</span>
								</Button>
							</div>
						</Col>
					</Row>
			);
		}
		return (
			<Row>
				<Col>
					<Col>
						<Table bordered hover size="sm" className="selectable">
							<thead>
								<tr>
									<th>ID</th>
									<th>№</th>
									<th className="col-2">Наименование</th>
									<th className="col-1">Вид договора</th>
									<th className="col-2">Дата договора</th>
									<th className="col-2">Действует с</th>
									<th className="col-2">Действует по</th>
									<th className="col-1">Статус</th>
									<th>Основной</th>
									<th className="col-1 text-center">
										<Button onClick={this.handleAdd} size="sm" variant="primary" className="btn-xs" disabled={securityService.isClient() || securityService.isCarrier()}>
											<span>добавить</span>
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{contracts.map(this.renderContractTableRow)}
							</tbody>
						</Table>
					</Col>
				</Col>
			</Row>
		);
	}

	renderContractTableRow(contract, index) {
		const contactType = contract[ContractsForm.FIELD_CONTRACT_TYPE];
		const handler = () => this.selectContractIndex(index);
		return (
			<tr key={`contract-table-row-${index}`} onClick={() => handler()} onFocus={() => handler()} className={index === this.state.currentIndex ? "active" : null}>
				<td>{contract[ContractsForm.FIELD_ID]}</td>
				<td>{contract[ContractsForm.FIELD_NUMBER]}</td>
				<td>{this.getContractName(contract)}</td>
				<td>{contactType ? contactType.value : ""}</td>
				<td>{TableCell.dtFormatter(contract[ContractsForm.FIELD_DT])}</td>
				<td>{TableCell.dtFormatter(contract[ContractsForm.FIELD_VALID_FROM])}</td>
				<td>{TableCell.dtFormatter(contract[ContractsForm.FIELD_VALID_TO])}</td>
				<td>{contract[ContractsForm.FIELD_CONTRACT_STATUS]?.value}</td>
				<td>{TableCell.booleanFormatter(contract[ContractsForm.FIELD_MAIN_CONTRACT])}</td>
				<td className="col-1 text-center">
					<Button onClick={() => this.handleRemove(index)} size="sm" variant="danger" className="btn-xs" disabled={securityService.isClient() || securityService.isCarrier()}>
						<span>удалить</span>
					</Button>
				</td>
			</tr>
		);
	}

	getCounterClassName(counter) {
		if (counter > 3) {
			return "text-success";
		} else if (counter === 3) {
			return "text-warning";
		} else {
			return "text-danger";
		}
	}

	static getCorrectEnding(counter) {
		if (counter >= 5 || counter <= 0) {
			return "заявок ";
		} else if (counter > 1) {
			return "заявки ";
		} else {
			return "заявка ";
		}
	}

	renderCounter() {
		const contract = this.getCurrentContract();
		const ending = ContractsForm.getCorrectEnding(this.state.counter);
		if (contract?.id) {
			contractService.getCounter(contract.id).then(res => {
				const counter = ContractsForm.DEFAULT_DRAFT_COUNTER - res;
				counter > 0 ? this.setState({counter: counter}) : this.setState({counter: 0});
			});
		}
		return (
			<h5 className={"m-0 text-right"}>
				Доступно <span className={this.getCounterClassName(this.state.counter)}>{this.state.counter}</span> {ending}
			</h5>
		);
	}

	renderContractForm() {
		const contract = this.getCurrentContract();
		if (!contract) {
			return;
		}
		const readOnly = this.props.user.isClient;

		return (
			<Card className="m-1 bg-light">
				<Card.Header>
					<Row>
						<Col>
							<h5 className="m-0">
								{`Договор № ${this.getFieldValue(ContractsForm.FIELD_NUMBER)}`}
							</h5>
						</Col>
						<Col>
							{ContractsForm.IS_DRAFT_CONTRACT(contract) && this.renderCounter()}
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup
								title="Вид договора"
								type="dictionary"
								name={this.formatFieldName(ContractsForm.FIELD_CONTRACT_TYPE)}
								store={this.props.store}
								optionsType="CONTRACT_TYPE"
								optionsFilter={this.filterContractTypes}
								readOnly={this.getCurrentContract()?.id || readOnly}
								required
							/>
						</Col>
						<Col>
							<FormGroup
								title="Номер"
								name={this.formatFieldName(ContractsForm.FIELD_NUMBER)}
								store={this.props.store}
								readOnly={readOnly}
								required
							/>
						</Col>
						<Col>
							<FormGroup
								title="Дата"
								type="datetime"
								name={this.formatFieldName(ContractsForm.FIELD_DT)}
								store={this.props.store}
								readOnly={readOnly}
								required
							/>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>&nbsp;</Form.Label>
								<Form.Check
									label="Основной контракт"
									id={this.formatFieldName(ContractsForm.FIELD_MAIN_CONTRACT)}
									name={this.formatFieldName(ContractsForm.FIELD_MAIN_CONTRACT)}
									checked={this.getFieldValue(ContractsForm.FIELD_MAIN_CONTRACT)}
									disabled={readOnly || !contract[ContractsForm.FIELD_CONTRACT_TYPE] || ContractsForm.IS_DRAFT_CONTRACT(contract)}
									onChange={(e) => this.onMainContractChange(e.target.checked)} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								title="Документы для оплаты"
								name={this.formatFieldName(ContractsForm.FIELD_PAYMENT_DOCUMENTS)}
								store={this.props.store}
								readOnly={readOnly}
							/>
						</Col>
						<Col>
							<FormGroup
								title="Печатная форма заявки"
								readOnly={true} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Row className="no-gutters">
								<Col>
									<FormGroup
										title="Отсрочка платежа"
										type="number"
										name={this.formatFieldName(ContractsForm.FIELD_PAYMENT_DELAY)}
										store={this.props.store}
										readOnly={readOnly}
										required
									/>
								</Col>
								<Col>
									<FormGroup
										title="Тип отсрочки"
										name={this.formatFieldName(ContractsForm.FIELD_PAYMENT_DELAY_TYPE)}
										store={this.props.store}
										type="dictionary"
										optionsType="PAYMENT_DELAY_TYPE"
										readOnly={readOnly}
										required
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<FormGroup
								title="Форма договора"
								readOnly={true} />
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								title="Вид оплаты"
								name={this.formatFieldName(ContractsForm.FIELD_PAYMENT_MODE)}
								store={this.props.store}
								type="dictionary"
								optionsType="PAYMENT_MODE"
								readOnly={readOnly}
								required
							/>
						</Col>
						<Col>
							<FormGroup
								title="Статус договора"
								type="dictionary"
								name={this.formatFieldName(ContractsForm.FIELD_CONTRACT_STATUS)}
								store={this.props.store}
								optionsType="CONTRACT_STATUS"
								readOnly={readOnly}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								title="Форма оплаты"
								name={this.formatFieldName(ContractsForm.FIELD_VAT_MODE)}
								store={this.props.store}
								type="dictionary"
								optionsType="VAT_MODE"
								readOnly={readOnly}
								required
							/>
						</Col>
						<Col>
							<Col>
								<Form.Group>
									<Form.Label>&nbsp;</Form.Label>
									<Form.Check
										label="Есть дополнительное соглашение"
										id={this.formatFieldName(ContractsForm.FIELD_SUPPLEMENTARY_AGREEMENT)}
										name={this.formatFieldName(ContractsForm.FIELD_SUPPLEMENTARY_AGREEMENT)}
										checked={this.getFieldValue(ContractsForm.FIELD_SUPPLEMENTARY_AGREEMENT)}
										onChange={(e) => this.onChange(this.formatFieldName(ContractsForm.FIELD_SUPPLEMENTARY_AGREEMENT), e.target.checked)}
										disabled={readOnly}
									/>
								</Form.Group>
							</Col>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								title="Количество дней на возврат ТТН"
								type="number"
								name={this.formatFieldName(ContractsForm.FIELD_TTN_RETURN_DAYS)}
								store={this.props.store}
								readOnly={readOnly}
							/>
						</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>
							<Row className="flex-column">
								{this.renderPartyFields(contract, true)}
							</Row>
						</Col>
						<Col>
							<Row className="flex-column">
								{this.renderPartyFields(contract, false)}
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								title="Валюта договора"
								readOnly={true} />
						</Col>
						<Col>
							<FormGroup
								title="Действует с"
								type="datetime"
								name={this.formatFieldName(ContractsForm.FIELD_VALID_FROM)}
								store={this.props.store}
								readOnly={readOnly}
							/>
						</Col>
						<Col>
							<FormGroup
								title="Действует по"
								type="datetime"
								name={this.formatFieldName(ContractsForm.FIELD_VALID_TO)}
								store={this.props.store}
								readOnly={readOnly}
							/>
						</Col>
						<Col>
							<Col>
								<Form.Group>
									<Form.Label>&nbsp;</Form.Label>
									<Form.Check
										label="Пролонгируется"
										disabled />
								</Form.Group>
							</Col>
						</Col>
					</Row>
					<Row>
						<Col lg={6} md={12}>
							<FileUploader
								store={this.props.store}
								name={this.formatFieldName(ContractsForm.FIELD_CONTRACT_ATTACHMENTS)}
								title="Сканкопии документов"
								fileNameFunction={(attachment, index) => Util.formatFileName(attachment.originalName, "Договор_" + this.getFieldValue(ContractsForm.FIELD_NUMBER), index)}
								hideRemove={readOnly}
								hideDropzpne={readOnly}
							/>
						</Col>
						{this.getFieldValue(ContractsForm.FIELD_CONTRACT_TYPE).id === ContractsForm.CONTRACT_TYPE_ID_CLIENT &&
						<Col lg={6} md={12}>
							<TextEditor readOnly={readOnly} value={this.getFieldValue(ContractsForm.FIELD_AGREEMENT)} onEditorStateChange={this.onChangeAgreement}/>
						</Col>}
					</Row>
					<Row>
						<Col/>
						<Col>
							{!readOnly &&
								<div className="text-center">
									{this.renderSaveButton()}
									&nbsp;
									{this.renderClearButton()}
								</div>
							}
						</Col>
						<Col className="text-right">
							<div>
								{this.renderAccessionDownloadButton()}
								&nbsp;
								{this.getCurrentContract()?.id && !ContractsForm.IS_DRAFT_CONTRACT(contract) && this.renderAgreementDownloadButton()}
							</div>
						</Col>						
					</Row>
				</Card.Body>
			</Card>
		);
	}

	renderPartyFields(contract, isRenderingClientSide) {
		if (this.isClientContract(contract)) {
			return isRenderingClientSide ? this.renderContractorFields() : this.renderOrganizationFields();
		} else {
			return isRenderingClientSide ? this.renderOrganizationFields() : this.renderContractorFields();
		}
	}

	renderContractorFields() {
		const readOnly = this.props.user.isClient;

		return (
			<React.Fragment>
				<Col>
					<FormGroup
						type="dictionary"
						title="Заказчик"
						name={this.formatFieldName(ContractsForm.FIELD_CONTRACTOR)}
						store={this.props.store}
						optionsType="CONTRACTOR"
						readOnly={true}
						required
					/>
				</Col>
				<Col>
					<FormGroup
						title="В лице"
						name={this.formatFieldName(ContractsForm.FIELD_CONTRACTOR_AUTHORITY)}
						store={this.props.store}
						readOnly={readOnly}
						required
					/>
				</Col>
				<Col>
					<FormGroup
						title="Должность"
						name={this.formatFieldName(ContractsForm.FIELD_CONTRACTOR_AUTHORITY_POSITION)}
						store={this.props.store}
						readOnly={readOnly}
					/>
				</Col>
				<Col>
					<FormGroup
						title="Действует на основании"
						name={this.formatFieldName(ContractsForm.FIELD_CONTRACTOR_ACTION_BASIS)}
						store={this.props.store}
						readOnly={readOnly}
					/>
				</Col>
				<Col>
					<FormGroup
						title="Р.счет"
						type="paymentAccount"
						name={this.formatFieldName(ContractsForm.FIELD_PAYMENT_ACCOUNT)}
						store={this.props.store}
						params={{owner: this.props.contractor, field: ContractsForm.FIELD_CONTRACTOR}}
						readOnly={readOnly}
						required
					/>
				</Col>
			</React.Fragment>
		);
	}

	renderOrganizationFields() {
		const readOnly = this.props.user.isClient;

		return (
			<React.Fragment>
				<Col>
					<FormGroup
						type="dictionary"
						title="Исполнитель"
						name={this.formatFieldName(ContractsForm.FIELD_ORGANIZATION)}
						store={this.props.store}
						optionsType="FORWARDER_ORGANIZATION"
						onChangeHandler={(value) => this.onChangeOrganization(value)}
						readOnly={readOnly}
						required
					/>
				</Col>
				<Col>
					<FormGroup
						title="В лице"
						name={this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_AUTHORITY)}
						store={this.props.store}
						readOnly={readOnly}
						required
					/>
				</Col>
				<Col>
					<FormGroup
						title="Должность"
						name={this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_AUTHORITY_POSITION)}
						store={this.props.store}
						readOnly={readOnly}
					/>
				</Col>
				<Col>
					<FormGroup
						title="Действует на основании"
						name={this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_ACTION_BASIS)}
						store={this.props.store}
						readOnly={readOnly}
					/>
				</Col>
				<Col>
					<FormGroup
						title="Р.счет организации"
						type="paymentAccount"
						name={this.formatFieldName(ContractsForm.FIELD_ORGANIZATION_PAYMENT_ACCOUNT)}
						store={this.props.store}
						params={{owner: this.getFieldValue(ContractsForm.FIELD_ORGANIZATION), field: ContractsForm.FIELD_ORGANIZATION}}
						readOnly={readOnly || !this.getFieldValue((ContractsForm.FIELD_ORGANIZATION))}
						required
					/>
				</Col>
			</React.Fragment>
		);
	}

	generateAgreement(type) {
		docService.getContractAgreement(this.getCurrentContract(), type);
	}
	generateAccession() {
		docService.getContractAccession();
	}

	renderAgreementDownloadButton() {
		const createBtn = type => <Button onClick={this.generateAgreement.bind(this, type)} size="sm" variant="success">Скачать договор</Button>;
        switch(this.getCurrentContract().contractType.id) {         
            case ContractsForm.CONTRACT_TYPE_ID_CARRIER_AGENT:
                return createBtn(AGENT);
            case ContractsForm.CONTRACT_TYPE_ID_CARRIER:
                return createBtn(CARRIER);
            case ContractsForm.CONTRACT_TYPE_ID_CLIENT:
                return createBtn(CLIENT);
        }        	
	}

	renderAccessionDownloadButton() {
		return (
			<Button onClick={this.generateAccession} size="sm" variant="success">Скачать соглашение</Button>
		)
	}

}

export default connect(mapStateToProps)(ContractsForm);