import {axios} from "../../utils/api/axios";

export class HttpService {
    static async basicRequest({method = "get", url = "", data = {}, options = {}}) {
        let resData;
        let resError;

        const settings = {
            url,
            method,
            data,
            ...options
        }

        try {
            const response = await axios(settings);
            resData = response?.data;
        } catch (error) {
            resError = error?.response?.data || error.message;
        }

        return {
            data: resData,
            error: resError
        }
    }

    static async post(url, data, options) {
        return this.basicRequest({method: "post", url, data, options});
    }

    static async get(url, options) {
        return this.basicRequest({method: "get", url, options});
    }

    static async put(url, data, options) {
        return this.basicRequest({method: "put", url, data, options});
    }

    static async patch(url, data, options) {
        return this.basicRequest({method: "patch", url, data, options});
    }

    static async delete(url, options) {
        return this.basicRequest({method: "delete", url, options});
    }
}