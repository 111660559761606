import React from "react";
import {Card, Row, Col, Form, Spinner} from "react-bootstrap";
import docService from "../../../services/DocService";
import StoreWrapper from "../../form/StoreWrapper";
import {connect} from "react-redux";
import {setToastObjAC} from "../../../reducers/toastObj";
import {SET_TOAST_OBJ} from "../../../actions/types";
import {RequestStatus} from "../../../utils/consts/Const";
import FormGroup from "../../form/FormGroup";
import BaseForm from "../../form/BaseForm";
import {requiredValidator} from "../../../validators/simpleValidators";

class QuoteRouteExport extends StoreWrapper {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<QuoteRouteExportInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model
	};
}

class QuoteRouteExportInner extends BaseForm {

	constructor(props) {
		super(props);

		this.state = { sending: false, loading: false };

		this.configureValidators();
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "from", "to");
	}

	render() {
		return (
			<Form>
				<Row>
					<Col>
						<Card className="import-card">
							<Card.Header>
								Экспорт заявок по контрагенту АО "АБ ИНБЕВ ЭФЕС"
							</Card.Header>
							<Card.Body>
								<Col lg={4}>
									{this.renderDateRow()}
								</Col>
								<Col lg={3}>
									{this.renderSource()}
								</Col>
								<Col>
									{this.renderExportButton()}
								</Col>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

	renderExportButton() {
		if (this.state.loading) {
			return <Spinner animation={"border"} />
		}
		return this.renderSaveButton("Выполнить");
	}

	renderDateRow() {
		return (
			<Row>
				<FormGroup title="Начальная дата"
						   name="from"
						   type="datetime"
						   store={this.props.store}
						   required
				/>
				<FormGroup title="Конечная дата"
						   name="to"
						   type="datetime"
						   store={this.props.store}
						   required
				/>
			</Row>
		);
	}

	renderSource() {
		return (
			<Row>
				<FormGroup
					title="Источник"
					placeholder="Источник"
					name="source"
					type="dictionary"
					optionsType="REQUEST_SOURCE"
					store={this.props.store}
				/>
			</Row>
		)
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() => {
			this.setState({loading: true});
			const setLoading = () => {
				this.setState({loading: false});
				this.showToast(`Экспорт завершен.`,"Загрузка файла начнётся автоматически.", "bg-success");
			}

			const params = this.prepareParams();
			docService.getQuoteRouteExport(params, setLoading).then(() => this.setSending(false));
			this.showToast(`Экспорт выполняется.`, "Ожидайте скачивания файла", "bg-warning");
		});
	}

	prepareParams() {
		const {model} = this.props;
		const createdAt = { from: model.from, to: model.to };

		return {
			createdAt: createdAt,
			source: model.source,
			contractor: { id: 3539, value: 'АО "АБ ИНБЕВ ЭФЕС"' },
			excludeStatuses: RequestStatus.getGarbageStatuses()
		};
	}

	setSending(isSending) {
		this.setState({sending: isSending});
	}

	formatDate(date) {
		return date.format("YYYY-MM-DDTHH:mm:ss");
	}

	showToast(header, body, className) {
		const data = { show: true, delay: 3000, textHeader: header, textBody: body, className: className };
		this.props.dispatch({type: SET_TOAST_OBJ, data});
	}
}

const QuoteRouteExportInnerConnected = connect(mapStateToProps, {setToastObjAC})(QuoteRouteExportInner);

export default connect(mapStateToProps)(QuoteRouteExport);

