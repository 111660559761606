import {axios} from '../utils/api/axios';

class EmailService {
    async send(data) {
        const response = await axios.post("/api/email/send", data);
        return response.data;
    }

    async getSentMessageTime(quoteId, contractorType) {
        const response = await axios.get(`/api/email/date/${quoteId}/${contractorType}`);
        return response.data;
    }
}

export default new EmailService();