import React from "react";
import BaseForm from "../../form/BaseForm";
import {connect} from "react-redux";
import abstractDictionaryService from "../../../services/AbstractDictionaryService";
import {TaskType, TaskTopic} from "../../../utils/consts/Const";
import {securityService} from "../../../services/SecurityService";
import {Button, Col, Row} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import ReadOnlyContainer from "../../ReadOnlyContainer";
import Enums, {DAY, ENUM_NAME, MONTH, YEAR} from "../../../utils/consts/Enums";
import TaskModal from "./TaskModal";
import Task from "./Task";
import taskService from "../../../services/tasktracker/TaskService";
import moment from "moment/moment";
import TextEditor from "../DraftEditor/TextEditor";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        user: state.user
    };
}

class Template extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {stashedVerifications: [], nextActivationDt: ""};
        this.isFormatNextActivationDt = this.isFormatNextActivationDt.bind(this)

        this.initDefaultValues();
    }

    initDefaultValues() {
        this.initDefaultDates();
        if (this.getTaskId()) {
            return;
        }
        this.onChange(Task.FIELD_OWNER, { id: this.props.user.id, value: this.props.user.fio });
        this.onChange(Task.FIELD_STATUS, Enums.TASK_STATUS.NEW);
        this.onChange(Task.FIELD_TYPE, TaskType.TYPE_TEMPLATE());
        this.props.initDefaultDepartmentAndDivision();
    }

    initDefaultDates() {
        if (!this.isEpicTemplate()) {
            this.onChange(Task.FIELD_START_DATE, new Date());
            this.setDueDate(this.props.model.topic)
        }
    }

    fromLocationState(type) {
        return this.props.location.state?.data?.[type];
    }

    getTaskId() {
        return new URLSearchParams(this.props.location.search).get(Task.FIELD_ID)
            || this.fromLocationState(Task.FIELD_ID)
            || this.props.model.id;
    }

    getTaskTitle() {
        return "# " + this.props.model.id + " " + this.props.model.title || this.props.model.topic?.value;
    }

    isEpicTemplate() {
        return  this.props.model.type?.id === TaskType.TYPE_EPIC_TEMPLATE().id;
    }

    formatTime(time) {
        return time != null ? `${time.getHours().pad(2)}:${time.getMinutes().pad(2)}` : null;
    }

    handleChangeTopic(topic) {
        if (topic && topic.id === TaskTopic.OTHER().id) {
            this.onChange(Task.FIELD_TITLE, '');
        }
        this.setDueDate(topic);
    }

    setDueDate(topic) {
        if (topic) {
            abstractDictionaryService.read(topic.id, "topic").then(response => {
                const days = response.daysToComplete;
                const startDate = this.props.model.startDate || moment();
                this.onChange(Task.FIELD_DUE_DATE, moment(startDate).add(days, "days").format('YYYY-MM-DDTHH:mm:ss'));
            });
        }
    }

    handleEditDescription = (content, editor) => {
        this.onChange(Task.FIELD_DESCRIPTION, content);
    };

    clearUsers(type) {
        if (type.id === TaskType.TYPE_EPIC_TEMPLATE().id) {
            this.onChange(Task.FIELD_ASSIGNEES, null);
            this.onChange(Task.FIELD_REPORTERS, null);
        }
    }

    isFormatNextActivationDt(prevModal) {
        return this.props.model.periodicityType !== prevModal.periodicityType
            || this.props.model.activationTime !== prevModal.activationTime
            || (this.props.model.daysOfWeek !== prevModal.daysOfWeek
                || this.props.model.activationDay !== prevModal.activationDay
                || this.props.model.activationDt !== prevModal.activationDt)
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.model !== this.props.model) {
            let nextActivationDt = "";
            if (this.props.model.periodicityType && this.props.model.activationTime && (this.props.model.daysOfWeek || this.props.model.activationDay || this.props.model.activationDt) && this.isFormatNextActivationDt(prevProps.model)) {
                if (this.props.model.description && typeof this.props.model.description != "string") {
                    await this.onChange("description", draftToHtml(convertToRaw(this.props.model.description.getCurrentContent())))
                }
                nextActivationDt = await taskService.formatNextActivationDt(this.props.model);
                nextActivationDt = moment(nextActivationDt).format("DD.MM.YYYY в HH:mm");
            }
            this.setState({nextActivationDt: nextActivationDt});
        }
    }

    renderManageCol() {
        return (
            <Col lg={3}>
                <FormGroup title="Создал"
                           name="owner"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={!securityService.isAdmin(this.props.user)}
                           required />

                <FormGroup title="Статус"
                           name="status"
                           type="enum"
                           optionsType={ENUM_NAME.TASK_STATUS}
                           store={this.props.store}
                           readOnly={!this.props.isSuperUserOrParticipant || !this.getTaskId()}
                           required />

                <FormGroup title="Ответственные"
                           name="assignees"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={this.isEpicTemplate() || !this.props.isSuperUserOrOwner}
                           multiSelect={true}
                           onChangeHandler={values => this.props.setDepartmentByUserId(values)}
                           renderCustomButton={this.props.renderMeButton.bind(this, true)}
                           activeOnly={true}
                           required={!this.isEpicTemplate()} />

                <FormGroup title="Проверяющие"
                           name="reporters"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={this.isEpicTemplate() || !this.props.isSuperUserOrOwner}
                           multiSelect={true}
                           onChangeHandler={values => this.props.handleChangeReporters(values)}
                           renderCustomButton={this.props.renderMeButton.bind(this, false)}
                           activeOnly={true}
                           required={!this.isEpicTemplate()} />

                {!this.isEpicTemplate() &&
                    <FormGroup title="Тема"
                               name="topic"
                               type="dictionary"
                               optionsType="TOPIC"
                               store={this.props.store}
                               onChangeHandler={(value) => {this.handleChangeTopic(value)}}
                               readOnly={!this.props.isSuperUserOrOwner}
                               required />
                }
                {(this.isEpicTemplate() || this.props.model.topic?.id === TaskTopic.OTHER().id) &&
                    <FormGroup title="Название задачи"
                               name="title"
                               type="text"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                               required />
                }
            </Col>
        );
    }

    renderInfoCol() {
        return (
            <Col lg={3}>
                <Row>
                    <FormGroup title="Номер"
                               type="number"
                               name="id"
                               store={this.props.store}
                               readOnly={true} />

                    <FormGroup title="Дата"
                               type="date"
                               name="createdAt"
                               store={this.props.store}
                               readOnly={true} />
                </Row>
                <Row>
                    <FormGroup title="Тип"
                               name="type"
                               type="dictionary"
                               optionsType="TASK_TYPE"
                               filteredOptions={TaskType.getTemplateTypes()}
                               store={this.props.store}
                               onChangeHandler={type => this.clearUsers(type)}
                               required />
                </Row>
            </Col>
        );
    }

    renderTasksRow() {
        return (
            <>
                <Row>
                    <Col>
                        <FormGroup title="Подразделение"
                                   name="department"
                                   type="dictionary"
                                   optionsType="DEPARTMENT"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                                   activeOnly={true}
                                   required
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Отдел"
                                   name="division"
                                   type="dictionary"
                                   optionsType="DIVISION"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                                   activeOnly={true}
                                   required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup title="Родительская задача"
                                   name="parentTask"
                                   type="task"
                                   taskType="parent"
                                   store={this.props.store}
                                   id={this.props.model.parentTask?.id}
                                   onChangeHandler={() => {this.onChange('previousTask', null)}}
                                   showViewIcon={true}
                                   showClearIcon={this.props.isSuperUserOrOwner}
                                   readOnly={true}
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Выполнить после"
                                   name="previousTask"
                                   type="task"
                                   taskType="previous"
                                   store={this.props.store}
                                   id={this.props.model.previousTask?.id}
                                   parentTaskId={this.props.model.parentTask?.id}
                                   currentTaskId={this.props.model?.id}
                                   showViewIcon={true}
                                   showClearIcon={this.props.isSuperUserOrOwner}
                                   readOnly={true}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    renderDates() {
        if (!this.isEpicTemplate()) {
            return (
                <Row>
                    <Col>
                        <FormGroup title="Дата начала выполнения"
                                   name="startDate"
                                   type="datetime"
                                   value={new Date()}
                                   store={this.props.store}
                                   readOnly={true}
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Дата конца выполнения"
                                   name="dueDate"
                                   type="datetime"
                                   store={this.props.store}
                                   readOnly={true}
                        />
                    </Col>
                </Row>
            );
        }
    }

    renderPositionCol() {
        return(
            <Col>
                {this.renderTasksRow()}
                {this.renderDates()}
                {this.renderAddSubtaskButton()}
                {this.props.renderSubtasks()}
            </Col>
        );
    }

    renderPeriodicityActivation() {
        return (
            <ReadOnlyContainer readOnly={this.props.model.periodicityActivation === true}>
                <div className={"mb-2"}>Переодичность активации</div>
                <Row>
                    <Col>
                        <FormGroup title="Переодичность"
                                   name="periodicityType"
                                   type="enum"
                                   optionsType={ENUM_NAME.PERIODICITY_TYPE}
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                                   showClearIcon={true}
                                   onChangeHandler={() => this.clearPeriodicityInterval()}
                        />
                    </Col>
                    <Col>
                        {this.renderPeriodicityInterval()}
                    </Col>
                    <Col>
                        <FormGroup title="Во сколько"
                                   name="activationTime"
                                   type="time"
                                   value={this.props.model.activationTime}
                                   store={this.props.store}
                                   onChange={time => this.onChange("activationTime", this.formatTime(time))}
                                   readOnly={!this.props.isSuperUserOrOwner}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup title="Дата начала"
                                   name="activationBeginDt"
                                   type="date"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Дата окончания"
                                   name="activationEndDt"
                                   type="date"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Дата последней активации"
                                   name="lastActivationDt"
                                   type="datetime"
                                   store={this.props.store}
                                   readOnly={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <span className={"ml-3 text-primary"}>Следующая активация задачи запланирована {this.state.nextActivationDt || `...`}</span>
                </Row>
            </ReadOnlyContainer>
        );
    }

    renderPeriodicityInterval() {
        switch (this.props.model.periodicityType?.value) {
            case DAY:
                return(
                    <FormGroup title="Дни недели"
                               name="daysOfWeek"
                               type="enum"
                               optionsType={ENUM_NAME.DAY_OF_WEEK}
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                               multiSelect={true}
                               showClearIcon={true}
                    />
                );
            case MONTH:
                return(
                    <FormGroup title="Число"
                               name="activationDay"
                               type="number"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                    />
                );
            case YEAR:
                return(
                    <FormGroup title="Дата (год не учитывается)"
                               name="activationDt"
                               type="date"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                    />
                );
            default: return (
                <FormGroup title="&nbsp;"
                           name="&nbsp;"
                           type="text"
                           store={this.props.store}
                           readOnly={true}
                />
            );
        }
    }

    clearPeriodicityInterval() {
        this.onChange("daysOfWeek", null);
        this.onChange("activationDay", null);
        this.onChange("activationDt", null);
    }

    renderTaskModal() {
        if (this.state.showTaskModal) {
            const onShow = (show) => {
                this.setState({showTaskModal: show});
            }
            const onAddTask = (task) => {
                const subtasks = this.props.model.subtasks || [];
                subtasks.push({id: task.id, value: "#" + task.id + " " + task.topic?.value});
                this.onChange("subtasks", [...subtasks]);
            }

            return (
                <TaskModal
                    onShow={show => onShow(show)}
                    onAddTask={task => onAddTask(task)}
                    show={this.state.showTaskModal}
                    department={this.props.model.department}
                    parentTask={{id: this.getTaskId(), value: this.getTaskTitle()}}
                    type={TaskType.TYPE_TEMPLATE()}
                    status={Enums.TASK_STATUS.NEW}
                />
            );
        }
    }

    renderAddSubtaskButton() {
        if (this.isEpicTemplate()) {
            if (this.getTaskId()) {
                return (
                    this.props.isSuperUserOrOwner &&
                    <Button variant="outline-primary" size="sm" onClick={() => this.setState({showTaskModal: true})}>
                        <i className="fas fa-plus mr-1"></i>
                        <span>Добавить подзадачу</span>
                    </Button>
                );
            } else {
                return <span>Чтобы добавить подзадачи, сохраните текущую задачу.</span>
            }
        }
    }

    render() {
        return (
            <div>
                {this.renderTaskModal()}
                <Row>
                    {this.renderManageCol()}
                    {this.renderInfoCol()}
                    {this.renderPositionCol()}
                </Row>
                <Row>
                    <Col lg={6} className={"ml-1"}>
                        <TextEditor onEditorStateChange={(this.props.onEditorStateChange)} value={this.props.model.description}/>
                    </Col>
                    <Col>
                        {this.renderPeriodicityActivation()}
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(mapStateToProps)(Template);