import React from "react";
import DataTable from "../table/DataTable";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TableCell from "../table/TableCell";
import noticesService from "../../services/NoticesService";
import {NOTIFICATION_TYPES, NOTIFICATION_CLASSIFICATIONS, ENUM_NAME, NOTIFICATION_PRIORITIES} from "../../utils/consts/Enums";


class NotificationTable extends React.PureComponent {

    fetch(params) {
        params.priority = this.paramToEnum(params.priority);
        params.classification = this.paramToEnum(params.classification);
        params.type = this.paramToEnum(params.type);
        return noticesService.listOfNotices(params);
    }

    paramToEnum(param) {
        if (param) {
            return Array.isArray(param) ? param.map(p => p.value) : param.value;
        }
    }

    render() {
        return (
            <DataTable {...this.props}
                title = "Уведомления"
                sort = {{field: "created", dir: 'desc'}}
                fetch = {this.fetch.bind(this)}
                columns = {[
                    { title: "#", field: "id", filter: DataTable.STRING_FILTER},
                    { title: "Тема", field: "subject", filter: DataTable.STRING_FILTER},
                    { title: "Описание", field: "body", filter: DataTable.STRING_FILTER},
                    { title: "Тип", field: "type", filter: DataTable.ENUM_FILTER,
                     optionsType: ENUM_NAME.NOTIFICATION_TYPE,
                        formatter: TableCell.enumFormatter(NOTIFICATION_TYPES) },
                    { title: "Получатель", field: "recipient", filter: DataTable.DICTIONARY_FILTER, optionsType: "CRM_USER", activeOnly: false },
                    { title: "Дата создания", field: "created", formatter: TableCell.dtFormatter,
                        filter: DataTable.INSTANT_DATE_RANGE_FILTER},
                    { title: "Классификация", field: "classification", filter: DataTable.ENUM_FILTER,
                        optionsType: ENUM_NAME.NOTIFICATION_CLASSIFICATION,
                        formatter: TableCell.enumFormatter(NOTIFICATION_CLASSIFICATIONS), multiSelect: true },
                    { title: "Приоритет", field: "priority", filter: DataTable.ENUM_FILTER,
                        optionsType: ENUM_NAME.NOTIFICATION_PRIORITY,
                        formatter: TableCell.enumFormatter(NOTIFICATION_PRIORITIES) }
                ]}
                />
        );
    }
}

export default withRouter(connect(DataTable.mapStateToProps)(NotificationTable));