import {axios} from '../utils/api/axios';

class ForwarderService {

	static BASE_PATH = "/api/v1/forwarder";

	async list(params) {
		const response = await axios.post(`${ForwarderService.BASE_PATH}/list`, params);
		return response.data;
	}

	async read(id) {
		const response = await axios.get(`${ForwarderService.BASE_PATH}/${id}`);
		return response.data;
	}

	async save(forwarder) {
		const call = forwarder.id ? axios.put : axios.post,
			response = await call(ForwarderService.BASE_PATH, forwarder);
		return response.data;
	}
}

export default new ForwarderService();