import {axios} from '../utils/api/axios';
import MD5 from 'crypto-js/md5';
import docService from "./DocService";

class FileStorageService {

	constructor() {
		this.getDirectLink = this.getDirectLink.bind(this);
		this.download = this.download.bind(this);
	}

	static BASE_URL_PROD = window.location.origin;
	static BASE_URL_DEV = "http://localhost:8080";
	static BASE_PATH = "/api/files";
	static TIMEOUT = 1000 * 60 * 60;
	static SECRET = "0lk0B80DSw";

	getBasePath() {
		return FileStorageService.BASE_PATH;
	}

	static prepareFormData(file) {
		const data = new FormData();
		data.append('file', file);
		return data;
	}

	static prepareMultipartConfig() {
		return {
			headers: { 'content-type': 'multipart/form-data' }
		}
	}
	
	async download(attachment) {
		const fileName = attachment.fileName.substring(attachment.fileName.lastIndexOf("/") + 1);
		const { displayedName } = attachment;
		if (fileName == null && displayedName == null) {
			return;
		}

		await axios(`${this.getBasePath()}/download/${fileName}`, {
		    method: 'GET',
		    responseType: 'blob'
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', displayedName);
			document.body.appendChild(link);
			link.click();
		 });
	}
	
	upload(file, params) {
		return axios.post(`${this.getBasePath()}/upload`,
			FileStorageService.prepareFormData(file),
			{
				...FileStorageService.prepareMultipartConfig(),
				onUploadProgress: params?.onUploadProgress,
				cancelToken: params?.cancelToken,
			})
			.then(params?.onResponse, () => Promise.resolve());
	}

	preview(id, displayedName) {
		docService.preview(id, displayedName);
	}

	getDirectLink(id, fileName) {
		const hash = MD5(id + fileName + FileStorageService.SECRET).toString();
		return `${FileStorageService.BASE_URL_PROD}${this.getBasePath()}/download/${id}/${hash}`;
	}

}

export default new FileStorageService();
