import {axios} from '../utils/api/axios';
import {HttpService} from "./HttpService/HttpService";

class QuoteCommentService {

	static BASE_URL = '/api/v1/quote-comment';

	async create(request) {
		const response = await axios.post(QuoteCommentService.BASE_URL, request);
		return response.data;
	}

	async listByQuoteId(quoteId) {
		const response = await axios.get(`${QuoteCommentService.BASE_URL}?quoteId=${quoteId}`);
		return response.data;
	}

	async countByQuoteId(quoteId) {
		return await HttpService.get(`${QuoteCommentService.BASE_URL}/count?quoteId=${quoteId}`);
	}

	async pinComment(quoteId, commentId) {
		const response = await axios.put(`${QuoteCommentService.BASE_URL}/pin?quoteId=${quoteId}&commentId=${commentId}`);
		return response.data;
	}

	async unpinComment(commentId) {
		const response = await axios.put(`${QuoteCommentService.BASE_URL}/unpin?commentId=${commentId}`);
		return response.data;
	}
}

export default new QuoteCommentService();
