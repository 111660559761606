import React from "react";
import { connect } from 'react-redux';
import auditService from '../../services/AuditService';
import DataTable from '../table/DataTable';
import TableCell from '../table/TableCell';
import {Col, Row, Table} from "react-bootstrap";
import Loading from "../Loading";
import Pagination from "../table/pagination";
import "./AuditTable.css"
import {Forwarder, QuoteClothingMethod} from "../../utils/consts/Const";
import {securityService} from "../../services/SecurityService";

const QUOTE = "Quote";
const USER = "CrmUser";
const DRIVER = "Driver";
const CONTRACTOR = "Contractor";
const ORGANIZATION = "Organization";
const VEHICLE = "Vehicle";
const CARCASS = "Carcass";
const LOADING_TYPE = "LoadingType";
const CARGO_TYPE = "CargoType";
const DOCUMENT_TYPE = "DocumentType";
const DEPARTMENT = "Department";
const DIVISION = "Division";
const POSITION = "Position";
const TASK = "Task";

class AuditTable extends React.PureComponent {
	static get FIELD_LABELS() {
		return {
			"model": "Модель",
			"number": "Номер",
			"position": "Порядковый номер",
			"pointType": "Тип",
			"address": "Адрес",
			"carcass": "Тип кузова",
			"loadingType": "Тип загрузки",
			"temperature": "Температура",
			"weight": "Вес",
			"volume": "Объем",
			"timeFrom": "Время с",
			"timeTo": "Время до",
			"contact": "Контактное лицо",
			"city": "Город",
			"fullAddress": "Полный адрес",
			"cargoType": "Груз/упаковка",
			"width": "Ширина",
			"height": "Высота",
			"length": "Длина",
			"status": "Статус",
			"source": "Источник",
			"dt": "Дата",
			"createdAt": "Дата создания",
			"expireDt": "Действует до",
			"commercialDepManager": "Менеджер КО",
			"logisticDepManager": "Менеджер ОЛ",
			"organization": "Организация заказчика",
			"contractor": "Контрагент",
			"contractors": "Контрагенты",
			"contractorContact": "Контактное лицо заказчика",
			"contractorVatMode": "Режим НДС заказчика",
			"contractorPaymentMode": "Условия оплаты заказчика",
			"contractorExpenses": "Доп. расходы заказчика",
			"contractorExpensesDesc": "Описание доп. расходов заказчика",
			"contractorPrice": "Ставка заказчика",
			"contractorPaymentDelay": "Отсрочка платежа заказчика",
			"carrierOrganization": "Организация перевозчика",
			"carrier": "Перевозчик",
			"carrierContact": "Контактное лицо перевозчика",
			"carrierVatMode": "Режим НДС перевозчика",
			"carrierPaymentMode": "Условия оплаты перевозчика",
			"carrierPaymentDelay": "Отсрочка платежа перевозчика",
			"ttnStatus": "Статус ТТН",
			"ttnNumber": "Номер ТТН",
			"ttnDt": "Дата ТТН",
			"driver": "Водитель",
			"truck": "Тягач",
			"trailer": "Прицеп",
			"carrierPrice": "Ставка перевозчика",
			"carrierExpenses": "Доп. расходы перевозчика",
			"carrierExpensesDesc": "Описание доп. расходов перевозчика",
			"carrierGsm": "Лимит ГСМ перевозчика",
			"cargoPrice": "Цена груза",
			"priceInitial": "Ставка заказчика стартовая",
			"quantity": "Кол-во",
			"finalDt": "Дата финальной заявки",
			"description": "Описание",
			"routePoints": "Точка маршрута",
			"firstName": "Имя",
			"lastName": "Фамилия",
			"middleName": "Отчество",
			"person": "Физ.лицо",
			"name": "Название",
			"id": "Ид",
			"value": "Значение",
			"userRoles": "Роль",
			"password": "Пароль",
			"active": "Активен",
			"accredited":"Аккредитован",
			"department": "Подразделение",
			"departments": "Подразделения",
			"divisions": "Отделы",
			"division": "Отдел",
			"phone": "Телефон",
			"authority": "В лице",
			"authorityPosition": "Должность",
			"authorityFio": "Фио рук.",
			"ogrn": "Огрн",
			"inn": "Инн",
			"kpp": "Кпп",
			"bank": "Банк",
			"bik": "Бик",
			"account": "Р\\счет",
			"correspondentAccount": "Кор.счет",
			"fullName": "Полное наим.",
			"paymentDelay": "Отсрочка платежа",
			"external": "Не рф",
			"agreement": "Договор",
			"contractorType": "Тип контрагента",
			"contractorMode": "Вид контрагента",
			"approvalStatus": "Статус согласования",
			"comment": "Комментарий",
			"vtype": "Тип",
			"passportNumber": "Паспорт №",
			"passportIssuedBy": "Место выдачи паспорта",
			"passportIssuedDate": "Дата выдачи паспорта",
			"birthday": "Дата родж.",
			"snils": "Снилс",
			"license": "Вод. удост.",
			"licenseIssuedDate": "Дата выдачи вод. удостоверения",
			"foreigner": "Не рф",
			"clientQuoteNumber": "Номер заявки заказчика",
			"clientQuoteDt": "Дата заявки заказчика",
			"carrierQuoteNumber": "Номер заявки перевозчика",
			"carrierQuoteDt": "Дата заявки перевозчика",
			"gsmStrait": "Пролив ГСМ",
			"gsmDiscount": "Скидка ГСМ",
			"debtCarrier": "Долг перевозчику",
			"paymentDt": "Дата оплаты заказчиком",
			"contractorPaymentDt": "Дата оплаты заказчиком",
			"clientContract": "Договор заказчика",
			"carrierContract": "Договор перевозчика",
			"clientPrepayment": "Предоплата заказчика",

			"displayedName": "Имя файла",
			"originalName": "Исходное имя файла",
			"attachmentType": "Тип файла",
			"documentType": "Тип документа",
			"attachments": "Сканкопии",

			"signed": "Заявка подписана",
			"signedBy": "Подписант заявки",
			"signedAt": "Время подписания заявки",

			"removed": "Удалено",

			"paymentAccounts": "Р/счета",
			"paymentAccount": "Р/счет",

			"contracts": "Договоры",
			"contractType": "Тип договора",
			"paymentMode": "Тип оплаты",
			"paymentDelayType": "Тип отсрочки",
			"vatMode": "Форма оплаты",
			"supplementaryAgreement": "Есть дополнительное соглашение",
			"contractorAuthority": "В лице",
			"organizationAuthority": "В лице",
			"organizationAuthorityPosition": "Должность",
			"organizationPaymentAccount": "Р/счет организации",
			"mainContract": "Основной договор",
			"arrivalDt": "Дата прибытия факт",
			"departureDt": "Дата отъезда факт",
			"kilometre": "Километры",

			"topic": "Тема",
			"startDate": "Дата начала выполнения",
			"dueDate": "Дата конца выполнения",
			"verifications": "Статус проверки",
			"reporters": "Проверяющие",
			"assignees": "Ответственные",
			"owner": "Создал",
			"title": "Другое",
			"type": "Тип",
			"parentTask": "Родительская задача",
			"previousTask": "Выполнить после",

			"legalAddress": "Юридический адрес",
			"mailingAddress": "Почтовый адрес",
			"site": "Сайт",
			"email": "Почта",
			"atiId": "Код в АТИ",
			"currencyControl": "Подлежит валютному контролю",
			"externalInn": "ИНН не в РФ",
			"rating": "Рейтинг контрагента",
			"cubage": "Кубатура",
			"tonnage": "Тоннаж",
			"ptsNumber": "Серия, номер ПТС",
			"ptsIssuedDate": "Дата выдачи ПТС",
			"ownerFio": "ФИО собственника",
			"ownershipType": "Тип собственности",
			"viewName": "Представление наименования",
			"ttnReturnDays": "Количество дней на возврат ТТН",
			"paymentDocuments": "Документы для оплаты",
			"contractorAuthorityPosition": "Должность",
			"contractorActionBasis": "Действует на основании (Конт.)",
			"organizationActionBasis": "Действует на основании (Орг.)",
			"validFrom": "Действует с",
			"validTo": "Действует по",
			"contractStatus": "Статус",
			"closingMethod": "Метод закрытия",
			"copy": "Копия",
			"addresses": "Адреса",
			"mainQuote": "Основная заявка",
			"vehicleMark" : "Марка",
			"vehicleModel" : "Модель",
			"vin" : "Vin",
		};
	}

	constructor(props) {
		super(props);
		this.writeRecursively = this.writeRecursively.bind(this);
		this.initializedChanges = this.initializedChanges.bind(this);
		this.renderRows = this.renderRows.bind(this);
		this.selectChange = this.selectChange.bind(this);
		this.setNestedRouteFields = this.setNestedRouteFields.bind(this);
		this.setNewField = this.setNewField.bind(this);
		this.setDefaultNestedChange = this.setDefaultNestedChange.bind(this);
		this.onPageChanged = this.onPageChanged.bind(this);
		this.toggleStickiness = this.toggleStickiness.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.pushChangeAfter = this.pushChangeAfter.bind(this);
		this.toggleStickinessHorizont = this.toggleStickinessHorizont.bind(this);
		this.renderRouteTableRow = this.renderRouteTableRow.bind(this);
		this.renderNestedRow = this.renderNestedRow.bind(this);
		this.tableRef = React.createRef();
		this.tableParentRef = React.createRef();
		this.tableHeaderRef = React.createRef();
		this.state = {
			fields: [],
			columns: [],
			isLoading: true,
			isOldQuotes: false,
			page: 0,
			pages: 1,
			scrollLeft: 0,
			isIncrementPositionCh: false
		};
	}
	
	fetch(params) {
		return auditService.list({
			...params,
			sourceId: this.props.sourceId,
			source: this.props.source,
			userId: this.props.userId,
			pageSize: 30,
			page: this.state.page
		});
	}
	tableClass = () => {
		return {
			"width": `${(this.state.columns.length + 1) * 300}px`
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		this.initializedChanges();
	}
	scrollHandlerAdded = false

	setRowsSize() {
		const rows = document.querySelectorAll(".sticky-column")
		rows.forEach(row => {
			Array.from(row.children).map(child => {
				if(child.getBoundingClientRect().height !== row.getBoundingClientRect().height) {
					child.style.height = `${row.getBoundingClientRect().height - 1}px`
					child.style.width = `${row.getBoundingClientRect().width - 1}px`
				}})
		} )
	}

	componentDidUpdate() {
		if (!this.scrollHandlerAdded && this.tableRef.current && !this.state.isLoading) {
			this.tableParentRef.current.addEventListener("scroll", this.toggleStickinessHorizont);
			this.scrollHandlerAdded = true;
		}
	}

	isHeaderChildOver = false

	toggleStickiness = ({ top, bottom }) => {
		const fixedHeader = document.querySelector(".fixedHeaderTable")
		if (top <= 0 && bottom > 2 * 68) {
			fixedHeader.style.display = "block"
			const fieldHeaderRow = document.querySelector(".fieldHeaderRow")

			if(!this.isHeaderChildOver && fieldHeaderRow.getBoundingClientRect().height !== 0) {
				const fieldHeaderRowChild = Array.from(fieldHeaderRow.children)
				fieldHeaderRowChild[0].style.width = `${fieldHeaderRow.getBoundingClientRect().width - 1}px`
				fieldHeaderRowChild[0].style.height = `${fieldHeaderRow.getBoundingClientRect().height - 2}px`
				this.isHeaderChildOver = true
			}

		} else {
			fixedHeader.style.display = "none"
		}
	};

	toggleStickinessHorizont = (event) => {
		this.tableHeaderRef.current && (this.tableHeaderRef.current.style.transform = `translateX(-${event.target.scrollLeft}px`)
		const fieldHeaderRow = document.querySelector(".fieldHeaderRow")
		fieldHeaderRow.style.transform = `translateX(${event.target.scrollLeft}px`
	};

	handleScroll = () => {
		this.tableRef && this.toggleStickiness(this.tableRef.current.getBoundingClientRect());
	};

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
		this.tableParentRef.current
			&& this.tableParentRef.current.removeEventListener("scroll", this.toggleStickinessHorizont);
	}

	isOldQuotes(changes) {
		let isOld;

		if(!this.state.isOldQuotes) {
			changes.map(change => {
				if(change.field === "routePoints") {
					isOld = true
					for(let i = 0; i < change.changes?.length; i++) {
						if (change.changes[i].field === "position") {
							isOld = false
							break
						}
					}
			}})

			isOld && this.setState({isOldQuotes: true})
		}
	}

	initializedChanges() {
		this.fetch().then(data => {
			data.data && data.data.map(column => {
				this.isOldQuotes(column.changes)
				this.setFields(column.changes);
			})
			this.setState({pages: data.pages})
			this.setState({columns: [...data.data]})
			this.setState({isLoading: false});
			this.setRowsSize()
		})
	}

	isUniqueFields(fields, changeField, changes) {
		if((fields?.name || changes.field) === "routePoints" && !this.state.isOldQuotes) {
			let positionIndex = this.findRouteChildPosition(changes, fields)
			return changeField !== "position"
				&& (positionIndex !== -1
					? fields.children[positionIndex].changes.every(field => {return (field !== changeField)})
					: true)
		}
		if(changeField !== "id") {
			return fields.children?.every(field => {return (field?.name || field) !== changeField});
		}
	}

	findRouteChildPosition(changes, fields) {
		const positionValue = this.setRoutePosition(changes)
		return fields.children.findIndex(field => field.name === positionValue)
	}

	setRoutePosition(changes) {
		if(changes.length > 0) {
			for(let i = 0; i < changes.length; i++) {
				if(changes[i].field === "position") {
					return parseInt(changes[i].after) + 1
				}
			}
			return false
		}
	}

	setNewField(changeField, changes) {
		this.setState({fields:
				[...this.state.fields,
					{name: changeField,
						children: (changeField === "routePoints" && !this.state.isOldQuotes)
							? [{name: this.setRoutePosition(changes), changes: []}]
							: []
					}
				]
		});
	}

	isTerribleRoute(change) {
		return change.field === "routePoints" && change.description && change.description === "удалено - "
	}

	setFields(changes, level, currentField) {
		if (!level) {
			level = 0;
		}
		level++;

		changes.map(change => {

			if(change.changes && level !== 2 && !this.isTerribleRoute(change)) {
				this.state.fields.every(field => {return field.name !== change.field})
					&& this.setNewField(change.field, change.changes)

				const currentFieldIndex = this.state.fields.findIndex(field => {return field.name === change.field});
				return this.setFields(change.changes, level, currentFieldIndex);

			} else if(level !== 3) {
				let fields = this.state.fields;

				level === 1 ?
					this.state.fields.every(field => {return field.name !== change.field})
					&& (fields = [...this.state.fields, {name: change.field, children:[]}])
				:
					this.isUniqueFields(fields[currentField], change.field, changes)
						&& ((fields[currentField].name === "routePoints" && !this.state.isOldQuotes)
							? this.setNestedRouteFields(fields, currentField, changes, change.field)
							: fields[currentField].children = [...fields[currentField].children, change.field])
				this.setState({fields: fields});
			}
		});
	}

	setNestedRouteFields(fields, currentField, changes, changeField) {
		this.findRouteChildPosition(changes, fields[currentField]) !== -1
			? fields[currentField].children[this.findRouteChildPosition(changes, fields[currentField])].changes = [
				...fields[currentField].children[this.findRouteChildPosition(changes, fields[currentField])].changes, changeField]
			: this.setRoutePosition(changes)
				&& (fields[currentField].children =
					[...fields[currentField].children, {name: this.setRoutePosition(changes), changes: [changeField !== "position" && changeField]}])
		return fields
	}

	nestedChange = [];

	setDefaultNestedChange(children) {
		this.nestedChange = Array.from({ length: children.length }, (v, i) =>  []);
		children[0]?.changes
			&& this.nestedChange.map((ch, index) =>
				this.nestedChange[index] =
					{name: children[index].name, changes: Array.from({ length: children[index].changes?.length }, (v, i) =>  [])}
			)
	}
	writeRecursively(changes, field, level, currentChild, position) {

		if (!level) {
			level = 0;
		}

		level++;
		return changes.map((change) => {
			if(level === 1 ) {

				return this.renderFirstChangeRec(change, field, level, currentChild, position);

			} else if(level === 2) {

				if(position && position === this.setRoutePosition(changes) && currentChild === change.field) {
					return <div style={{"padding": "0 5px"}}>{this.renderChangeAfter(change)}</div>
				} else if(!position && currentChild && currentChild === change.field) {
					return <div style={{"padding": "0 5px"}}>{this.renderChangeAfter(change)}</div>
				}

			}
		})
	}

	renderPagination() {
		return (
			<Row>
				<Col style={{display: "flex", justifyContent: "center"}}>
					<Pagination page={this.state.page} pages={this.state.pages} onPageChanged={this.onPageChanged} />
				</Col>
			</Row>
		)
	}

	async onPageChanged(e) {
		await this.setState({page: e})
		this.initializedChanges()
	}

	pushChangeAfter(change) {
		let changeAfter = change.after

		if (change.field === "position" && change.after) {
			changeAfter = Number(change.after) + 1;
		}

		return changeAfter
	}

	isHTML(str) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(str, 'text/html');
		return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
	}

	getTextFromHTML(htmlStr) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlStr, 'text/html');
		return doc.body.textContent || "";
	}

	renderTextAfter(change) {
		let textAfter = this.isHTML(change.after) ? this.getTextFromHTML(change.after) : change.after
		change.field === "position" && (textAfter++)
		textAfter === "true" && (textAfter = "Да")
		textAfter === "false" && (textAfter = "Нет")
		textAfter === QuoteClothingMethod.BY_LOGISTIC.name && (textAfter = QuoteClothingMethod.BY_LOGISTIC.value)
		textAfter === QuoteClothingMethod.FIXED_RATE.name && (textAfter = QuoteClothingMethod.FIXED_RATE.value)
		return change.description === "DELETE" ? `Удалено - ${textAfter || ''}` : textAfter
	}


	renderChangeAfter(change) {
		if(change) {
			if(change?.changes) {
				return this.renderChangeAfter(change.changes[1])
			}
			return this.renderTextAfter(change)
		}
	}

	renderFirstChangeRec(change, field, level, currentChild, position) {
		return (change?.changes && change.field === field)
			? this.writeRecursively(change.changes, field, level, currentChild, position)
			: change.field === field && <div style={{"padding": "0 5px"}}>{this.renderChangeAfter(change)}</div>
	}

	selectChange(fieldName, currentChild, position) {
		let rows, allRows

		allRows = document.querySelectorAll('.isActiveRow')

		allRows.forEach(tr => tr.classList.remove("trActive"))
		if(position) {
			rows = document.querySelectorAll(`.row_${fieldName}_${position}_${currentChild}`)
		} else if(currentChild) {
			rows = document.querySelectorAll(`.row_${fieldName}_${currentChild}`)
		} else rows = document.querySelectorAll(`.row_${fieldName}`)

		rows.forEach(row => row.classList.add("trActive"))
	}

	rowsClasses = () => {
		return {
			minHeight: "100%",
			padding: "0",
			minWidth: "300px",
			margin: "auto 0",
			fontSize: "12px",
			fontWeight: "600",
			backgroundColor: "rgb(245, 245, 247)"
		};
	}

	renderRouteTableRow(child, field) {
		return child.changes.map(childRoute =>
			<tr
				className={`row_${field.name}_${child.name}_${childRoute} isActiveRow`}
				style={{backgroundColor: "#fafbff"}}
				onClick={() => this.selectChange(field.name, childRoute, child.name)}>

				<td
					style={this.rowsClasses()}
					className={`sticky-column-cell isActiveRow row_${field.name}_${child.name}_${childRoute} sticky-column`}>
					<td
						className={"noneBorderVert"}
						style={{minWidth: "149px", height: "100%"}}>
						{`${AuditTable.FIELD_LABELS[field.name] || field.name}: ` + child.name}
					</td>
					<td
						className={"borderRight"}
						style={{minWidth: "149px", height: "100%"}}>
						{AuditTable.FIELD_LABELS[childRoute] || childRoute}
					</td>
				</td>

				{this.renderColumns(field, childRoute, child.name)}
			</tr>)
	}

	renderNestedRow(child, field) {
		return <tr
			className={`row_${field.name}_${child} isActiveRow`}
			style={{backgroundColor: "#fafbff", minHeight: "29px"}}
			onClick={() => this.selectChange(field.name, child)}>

			<td
				style={this.rowsClasses()}
				className={`sticky-column-cell isActiveRow row_${field.name}_${child} sticky-column`}>
				<td
					className={"noneBorderVert"}
					style={{minWidth: "149px", height: "100%"}}>
					{`${AuditTable.FIELD_LABELS[field.name] || field.name}: `}
				</td>
				<td
					className={"borderRight"}
					style={{minWidth: "149px", height: "100%"}}>
					{AuditTable.FIELD_LABELS[child] || child}
				</td>
			</td>

			{this.renderColumns(field, child)}
		</tr>
	}

	renderFirstLevelRow(field) {
		return <tr
			className={`row_${field.name} isActiveRow`}
			style={{backgroundColor: "#fafbff"}}
			onClick={() => this.selectChange(field.name)}>

			<td
				style={this.rowsClasses()}
				className={`sticky-column-cell isActiveRow sticky-column row_${field.name}`}>
				<td className={"noneBorderVert"} style={{padding: "5px"}}>
					{AuditTable.FIELD_LABELS[field.name] || field.name}
				</td>
			</td>

			{this.renderColumns(field)}
		</tr>
	}

	renderRows() {
		return <tbody>
		{
			this.state.fields.map((field) =>
				field.children.length > 0 ? field.children.map(child =>
					child.name
						? this.renderRouteTableRow(child, field)
						: this.renderNestedRow(child, field)
			) : this.renderFirstLevelRow(field))
		}
		</tbody>
	}

	renderColumns(field, child, position) {
		return this.state.columns.map(column =>
			<td style={{"min-width": "300px", "padding": "0", "margin": "0", "font-size": "12px", "overflow-x": "auto"} }>
				{this.writeRecursively(column.changes, field.name, 0, child, position)}
			</td>);
	}

	renderHeader() {
		const columns = this.state.columns;
		return <thead style={{"font-weight": "600"}}>
				<tr style={{backgroundColor: "white"}}>
					<td className={`sticky-column-cell sticky-column fieldHeaderRow`}
						style={{backgroundColor: "rgb(245, 245, 247)", padding: 0}}>

						<td className={"noneBorderVert"} style={{padding: "5px", height: "100%"}}>Реквизиты</td>

					</td>
					{columns.map(column => this.renderChanges(column))}
				</tr>
			</thead>
	}


	isArttekNotExternalUser() {
		const user = securityService.getUser()
		return securityService.isAdmin(user)
			|| (securityService.forwarderIsArttek(Forwarder.ARTTEK()) && !securityService.isExternal(user))
	}

	renderChanges(column) {
		return <td>
				{`${this.isArttekNotExternalUser() ? column.fio : ""}`}
				<span style={{"display": "block"}}>{new Date(column.dt).toLocaleString()}</span>
			</td>
	}

	render() {
		if(this.state.isLoading) return <Loading/>

		return <div style={{"overflow-x": "auto", position: "relative"}} className={"AuditTable"} ref={this.tableParentRef}>

			<div className={"fixedHeaderTable"} style={{
				overflowX: "hidden",
				width: "100%",
				display: "none",
				height: `${this.isArttekNotExternalUser() ? "60px" : "36px"}`
			}}>
				<Table striped bordered hover size="sm" ref={this.tableHeaderRef}
					   style={{"width": `${(this.state.columns.length + 1) * 300}px`}}>
					{this.renderHeader()}
				</Table>
			</div>

			<Table ref={this.tableRef} striped bordered hover size="sm" style={this.tableClass()}>
				{this.renderHeader()}
				{this.renderRows()}
			</Table>
			{this.renderPagination()}
			</div>
	}
}

const connected =  connect(DataTable.mapStateToProps)(AuditTable);
export { connected as AuditTable, QUOTE, USER, DRIVER, CONTRACTOR, ORGANIZATION, VEHICLE, CARCASS, LOADING_TYPE, CARGO_TYPE,
	DOCUMENT_TYPE, DEPARTMENT, DIVISION, POSITION, TASK };
