import { NavLink, Redirect } from 'react-router-dom';
import {Button, Form, InputGroup} from "react-bootstrap";
import {emailValidator, phoneValidator, requiredValidator} from "../validators/simpleValidators";
import ValidationErrors from "../validators/ValidationErrors";
import BaseForm from './form/BaseForm';

import { connect } from "react-redux";
import { login } from "../actions/auth";
import Notification from "./Notification";
import { clearNotification } from "../actions/notification";
import { SHOW_WELCOME_MODAL } from "../actions/types";
import {securityService} from "../services/SecurityService";
import React from "react";
import PhoneInput from "react-phone-input-2";

class Login extends BaseForm {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			username: "",
			password: "",
			errors: {},
			showPassword: false,
			loginForm: "email"
		};

		this.handleLogin = this.handleLogin.bind(this);
		this.handleShowPassword = this.handleShowPassword.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeLoginForm = this.onChangeLoginForm.bind(this);

		this.validator.validationMap = {
			username: () => (this.state.loginForm === "number" ? phoneValidator(this.state.username) : emailValidator(this.state.username)),
			password: () => requiredValidator(this.state.password)
		};
	}

	handleLogin(e) {
		e.preventDefault();
		this.submit();
	}

	submit() {
		this.setSending(true);
		this.validator.validate().then(valid => {
			if (valid) {
				const { dispatch, history } = this.props;
				dispatch(login(this.state.username, this.state.password))
					.then(() => {
						history.push(this.getRedirectPath());
                        dispatch({type: SHOW_WELCOME_MODAL});
					})
					.catch(() => this.setSending(false));
			} else {
				this.setSending(false);
			}
		})
	}

	onChange(e) {
		this.setState({[e?.target?.name]: e?.target?.value});
	}

	onClose() {
		const { dispatch } = this.props;
		dispatch(clearNotification());
	}

	onChangeLoginForm(value) {
		this.setState({loginForm: value})
		this.setState({username: ""})
	}

	renderForm() {
		const { isLoggedIn, notification } = this.props;
		let message = this.props.message
		if(message && message.includes("Unauthorized")) {
			message = 'Пользователь не авторизован!'
		}
		if (isLoggedIn) {
			return <Redirect to={this.getRedirectPath()} />;
		}
		const {showPassword} = this.state;
		return (
			<div className="col-md-12 login">
				<div className="card card-container">
					<img
						src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
						alt="profile-img"
						className="profile-img-card"
					/>

					{
						notification &&
						<Notification
							message={notification}
							show={Boolean(notification)}
							onClose={() => this.onClose()}
						/>
					}

					<Form onSubmit={this.handleLogin}>
						<div className="form-group">
							<div style={{width: "100%", display: "flex", gap: "2px", justifyContent: "center", padding: "2px", borderRadius: "5px", border: "2px solid #192744", marginBottom: "15px"}}>
								<Button variant={this.state.loginForm === "email" ? "primary" : "light"} style={{width: "50%"}} onClick={e => this.onChangeLoginForm("email")}>E-mail</Button>
								<Button variant={this.state.loginForm === "number" ? "primary" : "light"} style={{width: "50%"}} onClick={e => this.onChangeLoginForm("number")}>Телефон</Button>
							</div>
							{
								this.state.loginForm === "number"
									? <PhoneInput
										specialLabel={''}
										onlyCountries={['ru', 'kz', 'by']}
										country={'ru'}
										value={this.state.username}
										name={"username"}
										placeholder={"Введите номер телефона"}
										onChange={e => this.onChange({target: {name: "username", value: e}})}
										autoFormat={true}
										isValid={!this.validator.isInvalid("username")}
										inputStyle={{
											width: '100%'
										}}
									/>
									: <Form.Control
										placeholder="Введите e-mail"
										className="form-control"
										name="username"
										value={this.state.username}
										onChange={e => {
											const { name, value } = e.target;
											this.onChange({ target: { name, value: value.toLowerCase() } });
										}}
										isInvalid={this.validator.isInvalid("username")}
									/>
							}
							<ValidationErrors errors={this.state.errors["username"]} />
							{this.validator.isInvalid("username") && <div class="alert alert-danger" role="alert">Некорректно введен {this.state.loginForm === "number" ? "Телефон" : "Email"}</div>}
						</div>

						<div className="form-group">
							<label htmlFor="password">Пароль</label>
							<InputGroup>
								<Form.Control
									type={showPassword ? "text" : "password"}
									className="form-control"
									name="password"
									value={this.state.password}
									onChange={this.onChange}
									isInvalid={this.validator.isInvalid("password")}
								/>
								<InputGroup.Append>
									<Button variant="outline-secondary" className="show-password-btn" onClick={this.handleShowPassword}>
										<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
									</Button>
								</InputGroup.Append>
							</InputGroup>
							<ValidationErrors errors={this.state.errors["password"]} />
						</div>

						<div className="form-group">
							<button
								className="btn btn-primary btn-block"
								disabled={this.state.sending}
							>
								{this.state.sending && (
									<span className="spinner-border spinner-border-sm"></span>
								)}
								<span>Войти</span>
							</button>
						</div>

						<ValidationErrors errors={message} />

					</Form>

					<NavLink to="/registration">Регистрация</NavLink>
				</div>
			</div>
		);
	}

	handleShowPassword() {
		const {showPassword} = this.state;
		this.setState({showPassword: !showPassword});
	}

	getRedirectPath() {
		const user = securityService.getUser();
		const isExternal = securityService.isExternal(user);
		return isExternal ? "/free-quotes" : "/";
	}
}

function mapStateToProps(state) {
	const { isLoggedIn } = state.auth;
	const { message } = state.message;
	const { notification } = state.notification;
	return {
		isLoggedIn,
		message,
		notification,
	};
}

export default connect(mapStateToProps)(Login);
