import React, {PureComponent} from "react";
import mapService from "../../services/MapService";
import {
    Button,
    Card,
    Form,
    ListGroup, Table,
    ToggleButton, ToggleButtonGroup
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import dateTimeService from "../../services/DateTimeService";
import "./MapStyle.css";
import Util from "../../utils/util/Util";
import {NavLink} from "react-router-dom";
import Select from "react-select";
import MarkerIcons from "./MarkerIcons";
import {securityService} from "../../services/SecurityService";
import {Forwarder} from "../../utils/consts/Const";

class MonitoringMap extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLast: true,
            isActive: true,
            isDrivers: !securityService.isClient(),
            isActiveStatuses: true,
            driverStatus: this.DRIVER_STATUSES[0],
            startPeriod: dateTimeService.setStartOfTheDay(new Date()),
            endPeriod: dateTimeService.setEndOfTheDay(new Date()),
            planCoordinates: [],
            carriers: [],
            clients: [],
            logistics: [],
            departments: [],
            isScoutGeolocationOn: true,
            isPhoneGeolocationOn: true,
            drivers: []
        }
    }

    map = null;
    placemarks = new Map();
    clusterer = null;
    markerIcons = new MarkerIcons();

    DRIVER_STATUSES = [
        { label: "Все водители", value: "ALL" },
        { label: "Свободные водители", value: "FREE" },
        { label: "Водители на заявке", value: "BUSY" },
        { label: "Водители на завершающих этапах", value: "ON_FINISH" }
    ];

    DRIVER_OR_QUOTES = {
        options: [{ label: "Водители", value: "drivers" }, { label: "Заявки", value: "quotes" }],
        stateKey: "isDrivers"
    };

    LAST_OR_FACT = {
        options: [{ label: "Последнее местоположение", value: "last" }, { label: "Фактический путь", value: "fact" }],
        stateKey: "isLast"
    };

    ACTIVE_OR_ALL = {
        options: [{ label: "Активные водители", value: "active" }, { label: "Все", value: "all" }],
        stateKey: "isActive"
    };

    ACTIVE_OR_ACTUAL = {
        options: [{ label: "Активные заявки", value: "active" }, { label: "Актуальные", value: "actual" }],
        stateKey: "isActiveStatuses"
    };

    LOGISTIC = { state: "logistic", label: "логисты" };
    DEPARTMENT = { state: "department", label: "подразделения" };
    CARRIER = { state: "carrier", label: "перевозчики" };
    CLIENT = { state: "client", label: "заказчики" };
    TRUCK_NUMBER = { state: "truckNumber", label: "номер ТС" };
    QUOTE_STATUS = { state: "quoteStatus", label: "статус заявки" };

    componentDidMount() {
        mapService.setScriptToHtmlPage()
            .then(() => {
                ymaps.ready(() => {
                    this.removeSpinner();
                    this.initMap();
                    if (this.state.isDrivers === true) {
                        this.loadDriverListAndDrawLastPlace({}, true, true);
                    } else {
                        this.loadQuoteList({}, true);
                    }
                });
            });
    }

    loadQuoteList(data, isUpdate) {
        const request = {
            period: {
                start: dateTimeService.createDateAsUTC(data.period ? data.period.start : this.state.startPeriod),
                end: dateTimeService.createDateAsUTC(data.period ? data.period.end : this.state.endPeriod)
            },
            isActiveStatuses: data.isActiveStatuses !== undefined ? data.isActiveStatuses : this.state.isActiveStatuses,
            departmentIds: this.getValuesByDataOrState(data.department, this.DEPARTMENT.state, isUpdate),
            logisticIds: this.getValuesByDataOrState(data.logistic, this.LOGISTIC.state, isUpdate),
            carrierIds: this.getValuesByDataOrState(data.carrier, this.CARRIER.state, isUpdate),
            clientIds: this.getValuesByDataOrState(data.client, this.CLIENT.state, isUpdate),
            quoteStatusIds: this.getValuesByDataOrState(data.quoteStatus, this.QUOTE_STATUS.state, isUpdate),
            truckNumbers: this.getValuesByDataOrState(data.truckNumber, this.TRUCK_NUMBER.state, isUpdate),
            searchByNumber: data.searchByNumber ? data.searchByNumber : this.state.search
        }

        mapService.getQuotesList(request).then(quotes => {
            this.setState({ quotes: quotes });

            if (isUpdate) {
                this.updateFiltersByQuotes(quotes);
            }
        })
    }

    getValuesByDataOrState(values, stateKey, isUpdate) {
        return values !== undefined || isUpdate ? values : this.state[stateKey]?.map(it => it.value);
    }

    loadDriverListAndDrawLastPlace(data, isActive, isUpdate) {
        const hourAgo = dateTimeService.subtractHours(new Date(), 1);
        const yearAgo = dateTimeService.subtractMonths(new Date(), 12);
        const now = new Date();
        const request = {
            searchByFio: data.searchByFio ? data.searchByFio : this.state.search,
            driverStatus: data.driverStatus ? data.driverStatus : this.state.driverStatus?.value,
            period: isActive
                ? { start: dateTimeService.createDateAsUTC(hourAgo), end: dateTimeService.createDateAsUTC(now) }
                : { start: dateTimeService.createDateAsUTC(yearAgo), end: dateTimeService.createDateAsUTC(now) },
            carrierIds: this.getValuesByDataOrState(data.carrier, this.CARRIER.state, false),
            quoteStatusIds: this.getValuesByDataOrState(data.quoteStatus, this.QUOTE_STATUS.state, false),
            isScoutGeolocationOn: data.isScoutGeolocationOn !== undefined ? data.isScoutGeolocationOn : this.state.isScoutGeolocationOn,
            isPhoneGeolocationOn: data.isPhoneGeolocationOn !== undefined ? data.isPhoneGeolocationOn : this.state.isPhoneGeolocationOn
        }

        mapService.getLatestDriversGeolocation(request)
            .then(drivers => {
                if (isUpdate) {
                    this.updateFiltersByDrivers(drivers);
                }

                if (this.state.chosenQuote) {
                    this.drawDriversInCircle(drivers, data.radius, this.state.planCoordinates);
                } else {
                    this.clearMap();
                    this.drawUsersLastPlace(drivers);
                }

                this.setState({drivers: drivers});
            });
    }

    drawDriversInCircle(drivers, radius, coordinates) {
        this.removePlacemarksByStateKey("circle", "driversInCircle");

        if (coordinates.length === 0) {
            return;
        }

        const circle = new ymaps.GeoObjectCollection({}, {preset: "circle"});
        circle.add(new ymaps.Circle([
            [coordinates[0][0], coordinates[0][1]],
            (radius ? radius : 0) * 1000
        ]));

        this.map.geoObjects.add(circle);
        this.setState({circle: circle});

        const driversInCircle = new ymaps.GeoObjectCollection({}, {preset: "driversInCircle"});
        drivers.forEach(d => {
            if (this.distanceToDriver(coordinates[0][1], coordinates[0][0], d.longitude, d.latitude) <= (radius ? radius : 0)) {
                driversInCircle.add(this.createPlacemark(d));
            }
        });

        this.map.geoObjects.add(driversInCircle);
        this.setState({driversInCircle: driversInCircle});
    }

    distanceToDriver(centerLon, centerLat, driverLon, driverLat) {
        const earthRadius = 6371;

        // Преобразование градусов в радианы
        const lat1Rad = (centerLat * Math.PI) / 180;
        const lon1Rad = (centerLon * Math.PI) / 180;
        const lat2Rad = (driverLat * Math.PI) / 180;
        const lon2Rad = (driverLon * Math.PI) / 180;

        const dLat = lat2Rad - lat1Rad;
        const dLon = lon2Rad - lon1Rad;

        // Формула Гаверсина
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadius * c;
    }

    focusOnCoordinates(coordinates) {
        this.map.setBounds(
            ymaps.util.bounds.fromPoints(coordinates),
            { checkZoomRange: true }
        );
    }

    updateFiltersByDrivers(drivers) {
        const carriers = [];
        const activeQuoteIdAndStatus = [];

        drivers.map(d => {
            d.carriers.forEach(c => {
                if (!carriers.map(l => l.value).includes(c.id)) {
                    carriers.push({ value: c.id, label: c.value });
                }
            })

            d.activeQuoteIdAndStatus.forEach(c => {
                if (!activeQuoteIdAndStatus.map(l => l.value).includes(c.id)) {
                    activeQuoteIdAndStatus.push({ value: c.id, label: c.value });
                }
            })
        });

        carriers.sort((a, b) => a.label > b.label ? 1 : -1);

        this.setState({
            [[this.CARRIER.state + "s"]]: carriers,
            [[this.QUOTE_STATUS.state + "s"]]: activeQuoteIdAndStatus,
            chosenDriver : null
        });
    }

    updateFiltersByQuotes(quotes) {
        [this.LOGISTIC, this.DEPARTMENT, this.CARRIER, this.CLIENT, this.TRUCK_NUMBER, this.QUOTE_STATUS]
            .forEach(f => {
                const list = [];

                quotes.map(q => {
                    if (q[f.state + "Value"] && !list.map(l => l.value).includes(q[f.state + "Id"])) {
                        list.push({ value: q[f.state + "Id"], label: q[f.state + "Value"] });
                    }
                });

                list.sort((a, b) => a.label > b.label ? 1 : -1);

                this.setState({ [[f.state + "s"]]: list });
        });

        this.setState({ chosenQuote: null });
}

    loadFactUserRoute(id, startPeriod, endPeriod, isForScout) {
        const idLabel = isForScout ? "terminalSerialId" : "crmUserId";

        const data = {
            [idLabel]: id,
            startPeriod: dateTimeService.createDateAsUTC(startPeriod),
            endPeriod: dateTimeService.createDateAsUTC(endPeriod)
        };

        (isForScout ? mapService.getScoutActualRoute(data) : mapService.getActualRoute(data))
            .then(route => {
                this.setState({route: route});
                this.drawFactRoute(route);

                if (route.length !== 0) {
                    this.focusOnCoordinates(this.getDriverCoordinates(route));
                }
            });
    }

    loadPlanUserRoute(crmUserId, startPeriod, endPeriod) {
        mapService.getPlannedRoute({
                crmUserId: crmUserId,
                startPeriod: dateTimeService.createDateAsUTC(startPeriod),
                endPeriod: dateTimeService.createDateAsUTC(endPeriod)
        })
            .then(planCoordinates => {
                this.setState({ planCoordinates: planCoordinates });
                this.drawPlanRoute(this.getDriverCoordinates(planCoordinates));
            });
    }

    drawPlanRoute(coordinates) {
        this.removePlacemarksByStateKey("userPlanRoute");
        const geoObjects = new ymaps.GeoObjectCollection({}, { preset: "userPlanRoute" });

        geoObjects.add(new ymaps.multiRouter.MultiRoute(
            { referencePoints: coordinates },
            { routeActiveStrokeColor: this.markerIcons.getRouteColor() }
        ));

        this.setState({ userPlanRoute: geoObjects });
        this.map.geoObjects.add(geoObjects);
    }

    getDriverCoordinates(drivers) {
        return drivers?.map(c => [ c.latitude, c.longitude ])
            .filter(c => c[0] && c[1]);
    }

    isActive(dt) {
        return new Date(dt) > dateTimeService.subtractHours(new Date(), 1);
    }

    drawUsersLastPlace(drivers) {
        if (this.clusterer) {
            this.clusterer.removeAll();
        }

        const pacemarks = [];
        drivers?.forEach(driver => {
            const placemark = this.createPlacemark(driver);
            pacemarks.push(placemark);
            this.placemarks.set(driver.crmUserId, placemark);
        });

        this.addToClusters(pacemarks);

        if (drivers.length > 0) {
            this.focusOnCoordinates(this.getDriverCoordinates(drivers));
        }
    }

    addToClusters(pacemarks) {
        this.clusterer = new ymaps.Clusterer({ preset: 'islands#invertedVioletClusterIcons' });

        this.clusterer.add(pacemarks);
        if (this.map) {
            this.map.geoObjects.add(this.clusterer);
        }
    }

    createPlacemark(driver) {
        let placemark = new ymaps.Placemark(
            [ driver.latitude, driver.longitude ],
            {
                balloonContentHeader: driver.fio,
                balloonContentBody: new Date(driver.dt).toLocaleString(),
                balloonContentFooter: driver.latitude + " " + driver.longitude
            },
            this.markerIcons.getIconsForDriver(driver.dt)
        );

        placemark.events.add('click', () => this.setState({ chosenDriver: driver }));

        return placemark;
    }

    removePlacemarksByStateKey(...stateKeys) {
        stateKeys.forEach(stateKey => {
            if (this.state[stateKey]) {
                this.map.geoObjects.remove(this.state[stateKey]);
                this.setState({ [stateKey] : null });
            }
        })
    }

    drawFactRoute(route) {
        this.removePlacemarksByStateKey("userFactRoute");
        const geoObjects = new ymaps.GeoObjectCollection({}, { preset: "userFactRoute" });

        for (let i = 0; i < route.length; i++) {
            const placemark = new ymaps.Placemark(
                [ route[i].latitude, route[i].longitude ],
                { hintContent: new Date(route[i].dt).toLocaleString() },
                this.markerIcons.getImageSettingsForActualRoute(route.length, i)
            );

            geoObjects.add(placemark);
            this.placemarks.set(route[i].crmUserId, placemark);
        }

        this.map.geoObjects.add(geoObjects);
        this.setState({ userFactRoute: geoObjects });

        // добавление последней точки с геолокацией водителя
        this.map.geoObjects.add(this.createLastPlacePlacemark(this.state.chosenDriver));
    }

    createLastPlacePlacemark(driver) {
        this.removePlacemarksByStateKey("userLastPlace");
        const lastPlace = new ymaps.GeoObjectCollection({}, { preset: "userLastPlace" });
        const placemark = this.createPlacemark(driver);
        this.setState({ userLastPlace: placemark });
        lastPlace.add(placemark);

        return lastPlace;
    }

    removeSpinner() {
        const loadSpinner = document.getElementById("yandex-map-load-spinner");
        if (loadSpinner) {
            loadSpinner.remove();
        }
    }

    initMap() {
        const defaultCenter = [ 45.035483, 38.975285 ];

        this.map = new ymaps.Map("yandex-map", {
            center: defaultCenter,
            zoom: 10
        });
    }

    setDriverOrQuote() {
        this.clearMap();
        this.setState({route: []});

        if (this.state.isDrivers) {
            this.loadQuoteList({ carrier: [] }, true);
        } else {
            this.setState({ chosenQuote: null });
            this.loadDriverListAndDrawLastPlace({ carrier: [] }, this.state.isActive, true);
        }

        this.setState({ isDrivers: !this.state.isDrivers });
        this.clearAllFilters();
    }

    clearAllFilters() {
        this.setState({department: [], logistic: [], client: [], carrier: []})
    }

    setActualOrActiveQuotes = (event) => {
        this.removePlacemarksByStateKey("userPlanRoute", "circle", "driversInCircle", "radius");

        this.setState({ isActiveStatuses: event.target.value === "active", chosenQuote: null });
        this.loadQuoteList({ isActiveStatuses: event.target.value === "active" }, true);
    }

    clearMap() {
        this.map.geoObjects.removeAll();
    }

    setLastOrFactUsers() {
        this.setState({route: []});

        if (this.state.chosenDriver && this.state.isLast) {
            this.clearMap();
            const now = new Date();
            this.loadPlanUserRoute(this.state.chosenDriver.crmUserId, now, now);
            this.loadFactUserRoute(
                this.state.chosenDriver.crmUserId,
                this.state.startPeriod,
                this.state.endPeriod,
                this.state.chosenDriver.isScout
            );
        } else {
            this.loadDriverListAndDrawLastPlace({}, this.state.isLast ? false : this.state.isActive, true);
        }

        this.setState({ isLast: !this.state.isLast, isActive: false});
    }

    setActiveOrAllUsers() {
        const isActive = !this.state.isActive;
        this.loadDriverListAndDrawLastPlace({}, isActive, true);
        this.setState({ isActive: isActive });
    }

    chooseDriver(driver, startPeriod, endPeriod) {
        this.zoomToDriverAndOpenBalloon(driver);
        this.setState({ chosenDriver: driver });

        if (!this.state.isLast) {
            const now = new Date();

            this.clearMap();
            this.loadPlanUserRoute(driver.crmUserId, now, now);
            this.loadFactUserRoute(
                driver.crmUserId,
                startPeriod ? startPeriod : this.state.startPeriod,
                endPeriod ? endPeriod : this.state.endPeriod,
                driver.isScout
            );
        }
    }

    zoomToDriverAndOpenBalloon(driver) {
        if (driver) {
            this.map.panTo([ driver.latitude, driver.longitude ])
                .then(() => this.placemarks?.get(driver.crmUserId).balloon.open());
        }
    }

    chooseQuote(quote) {
        if (quote.quoteId !== this.state.chosenQuote?.quoteId) {
            this.clearMap();

            mapService.getQuoteAddress(quote.quoteId)
                .then(route => {
                    const coordinates = this.getDriverCoordinates(route);
                    this.drawPlanRoute(coordinates);
                    if (coordinates.length > 0) {
                        this.map.setBounds(ymaps.util.bounds.fromPoints(coordinates), { checkZoomRange: true });
                    }
                    this.drawDriversInCircle(this.state.drivers, this.state.radius, coordinates);
                    this.setState({ planCoordinates: coordinates });
                })

            mapService.getUserLastGeolocationByQuoteId(quote.quoteId)
                .then(driver => {
                    if (driver) {
                        this.map.geoObjects.add(this.createPlacemark(driver));
                    }
                });

            this.setState({ chosenQuote: quote });
        }
    }

    searchByString() {
        if (this.state.isDrivers) {
            this.loadDriverListAndDrawLastPlace({ searchByFio: this.state.search }, this.state.isActive, true)
        } else {
            this.loadQuoteList({ searchByNumber: this.state.search }, true);
        }
    }

    updateData() {
        this.clearMap();
        this.removePlacemarksByStateKey("radius");

        if (this.state.isDrivers) {
            this.loadDriverListAndDrawLastPlace({}, this.state.isActive, true);
        } else {
            this.loadQuoteList({}, true);
        }

        this.clearAllFilters();
    }

    renderUpdateButton() {
        return <Button style={{ height: "33px", marginBottom: "16px" }} variant="outline-primary"
                                   size="sm"
                                   onClick={() => this.updateData()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
            </svg>
        </Button>
    }

    renderRadioButtons(data, onChangeFunc, isOnMap) {
        return <div style={{ display: "flex", height: "33px", marginBottom: "16px" }}>
            <ToggleButtonGroup
                type="radio"
                size="sm"
                name="options"
                defaultValue={ this.state[data.stateKey] ? data.options[0].value : data.options[1].value }>
                {data.options.map(o => <ToggleButton
                        style={{ whiteSpace: "nowrap"}}
                        type="radio"
                        variant={"outline-primary"}
                        value={o.value}
                        checked={!this.state[data.stateKey]}
                        onChange={onChangeFunc}>
                        {o.label}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
            {!this.state.isDrivers && isOnMap && this.state.chosenQuote && this.renderRadiusInput()}
        </div>
    }

    renderListPanel() {
        const forwarderIsArttek = securityService.forwarderIsArttek(Forwarder.ARTTEK());
        const listForDrivers = driver => (
            <ListGroup.Item active={ driver.crmUserId === this.state.chosenDriver?.crmUserId }
                            as="li"
                            onClick={() => this.chooseDriver(driver)}>
                <div>
                    <div className="flex-space-between">
                        <div>
                            <span>{driver.fio}</span>
                        </div>
                        <div className="geo-datetime" style={{ color: this.isActive(driver.dt) && "#00F008" }}>
                            <p style={{ marginBottom: 0 }}>{new Date(driver.dt).toLocaleString("ru-RU")}</p>
                        </div>
                    </div>
                    <div>
                        { forwarderIsArttek &&
                            <div style={{fontSize: "smaller"}}>
                                {Util.smartJoin(", ", driver.carriers.map(c => c.value))}
                            </div>
                        }
                        <div className="flex-space-between">
                            <div>
                                {driver.activeQuoteIdAndStatus?.length > 0
                                    ? <NavLink to={"/quotes/quote?id=" + driver.activeQuoteIdAndStatus[0].id}
                                               target="_blank"
                                               className="flex-space-between"
                                               style={{ fontSize: "smaller", color: this.state.chosenDriver?.crmUserId === driver.crmUserId ? "white" : "#007bff" }}>
                                        <span>{driver.activeQuoteIdAndStatus[0].id + " : " + driver.activeQuoteIdAndStatus[0].value}</span>
                                    </NavLink>
                                    : <></>}
                            </div>
                            <div>{driver.truckNumber}</div>
                        </div>
                    </div>
                </div>
            </ListGroup.Item>
        );

        const isClient = securityService.isClient();

        const listForQuotes = quote => (
            <ListGroup.Item active={ quote.quoteId === this.state.chosenQuote?.quoteId }
                            as="li"
                            onClick={() => this.chooseQuote(quote)}>
                <div>
                    <div className="flex-space-between" >
                        <NavLink to={"/quotes/quote?id=" + quote.quoteId}
                                 target="_blank"
                                 style={{ color: this.state.chosenQuote?.quoteId === quote.quoteId ? "white" : "#007bff" }}>
                            <span>{isClient && quote.clientQuoteNumber ? quote.clientQuoteNumber : quote.quoteId}</span>
                        </NavLink>

                        <div className="geo-datetime">
                            {quote.quoteStatusValue}
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: "0.8rem" }}>
                            <b>Загрузка: [{new Date(quote.loadingDt).toLocaleDateString("ru-RU")}] </b>{quote.loadingCity}
                        </div>
                        <div style={{ fontSize: "0.8rem" }}>
                            <b>Разгрузка: [{new Date(quote.unloadingDt).toLocaleDateString("ru-RU")}] </b>{quote.unloadingCity}
                        </div>
                    </div>
                    <div align="right">Гос. номер: <span style={{ fontFamily: "monospace" }}>{quote.truckNumberValue}</span></div>
                </div>
            </ListGroup.Item>
        );

        return <ListGroup as="ol"
                          numbered
                          style={{cursor: "pointer"}}
        >
            {this.state.isDrivers
                ? this.state.drivers?.map(driver => listForDrivers(driver))
                : this.state.quotes?.map(quote => listForQuotes(quote))}
            </ListGroup>
    }

    changePeriod(date, stateKey) {
        if (this.state.isDrivers) {
            if (this.state.chosenDriver) {
                this.chooseDriver(
                    this.state.chosenDriver,
                    stateKey === "startPeriod" ? date : this.state.startPeriod,
                    stateKey === "endPeriod" ? date : this.state.endPeriod
                );
            }
        } else {
            const data = (stateKey === "startPeriod")
                ? { period: { start: date, end: this.state.endPeriod }}
                : { period: { start: this.state.startPeriod, end: date }};

            this.loadQuoteList(data, true);
        }

        this.setState({[stateKey] : date});
    }

    renderDatePicker(stateKey) {
        return <Form.Group style={{width: "130px"}}>
            <DatePicker
                className="map-date-picker"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect={true}
                locale={ru}
                selected={this.state[stateKey]}
                onChange={date => this.changePeriod(date, stateKey)
                }/>
        </Form.Group>
    }

    renderPeriodSelector() {
        return <div className="period">
            &nbsp;{"с"}&nbsp;{this.renderDatePicker("startPeriod")}
            &nbsp;{"по"}&nbsp;{this.renderDatePicker("endPeriod")}
        </div>
    }

    renderSearch() {
        return <div style={{display: "flex"}}>
            <Form.Control size="md"
                          type="string"
                          placeholder={"Найти..."}
                          onKeyUp={event => {if (event.key === 'Enter') this.searchByString()}}
                          onChange={e => this.setState({ search: e.target.value })} />
            <Button variant="outline-primary"
                    size="md"
                    onClick={() => this.searchByString()}>
                <i className="fas fa-search"></i>
            </Button>
        </div>
    }

    renderSelect(label, stateKey, options) {
        const onChange = (selected, actionValue) => {
            let state = selected;

            if (actionValue.action === "clear") state = [];

            const data = {[stateKey]: state instanceof Array ? state.map(s => s?.value) : state?.value};
            if (this.state.isDrivers) {
                this.loadDriverListAndDrawLastPlace(data, this.state.isActive, this.state.isLast && stateKey === "driverStatus");
            } else {
                this.loadQuoteList(data, false);
            }
            this.setState({[stateKey]: state});
        }

        return <Form.Group style={{width: "25%"}}>
            <Select
                    styles={{
                        valueContainer: styles => ({ ...styles, height: "35px", display: "flex", flexWrap: "nowrap"}),
                        multiValueRemove: base => ({ ...base, display: "none"})
                    }}
                    options={options}
                    placeholder={label}
                    hideSelectedOptions={stateKey === "driverStatus"}
                    closeMenuOnSelect={stateKey === "driverStatus"}
                    value={this.state[stateKey]}
                    classNamePrefix="react-select"
                    onChange={(selected, actionValue) => onChange(selected, actionValue)}
                    isMulti={stateKey !== "driverStatus"}
            >
            </Select>
        </Form.Group>
    }

    renderTopSelectPanel() {
        const isClient = securityService.isClient();
        const isCarrier = securityService.isCarrier();

        return <Card.Header style={{ padding: 0 }}>
            <div className="flex-space-between" >
                <div style={{display: "flex"}}>
                    {this.state.isDrivers && this.renderRadioButtons(this.LAST_OR_FACT, () => this.setLastOrFactUsers(), false)}
                    {!isClient && !this.state.isDrivers && this.renderRadioButtons(this.ACTIVE_OR_ACTUAL, this.setActualOrActiveQuotes, false)}
                    &nbsp;
                    {!this.state.isLast || !this.state.isDrivers
                        ? this.renderPeriodSelector()
                        : this.renderRadioButtons(this.ACTIVE_OR_ALL, () => this.setActiveOrAllUsers(), false)
                    }
                </div>
                {!isClient && !isCarrier && this.renderRadioButtons(this.DRIVER_OR_QUOTES, () => this.setDriverOrQuote(), true)}
            </div>
            <div style={{display: "flex", height: "38px"}}>
                {!(this.state.isDrivers || isClient) && this.renderSelect("Все подразделения", this.DEPARTMENT.state, this.state[this.DEPARTMENT.state + "s"])}
                {!(this.state.isDrivers || isClient) && this.renderSelect("Все логисты", this.LOGISTIC.state, this.state[this.LOGISTIC.state + "s"])}
                {!(this.state.isDrivers || isClient) && this.renderSelect("Все заказчики", this.CLIENT.state, this.state[this.CLIENT.state + "s"])}
                {this.state.isDrivers && this.renderSelect("Статусы водителей", "driverStatus", this.DRIVER_STATUSES)}
                {this.state.isDrivers && isCarrier && this.renderSelect("Статус завявки", "quoteStatus", this.state[this.QUOTE_STATUS.state + "s"])}
                {isClient && this.renderSelect("Номера ТС", "truckNumber", this.state[this.TRUCK_NUMBER.state + "s"])}
                {isClient && this.renderSelect("Статус завявки", "quoteStatus", this.state[this.QUOTE_STATUS.state + "s"])}
            </div>
        </Card.Header>
    }

    showOrHideButton(label, stateKey) {
        return <Button
            style={{height: "33px", marginBottom: "16px", marginRight: "5px"}}
            variant={this.state[stateKey] ? "primary" : "outline-primary"}
            size="sm"
            onClick={() => {
                this.loadDriverListAndDrawLastPlace({ [stateKey]: !this.state[stateKey] }, this.state.isActive, true);
                this.setState({[stateKey]: !this.state[stateKey]})
            }}
        >
            {label}
        </Button>
    }

    renderMap() {
        this.map?.container.fitToViewport();

        return <Card.Body style={{padding: 0, display: "flex"}}>
            <div id="yandex-map" style={{ width: `${this.state.route?.length > 0 ? "88%" : "100%"}`}}>
                <div id="yandex-map-load-spinner"
                     className="spinner-border spinner-border-lg">
                </div>
            </div>
            <div style={{ width: `${this.state.route?.length > 0 ? "16%" : "0"}`}}>
                {this.state.route?.length > 0 && this.renderCoordinatesTable()}
            </div>
        </Card.Body>
    }

    renderCoordinatesTable() {
        const clickOnTableData = g => {
            const coordinate = [g.latitude, g.longitude];
            const text = new Date(g.dt).toLocaleString() + "<br/>" + Util.smartJoin(", ", coordinate);

            this.map.panTo(coordinate)
                .then(() => this.map.balloon.open(coordinate, text));
        }

        this.map?.container.fitToViewport();

        return <div style={{height: "100%", overflowY: "auto"}}>
            <Table bordered hover size="sm" style={{fontSize: "smaller"}}>
                <tbody>
                    {this.state.route?.map(g =>
                        <tr>
                            <td onClick={() => clickOnTableData(g)}>
                                {new Date(g.dt).toLocaleString()}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    }

    renderRadiusInput() {
        return <input type="number"
                      placeholder="Радиус км."
                      style={{ width: "100px" }}
                      onChange={e => this.setState({ radius: e.target.value })}
                      onKeyUp={e => {
                          if (e.key === 'Enter') {
                              this.loadDriverListAndDrawLastPlace({ radius: e.target.value, searchByFio: " " }, false, false)
                          }
                      }}
                   />
    }

    render() {
        const isCarrier = securityService.isCarrier();
        const forwarderIsArttek = securityService.forwarderIsArttek(Forwarder.ARTTEK());
        return <div style={{display: "flex"}}>
            <Card style={{width: "75%", height: "81vh"}}>
                {this.renderTopSelectPanel()}
                {this.renderMap()}
            </Card>

            <Card style={{width: "25%", height: "81vh"}}>
                <Card.Header style={{padding: 0}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            {this.state.isDrivers && !isCarrier && forwarderIsArttek &&
                                <div>
                                    {this.showOrHideButton("СКАУТ", "isScoutGeolocationOn")}
                                    {this.showOrHideButton("Моб. приложение", "isPhoneGeolocationOn")}
                                </div>
                            }
                        </div>
                        {this.renderUpdateButton()}
                    </div>
                    {this.renderSearch()}
                </Card.Header>
                <Card.Body style={{padding: 0, overflowY: "auto"}}>
                    <div>{this.state.drivers && this.renderListPanel()}</div>
                </Card.Body>
            </Card>
        </div>
    }
}

export default MonitoringMap;