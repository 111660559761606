import BaseForm from "../../form/BaseForm";
import { Form, Row } from "react-bootstrap";
import Notification from "../../Notification";
import React from "react";
import {requiredValidator} from "../../../validators/simpleValidators";

class UpdateForm extends BaseForm {
    constructor(props) {
        super(props);
        this.configureValidators();
        this.state = {showModal: false, notifyMessage: ""}
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "from", "to");
    }

    showModal() {
        this.setState({showModal: true});
    };

    handleClose() {
        this.setState({showModal: false});
    };

    handleSubmit(e) {
        e.preventDefault();
        this.submit(async () => {
            const response = await this.props.updateFunction(this.props.model);
            const {data} = response;
            this.setSending(false)
            if (data) {
                this.showModal();
                this.setState({notifyMessage: `Обновлено ${data} заявок.`});
            }
        })
    }

    render() {
        return (
            <Form>
                <Notification showModal={this.state.showModal} onClose={e => this.handleClose(e)} message={this.state.notifyMessage}/>
                {this.props.children}
                {
                    this.props.renderSaveButton === true && <Row className={"ml-2"}>{this.renderSaveButton()}</Row>
                }
            </Form>
        );
    }
}

export default UpdateForm;