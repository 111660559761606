import {axios} from '../utils/api/axios';

class SettingsService {

	static BASE_PATH = "/api/control-panel/settings";
	
	async get(key) {
		const response = await axios.get(`${SettingsService.BASE_PATH}/setting/${key}`);
		return response.data;
	}

	async getAll() {
		const response = await axios.get(`${SettingsService.BASE_PATH}/all`);
		return response.data;
	}

	async save(settings) {
		const response = await axios.post(SettingsService.BASE_PATH, settings);
		return response.data;
	}
	
	async saveOne(key, value) {
		const response = await axios.post(`${SettingsService.BASE_PATH}/setting`, {key: key, value: value});
		return response.data;
	}

	async saveNew(key, value) {
		const response = await axios.post(`${SettingsService.BASE_PATH}/save`, {key: key, value: value});
		return response.data;
	}

}

export default new SettingsService();