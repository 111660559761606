import {axios} from '../utils/api/axios';

class AtiService {

    async getSummaryAndContacts(atiId) {
        const response = await axios.get(`/api/ati/${atiId}`);
        return {
            summary: JSON.parse(response.data.summary),
            contacts: JSON.parse(response.data.contacts),
            error: JSON.parse(response.data.error)
        };
    }
}

export default new AtiService();