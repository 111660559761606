import BaseForm from "../../form/BaseForm";
import FormGroup from "../../form/FormGroup";
import StoreWrapper from "../../form/StoreWrapper";
import {requiredValidator} from "../../../validators/simpleValidators";
import FormValidator from "../../../validators/FormValidator";
import dictionaryService from "../../../services/AbstractDictionaryService";
import carcassService from "../../../services/CarcassService";
import {setData} from "../../../actions/form";
import Loading from "../../Loading";
import {Col, Form, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ToastService} from "../../../services/ToastService/ToastService";

class Carcass extends StoreWrapper {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <CarcassInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class CarcassInner extends BaseForm {

    constructor(props) {
        super(props);
        this.configureValidators();
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "value");
        this.validator.addValidator("value", this.valueUniqueValidator.bind(this));
    }

    async valueUniqueValidator() {
        const value = this.props.model.value;
        if (!value) {
            return FormValidator.PROMISE_OK;
        }
        const isValid = await carcassService.isValueUnique({id: this.getId(), value: value});
        return isValid ? FormValidator.OK : "запись с таким названием уже зарегистрирована в системе";
    }

    getId() {
        return this.props.location.state?.id || new URLSearchParams(this.props.location.search).get('id');
    }

    load() {
        const id = this.getId();
        if (id) {
            carcassService.read(id).then(data =>{
                this.props.store.dispatch(setData(data, this.props.location.state?.action));
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(() =>
            carcassService.save(this.props.model).then(() => {
                this.props.history.push(this.props.pathname)
                this.afterSubmit();
            }));
    }
    afterSubmit() {
        let message = "Тип кузова сохранен"
        if(this.props.model.id === undefined || this.props.model.id === null) {
            message = "Тип кузова добавлен!"
            this.load();
        }
        ToastService.success({
            message: message
        });
    }

    getTabs() {
        const tabs = [];
        if (this.getId()) {
            tabs.push(this.getAuditTab(this.getId(), this.props.auditType));
        }
        return tabs;
    }
    getFormTabTitle() {
        const name = this.props.model.value || (this.props.model.id > 0 ? this.props.model.id : '');
        return this.props.tabTitle + " " + name;
    }

    renderForm() {
        const isReady = !this.getId() || this.props.model.id;
        if (!isReady) {
            return (<Loading/>);
        }
        return (
            <Form>
                <Row>
                    <Col lg={6} md={12}>
                        <FormGroup title="Наименование" name="value" store={this.props.store} required />
                    </Col>
                </Row>
                {this.renderSaveCancelButtons()}
            </Form>
        );
    }
}

const CarcassInnerConnected = withRouter(connect(mapStateToProps)(CarcassInner));

export default Carcass;