import BaseForm from "../../form/BaseForm";
import {Button, Col, Row, Table} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import React, {useState} from "react";
import { connect } from "react-redux";
import taskService from "../../../services/tasktracker/TaskService";
import {DOCUMENTS, securityService} from "../../../services/SecurityService";
import {TaskTopic, TaskType} from "../../../utils/consts/Const";

import abstractDictionaryService from "../../../services/AbstractDictionaryService";
import FileUploader from "../../FileUploader";
import moment from "moment/moment";
import Task from "./Task";
import TinyEditor from "../TinyEditor";
import Enums, {ENUM_NAME} from "../../../utils/consts/Enums";
import positionHistoryService from "../../../services/PositionHistoryService";
import optionsService from "../../../services/OptionsService";
import TextEditor from "../DraftEditor/TextEditor";
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        user: state.user
    };
}

class TaskForm extends BaseForm {
    constructor(props) {
        super(props);

        this.initDefaultValues();

        this.state = {
            isClosedQuoteInput: true,
            editorState: null
        };
    }

    fromLocationState(type) {
        return this.props.location.state?.data?.[type];
    }

    getTaskId() {
        return new URLSearchParams(this.props.location.search).get(Task.FIELD_ID)
            || this.fromLocationState(Task.FIELD_ID)
            || this.props.model.id;
    }

    isEpic() {
        return this.props.model.type?.id === TaskType.TYPE_EPIC().id;
    }

    initDefaultValues() {
        if (this.getTaskId()) {
            return;
        }
        this.initDefaultQuoteData();
        this.initDefaultOwner();
        this.initDefaultStartDate();
        this.onChange(Task.FIELD_TYPE, TaskType.TYPE_DEFAULT());
        this.onChange(Task.FIELD_STATUS, Enums.TASK_STATUS.NEW);
        this.props.initDefaultDepartmentAndDivision(this.fromLocationState(Task.FIELD_DEPARTMENT));
        this.initReporter();
    }

    initDefaultQuoteData() {
        if (this.fromLocationState(Task.FIELD_QUOTE_NUMBER)) {
            this.onChange(Task.FIELD_QUOTE_NUMBER, this.fromLocationState(Task.FIELD_QUOTE_NUMBER));
            this.onChange(Task.FIELD_ASSIGNEES, this.initParticipants(Task.FIELD_ASSIGNEES))
            this.onChange(Task.FIELD_COMMERCIAL, this.fromLocationState(Task.FIELD_COMMERCIAL))
            this.onChange(Task.FIELD_CLIENT, this.fromLocationState(Task.FIELD_CLIENT));
            this.onChange(Task.FIELD_CARRIER, this.fromLocationState(Task.FIELD_CARRIER));
        }
    }

    initReporter() {
        const departmentId = this.fromLocationState(Task.FIELD_DEPARTMENT)?.id || this.props.model.departemnt?.id;
        if (departmentId) {
            positionHistoryService.getCurrentHeadOfDepartment(departmentId)
                .then(user => {
                    const reporters = user ? [user] : [];
                    this.onChange(Task.FIELD_REPORTERS, reporters);
                    this.props.handleChangeReporters(reporters);
                }
                );

        }
    }

    initParticipants(field) {
        return this.fromLocationState(field) ? new Array(this.fromLocationState(field)) : null;
    }

    initDefaultOwner() {
        this.onChange(Task.FIELD_OWNER, { id: this.props.user.id, value: this.props.user.fio });
    }

    initDefaultStartDate() {
        this.onChange(Task.FIELD_START_DATE, new Date());
    }

    setDueDate(topic) {
        abstractDictionaryService.read(topic.id, "topic").then(response => {
            const days = response.daysToComplete;
            const startDate = this.props.model.startDate || moment();
            this.onChange(Task.FIELD_DUE_DATE, moment(startDate).add(days, "days").format('YYYY-MM-DDTHH:mm:ss'));
        });
    }

    handleChangeTopic(topic) {
        if (topic.id === TaskTopic.OTHER().id) {
            this.onChange(Task.FIELD_TITLE, '');
        }

        if (topic.id === TaskTopic.TTN_ON_RECOVERY().id || topic.id === TaskTopic.CARRIER_INCOMING_DOC().id) {
            const user = securityService.getUser();
            const value = [{ id: user.id, value: user.fio }];
            this.props.handleChangeReporters(value);
            this.onChange(Task.FIELD_REPORTERS, value);
        }

        this.setDueDate(topic);
    }

    handleEditDescription = (content, editor) => {
        this.onChange(Task.FIELD_DESCRIPTION, content);
    };

    handleVerified(it) {
        const verifications = this.props.model.verifications || [];
        const index = verifications.indexOf(it);

        const newVerifications = [...verifications];
        newVerifications[index].verified = !newVerifications[index].verified;

        this.onChange("verifications", newVerifications);
        taskService.verifyTask(this.getTaskId(), it);
    }

    renderVerificationTable() {
        const verifications = this.props.model.verifications || [];
        return (
            <Table bordered size="sm" className="selectable">
                <thead>
                    <tr>
                        <th className="col-1">Проверяющий</th>
                        <th className="col-1">Проверил</th>
                        {this.props.isReporter && this.props.model.status.value === Enums.TASK_STATUS.REVIEW.value &&
                            <th className="col-1"></th>}
                    </tr>
                </thead>
                <tbody>
                    {verifications.sort((v1, v2) => v1.reporter.id - v2.reporter.id)
                        .map(it =>
                            <tr className={it.verified ? "verification-table-checked" : "verification-table-unchecked"}>
                                <td>{it.reporter.value}</td>
                                <td>{it.verified ? "Да" : "Нет"}</td>
                                {this.props.isReporter && this.props.model?.status.value === Enums.TASK_STATUS.REVIEW.value
                                    && <td className={"verification-table-default"}>{this.renderVerifyButton(it)}</td>}
                            </tr>)
                    }
                </tbody>
            </Table>
        );
    }

    renderManageCol() {
        const { isAssignee, isReporter, renderMeButton} = this.props;
        const renderMeAssigneeButton = !isAssignee ? renderMeButton.bind(this, true) : null;
        const renderMeReporterButton = !isReporter ? renderMeButton.bind(this, false) : null;
        return (
            <Col lg={3}>
                <FormGroup title="Создал"
                           name="owner"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={!securityService.isAdmin(this.props.user)}
                           required />

                <FormGroup title="Статус"
                           name="status"
                           type="enum"
                           optionsType={ENUM_NAME.TASK_STATUS}
                           store={this.props.store}
                           readOnly={this.isEpic() || !this.props.isSuperUserOrParticipant || !this.getTaskId()}
                           required />

                <FormGroup title="Ответственные"
                           name="assignees"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={!this.props.isSuperUserOrOwner}
                           multiSelect={true}
                           onChangeHandler={values => this.props.setDepartmentByUserId(values)}
                           renderCustomButton={renderMeAssigneeButton}
                           activeOnly={true}
                           required />

                <FormGroup title="Проверяющие"
                           name="reporters"
                           type="dictionary"
                           optionsType="CRM_USER"
                           store={this.props.store}
                           readOnly={!this.props.isSuperUserOrOwner}
                           multiSelect={true}
                           onChangeHandler={values => this.props.handleChangeReporters(values)}
                           renderCustomButton={renderMeReporterButton}
                           activeOnly={true}
                           required />

                <FormGroup title="Тема"
                           name="topic"
                           type="dictionary"
                           optionsType="TOPIC"
                           store={this.props.store}
                           onChangeHandler={(value) => {this.handleChangeTopic(value)}}
                           readOnly={!this.props.isSuperUserOrOwner}
                           required />

                {this.props.model.topic?.id === TaskTopic.OTHER().id &&
                    <FormGroup title="Название задачи"
                               name="title"
                               type="text"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                               required />
                }
            </Col>
        );
    }

    renderInfoCol() {
        return (
            <Col lg={3}>
                <Row>
                    <FormGroup title="Номер"
                               type="number"
                               name="id"
                               store={this.props.store}
                               readOnly={true} />

                    <FormGroup title="Дата"
                               type="datetime"
                               name="createdAt"
                               store={this.props.store}
                               readOnly={true} />
                </Row>
                <Row>
                    <FormGroup title="Тип"
                               name="type"
                               type="dictionary"
                               optionsType="TASK_TYPE"
                               filteredOptions={TaskType.getActiveTypes()}
                               store={this.props.store}
                               readOnly={true}
                               required />
                </Row>
            </Col>
        );
    }

    renderTasksRow() {
        return (
            <Row>
                <Col>
                    <FormGroup title="Родительская задача"
                               name="parentTask"
                               type="task"
                               taskType="parent"
                               store={this.props.store}
                               id={this.props.model.parentTask?.id}
                               onChangeHandler={() => {this.onChange('previousTask', null)}}
                               showViewIcon={true}
                               showClearIcon={this.props.isSuperUserOrOwner}
                               readOnly={!this.props.isSuperUserOrOwner || this.isEpic()}
                    />
                </Col>
                <Col>
                    <FormGroup title="Выполнить после"
                               name="previousTask"
                               type="task"
                               taskType="previous"
                               store={this.props.store}
                               id={this.props.model.previousTask?.id}
                               parentTaskId={this.props.model.parentTask?.id}
                               currentTaskId={this.props.model?.id}
                               showViewIcon={true}
                               showClearIcon={this.props.isSuperUserOrOwner}
                               readOnly={this.isEpic() || !this.props.isSuperUserOrParticipant}
                    />
                </Col>
            </Row>
        );
    }

    renderPositionCol() {
        return(
            <Col>
                {this.renderTasksRow()}
                <Row>
                    <Col>
                        <FormGroup title="Подразделение"
                                   name="department"
                                   type="dictionary"
                                   optionsType="DEPARTMENT"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                                   activeOnly={true}
                                   required
                        />
                    </Col>
                    <Col>
                        <FormGroup title="Отдел"
                                   name="division"
                                   type="dictionary"
                                   optionsType="DIVISION"
                                   store={this.props.store}
                                   readOnly={!this.props.isSuperUserOrOwner}
                                   activeOnly={true}
                                   required
                        />
                    </Col>
                </Row>
                {this.renderDates()}
                {this.renderQuoteRow()}
                {this.props.renderSubtasks()}
            </Col>
        );
    }

    renderDates() {
        return (
            <Row>
                <Col>
                    <FormGroup title="Дата начала выполнения"
                               name="startDate"
                               type="datetime"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                               required
                    />
                </Col>
                <Col>
                    <FormGroup title="Дата конца выполнения"
                               name="dueDate"
                               type="datetime"
                               store={this.props.store}
                               readOnly={!this.props.isSuperUserOrOwner}
                               required
                    />
                </Col>
            </Row>
        );
    }

    renderQuoteRow() {
        return this.props.model.quoteNumber &&
            <Row className={"mt-3"}>
                <Col>
                    <FormGroup title="Заказчик"
                               name="client"
                               type="dictionary"
                               optionsType="CONTRACTOR_CLIENT"
                               store={this.props.store}
                               readOnly={true}
                    />
                </Col>
                <Col>
                    <FormGroup title="Перевозчик"
                               name="carrier"
                               type="dictionary"
                               optionsType="CONTRACTOR_CARRIER"
                               store={this.props.store}
                               readOnly={true}
                    />
                </Col>
                <Col>
                    <FormGroup title="Менеджер КО"
                               name="commercialDepManager"
                               type="user"
                               role="COMMERCIAL"
                               store={this.props.store}
                               readOnly={true}
                    />
                </Col>
            </Row>
    }

    renderVerifyButton(it) {
        if (it.reporter.id === this.props.user.id) {
            return (
                this.getTaskId() &&
                <Button size="sm" variant={it.verified ? "danger": "success"} className="pull-right" style={{width: "100%"}}
                        disabled={this.state.sending}
                        onClick={() => this.handleVerified(it)}>
                    <i className={it.verified ?  "fas fa-times": "fas fa-fw fa-check"}/>
                    &nbsp;
                    <span>{it.verified ? "Не проверено": "Проверено"}</span>
                </Button>
            );
        }
    }

    render() {
        return (
            <div>
                <Row>
                    {this.renderManageCol()}
                    {this.renderInfoCol()}
                    {this.renderPositionCol()}
                </Row>
                <Row>
                    <Col lg={6} className={"ml-1"}>
                        <TextEditor value={this.props.model.description} onEditorStateChange={this.props.onEditorStateChange} readOnly={!this.props.isSuperUserOrOwner}/>
                    </Col>
                    <Col>
                        {this.renderVerificationTable()}
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col lg={6} className="task-file-uploader">
                        <FileUploader
                            store={this.props.store}
                            name="attachments"
                            title="Вложения"
                            hideRemove={!this.props.isSuperUserOrOwner}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(mapStateToProps)(TaskForm);