import {axios} from '../utils/api/axios';

class AuthService {
    async login(username, password) {
        const response = await axios.post("/auth/signin", {username, password});
        return response.data;
    }

    async register(form) {
        const response = await axios.post("/auth/register", form);
        return response.data;
    }

    async confirmCode(form) {
        const response = await axios.post("/auth/confirm", form);
        return response.data;
    }

    async smsRequest(form) {
        const response = await axios.post("/auth/sms-request", form);
        return response.data;
    }

    logout() {
        localStorage.removeItem("user");
    }
}

export default new AuthService();
