export function extendGlobals() {
    // @ts-ignore
    Number.prototype.pad = function (size) {
        let s = String(this);
        while (s.length < (size || 2)) {
            s = "0" + s;
        }
        return s;
    }
    // @ts-ignore
    Array.prototype.last = function () {
        return this.length ? this[this.length - 1] : null;
    };
    // @ts-ignore
    Array.prototype.first = function () {
        return this.length ? this[0] : null;
    };
    // @ts-ignore
    Array.prototype.removeAt = function (position) {
        this.splice(position, 1);
    };
}
