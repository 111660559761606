import DataTable from "../../table/DataTable";
import React from "react";
import TableCell from "../../table/TableCell";
import eventService from "../../../services/tasktracker/EventService";
import {ENUM_NAME, EVENT_SOURCES, EVENT_TYPES, TASK} from "../../../utils/consts/Enums";

class EventTable extends React.PureComponent {

    constructor(props) {
        super(props);
        this.columns = this.columns.bind(this);
    }

    fetch(params) {
        params.sourceId = params.sourceId || this.props.sourceId;
        return eventService.list(params);
    }

    formatUsers(options) {
        const users = options || [];
        return TableCell.arrayFormatter(users.length > 0 ? users.map(it => it?.value) : users);
    }

    openEditForm(row) {
        if (row.source === TASK) {
            window.open(`/task-tracker/task?id=${row.sourceId}`, "_blank");
        }
    }

    columns() {
        return [
            { title: "#", field: "id", filter: DataTable.STRING_FILTER, className: "id overflow", sortable: true},
            { title: "Дата", field: "createdAt", filter: DataTable.DATE_RANGE_FILTER, formatter: TableCell.dtFormatter, sortable: true },
            { title: "Источник", field: "source", filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.EVENT_SOURCE, selectType: "dictionary",
                formatter: TableCell.enumFormatter(EVENT_SOURCES), sortable: true },
            { title: "№", field: "sourceId", filter: DataTable.STRING_FILTER, className: "id overflow", sortable: true },
            { title: "Тип", field: "type", filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.EVENT_TYPE, selectType: "dictionary",
                formatter: TableCell.enumFormatter(EVENT_TYPES), sortable: true },
            { title: "Пользователь", field: "crmUser", filter: DataTable.DICTIONARY_FILTER, optionsType: "CRM_USER", activeOnly: false, sortable: false },
            { title: "Ответственные", field: "assignees", filter: DataTable.DICTIONARY_FILTER,
                formatter: value => this.formatUsers(value), optionsType: "CRM_USER", activeOnly: false, multiSelect: true, sortable: false },
            { title: "Проверяющие", field: "reporters", filter: DataTable.DICTIONARY_FILTER,
                formatter: value => this.formatUsers(value), optionsType: "CRM_USER", activeOnly: false, multiSelect: true, sortable: false }
        ];
    }

    render() {
        return (
          <DataTable {...this.props}
              title = {"События"}
              fetch = {this.fetch.bind(this)}
              openEditForm = {this.openEditForm.bind(this)}
              resizable = {true}
              columns = {this.columns().filter(col => { return Object.keys(col).length !== 0 })}
              tableClassName = "no-line-break" />
        );
    }
}

export default EventTable;