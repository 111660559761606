import {PureComponent} from "react";
import PinIcon from "../../../icon/PinIcon";

class PinButton extends PureComponent {

    render() {
        return (
            <button onClick={(e) => this.props.handleClick(e)} style={{border: "none", backgroundColor: "transparent"}}>
                <PinIcon/>
            </button>
        );
    }
}

export default PinButton;
