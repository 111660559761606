import {HttpService} from "./HttpService/HttpService";

class QuoteService {
	async byId(id) {
		return await HttpService.get("/api/quotes/" + id);
	}

	async list(params) {
		return await HttpService.post("/api/quotes/list", params);
	}
	
	async setMonitoringModeInLocalStorage() {
		const response = await HttpService.get("/api/control-panel/settings/setting/monitoringMode");
		localStorage.setItem("monitoringMode", JSON.stringify(response.data));
	}
	
	async save(quote, baseOn) {
		const request = {url: "/api/quotes?baseOn=" + baseOn, data: quote};
		let response;
		if (quote.id && quote.id > 0) {
			response = HttpService.put(request.url, request.data)
		} else {
			response = HttpService.post(request.url, request.data)
		}
		return response;
	}

	async change(partialData) {
		return await HttpService.put("/api/quotes/change", partialData);
	}

	async resetFieldsInActiveEntity(quote) {
		return await HttpService.post("/api/quotes/reset-fields", quote);
	}

	async calculateMarginProfitability(quote) {
		const request = {
			carrierVatMode: quote.carrierVatMode,
			contractorVatMode: quote.contractorVatMode,
			carrierPrice: quote.carrierPrice,
			contractorPrice: quote.contractorPrice,
			carrierGsm: quote.carrierGsm,
			gsmStrait: quote.gsmStrait,
			gsmDiscount: quote.gsmDiscount,
			carrierExpenses: quote.carrierExpenses,
			contractorExpenses: quote.contractorExpenses,
			clientContractId: quote.clientContract?.id
		}
		return HttpService.post("/api/quotes/margin-profitability", request);
	}

	async findLastQuoteByDriver(driver) {
		return HttpService.post("api/quotes/last/by-driver", driver);
	}

	async updateDepartmentByUsers(params) {
		return await HttpService.post("api/quotes/update/department", params);
	}

	async updateCommercial(params) {
		return await HttpService.post("api/quotes/update/commercial", params);
	}

	async verificationBeforePrintClientBill(quoteId) {
		return await HttpService.get(`/api/quotes/client-bill-verify/${quoteId}`);
	}

	async checkCarrierContractExistence(quoteId) {
		return await HttpService.get(`/api/quotes/carrier-contract-existence/${quoteId}`);
	}
}

export default new QuoteService();
