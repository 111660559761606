import axios, {
    AxiosError,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios';
import {localStorageService} from "../../helpers/localStorageService/localStorageService";
import {ApiErrorHandler} from "../../helpers/apiErrorHandler/apiErrorHandler";
import {env} from "../../env"

const $api = axios.create({
    baseURL: env.REACT_APP_API_URL ?? "http://localhost:8080"
});

$api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        const accessToken = localStorageService.getAccessToken();

        if (accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            };
        }

        return config;
    },
    (error: AxiosError) => {
        console.error("Request Error: ", error);
        return Promise.reject(error);
    }
);

$api.interceptors.response.use(
    (res: AxiosResponse) => {
        return res;
    },
    (error: AxiosError) => {
        return ApiErrorHandler.handleHttpError(error);
    }
);

export {$api as axios};

export const createCancelToken = () => axios.CancelToken.source()