import React, {Fragment, useRef} from "react";
import {createStore} from 'redux';
import { connect } from 'react-redux';

import {Form, Button, Card, Col, Row, Table, Dropdown, DropdownButton, Tab, Tabs, Container} from "react-bootstrap";

import localStorageService from "../../services/LocalStorageService";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ColumnsSelector from "./ColumnsSelector";
import Pagination from './pagination';
import ThSortable from "./ThSortable";
import reducer from './reducer';
import Filter from './Filter';
import ExportImport from "../control-panel/export-import/ExportImport";

import {
	DATA_TABLE_CLEAR_FILTERS,
	DATA_TABLE_HIDE_COLUMNS,
	DATA_TABLE_SORT_COLUMNS,
	DATA_TABLE_PAGE,
	DATA_TABLE_SEARCH,
	DATA_TABLE_FILTER
} from "../../actions/types";

import './DataTable.css';
import clsx from "clsx";
import ColumnResizer from "column-resizer";
import ReactDOM from "react-dom";
import Util from "../../utils/util/Util";

import AlertCenter from "../AlertCenter";
import PageSizeMenu from "./PageSizeMenu";
import {RequestStatus} from "../../utils/consts/Const";
import LocalStorageService from "../../services/LocalStorageService";

class DataTable extends React.PureComponent {
	static get STRING_FILTER() { return 'string'; }
	static get DICTIONARY_FILTER() { return 'dictionary'; }
	static get NUMERIC_RANGE_FILTER() { return 'numeric_range'; }
	static get DATE_RANGE_FILTER() { return 'date_range'; }
	static get INSTANT_DATE_RANGE_FILTER() { return 'instant_date_range'; }
	static get BOOLEAN_FILTER() { return 'boolean'; }
	static get ROLES_FILTER() { return 'roles'; }
	static get ENUM_FILTER() { return 'enum'; }
	static get USER_FILTER() { return 'user'; }
	static get DEPARTMENTS_FILTER() { return 'departments'; }
	static get ADDRESS_FILTER() { return 'address'; }

	static sortColumns(title, columns) {
		if (!columns) {
			return columns;
		}
		const sorted = localStorageService.extract(title, 'sortOrder');
		if (!sorted) {
			return columns;
		}
		const mapByField = columns.reduce((map, column) => {
			map[column.field] = column;
			return map;
		}, {});

		return sorted.map(it => mapByField[it]);
	}

	static mapStateToProps(state, ownProps) {
		const storedPropProvider = (prop, defaultProvider) => localStorageService.extract(ownProps.title,
					`${LocalStorageService.extract(ownProps.title, 'activeFilterTab')}_${prop}`)
				|| state[prop]
				|| localStorageService.extract(ownProps.title, prop, LocalStorageService.extract(ownProps.title, 'activeFilterTab') || ownProps[prop])
				|| (defaultProvider ? defaultProvider() : null),
			hiddenColumnsProvider = () =>
				ownProps.columns?.filter(col => col.hidden).map(col => col.key || col.field);
		return {
			sort: storedPropProvider('sort'),
			search: storedPropProvider('search', () => ''),
			filter: storedPropProvider('filter'),
			page: storedPropProvider('page', () => 0),
			showFilter: !!state.filterConfig,
			hiddenColumns: storedPropProvider('hiddenColumns', hiddenColumnsProvider),
			columns: DataTable.sortColumns(ownProps.title, ownProps.columns)
		};
	}

	render() {
		const initialState = () => {
			const {title, storageTitle, filter, sort, page, search} = this.props;
			return {
				title: storageTitle || title,
				filter, sort, page, search
			};
		};
		return <DataTableWithStoreProps {...this.props} store={createStore(reducer(initialState()))}/>
	}
}

const ItemType = 'COLUMN';

const DragDropHeader = (props) => {
	const ref = useRef(null);
	const {id, index, moveColumn, col} = props

	const [, drop] = useDrop({
		accept: ItemType,
		hover: (draggedItem) => {
			if (draggedItem.index !== index) {
				moveColumn(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	const [, drag] = useDrag({
		type: ItemType,
		item: {id, index},
	});

	drag(drop(ref));

	if (props.tableHeaderRenderer) {
		return props.tableHeaderRenderer.apply(this);
	}

	const key = col.key || col.field;

	return (
		props.isColumnHidden(key) ? null :

			<th ref={ref} className={col.className ? col.className + ` ${col.field}` : col.field}>
				<ThSortable store={props.store} key={key}
							sort={props.sort}
							ref={ref}
							field={key} sortable={col.sortable}
							filterType={col.filter}
							optionsType={col.optionsType}
							selectType={col.selectType}
							multiSelect={col.multiSelect}
							filteredOptions={col.filteredOptions}
							role={col.role}
							activeOnly={col.activeOnly}
							filter={props.filter}
							title={col.title}/>
			</th>
	);
};

class DataTableWithStore extends React.PureComponent {
	constructor(props) {
		super(props);
		this.menuRef = React.createRef();
		this.state = {
			loading: false,
			data: [],
			pageSize: localStorage.getItem("pageSize") || 10,
			pages: 0,
			resizer: undefined,
			selectedRowId: null,
			showPageSizeMenu: false,
			prevParams: {},
			isDownloadXls: false,
			prevFilterTab: null,
			columns: []
		};

		this.features = {canChooseColumns: true, ...props.features};

		this.onChangeHiddenColumns = this.onChangeHiddenColumns.bind(this);

		this.onSearch = this.onSearch.bind(this);
		this.onPageChanged = this.onPageChanged.bind(this);
		this.exportToExcel = this.exportToExcel.bind(this);
		this.onChangeFilterTabs = this.onChangeFilterTabs.bind(this);
		this.getFilterTabsValue = this.getFilterTabsValue.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.setDataInDT = this.setDataInDT.bind(this);
		this.getFilterTabsKey = this.getFilterTabsKey.bind(this);
		this.isFilterDataInHistory = this.isFilterDataInHistory.bind(this);
		this.onClickMenu = this.onClickMenu.bind(this);
		this.moveColumn = this.moveColumn.bind(this);
		this.getSortedColumns = this.getSortedColumns.bind(this);
		this.setSortedColumns = this.setSortedColumns.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleOnResize = this.handleOnResize.bind(this);
		this.visibleColumnsSumWidth = this.visibleColumnsSumWidth.bind(this);
		this.setColumnsSizes = this.setColumnsSizes.bind(this);
		this.onChangeXlsExportInterval = this.onChangeXlsExportInterval.bind(this);
		this.onChangeXlsExportStatuses = this.onChangeXlsExportStatuses.bind(this);

		this.dataTableCard = React.createRef();
		this.pageSizeMenu = React.createRef();
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
		this.props.contextMenuObj && document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (this.pageSizeMenu.current && !this.pageSizeMenu.current.contains(event.target)) {
			event.type === "click" && this.setState({showPageSizeMenu: false});
		}
		if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
			event.type === "mousedown" && this.setState({ menuVisible: false });
		}
	}

	moveColumn(fromIndex, toIndex) {
		const { columns } = this.state;
		const updatedColumns = [...columns];
		const [movedColumn] = updatedColumns.splice(fromIndex, 1);
		updatedColumns.splice(toIndex, 0, movedColumn);
		this.setState({ columns: updatedColumns });
		LocalStorageService.updateObject(this.props.title, 'columnsOrder', updatedColumns.map(col => col.title));
	}

	isColumnHidden(key) {
		return this.props.hiddenColumns && this.props.hiddenColumns.indexOf(key) >= 0;
	}

	setLoading(loading) {
		this.setState({loading: loading});
	}

	fetch(params) {
		return this.props.fetch ? this.props.fetch(params) : Promise.resolve({data: [], pages: 0});
	}

	fetchXLS(params) {
		return this.props.fetchXLS
			? this.props.fetchXLS(this.props.fetchXLSWithCurrentData ? this.state.data : params)
			: Promise.resolve({data: [], pages: 0});
	}

	onChangeXlsExportInterval(value) {
		this.setState({xlsExportInterval: value})
	}

	onChangeXlsExportStatuses(value) {
		this.setState({xlsExportStatuses: value})
	}

	async exportToExcel() {
		await this.setState({isDownloadXls: true})
		this.fetchXLS({
			search: this.props.search,
			createdAt: this.state.xlsExportInterval,
			status: this.state.xlsExportStatuses || this.props.getQuoteStatuses(),
			page: 0,
			pageSize: 2147483647 // Integer.MAX_VALUE
		}).then(res => this.setState({isDownloadXls: false}));
	}

	isFilterDataInHistory() {
		const activeFilterTab = this.getFilterTabsKey()
		return this.state.data.findIndex(dat => dat?.filter === activeFilterTab)
	}

	setDataInDT(data) {
		let newData
		if (this.props.filterTabs) {
			const activeFilterTab = this.getFilterTabsKey()
			const dataFilterIndex = this.isFilterDataInHistory()
			const pages = this.state.pages
			newData = dataFilterIndex === -1
				? [...this.state.data.filter(row => row.filter), {filter: activeFilterTab, data: [...data], pages}]
				: [...this.state.data]
			dataFilterIndex !== -1 && (newData[dataFilterIndex].data = [...data])
		} else {
			newData = [...data]
		}
		this.setState({data: newData})
	}

	async updateIsFiltered() {
		const filter = await this.getFilter();
		const isFiltered = Object.keys(filter).length > 0;
		this.setState({isFiltered});
	}


	async load(showLoading, forseUpdate) {
		showLoading && this.setLoading(true);
		const params = {
			search: this.props.search,
			page: this.props.page,
			pageSize: this.state.pageSize,
			sort: this.props.sort,
			...await this.getFilter()
		}
		this.props.filterTabs
		&& (params[this.props.defaultFilterParam] = this.getFilterTabsValue())
		const isEqualParams = forseUpdate
			|| (JSON.stringify(this.state.prevParams) !== JSON.stringify(params)
				&& (this.props.filterTabs ? this.state.activeFilterTab === this.getFilterTabsKey() : true));

		(isEqualParams || !this.props.filterTabs) && this.fetch(params)
			.then(response => {
				this.setDataInDT(response.data)
				this.updateIsFiltered()
				this.setState({
					pages: response.pages,
					loading: false
				});
				this.updateIsFiltered()
				this.setDataInDT(response.data)
			});
		this.props.filterTabs && this.setState({activeFilterTab: this.getFilterTabsKey()})
		!isEqualParams && this.setLoading(false)
		this.setState({prevParams: params})
	}

	handleContextMenu = (event, row) => {
		event.preventDefault();
		this.setState({
			menuVisible: true,
			menuPosition: {x: event.pageX - 240, y: event.pageY - 95},
			menuActiveRow: row
		});
	};

	renderTableRow(row, index) {
		const alertClassName = this.props.features?.showAlertCenter !== false && this.props.dataTableAlerts?.find(a => a.id === row.id) ? "alert-color" : "";
		const selectClassName = this.state.selectedRowId === row.id ? "select-row" : "";
		const rowClassName = selectClassName + " " + alertClassName;
		return (
			<tr onContextMenu={this.props.contextMenuObj && (event => this.handleContextMenu(event, row))} key={index}
				onClick={() => this.setState({selectedRowId: row.id})}
				onDoubleClick={() => this.props.openEditForm && this.props.openEditForm(row)}
				className={rowClassName}>
				{this.state.columns.map(col => {
					const key = col.key || col.field,
						className = "function" === typeof col.className ? col.className(row) : col.className;
					return key && this.isColumnHidden(key) ? null : (
						<td key={key + '-' + index} className={className}>
							{this.renderTableCell(row, col)}
						</td>
					);
				})}
			</tr>
		);
	}

	renderTableCell(row, col) {
		const value = row[col.field];
		if (typeof value === "object" && !Array.isArray(value)) {
			return this.renderObject(col, row);
		}
		return this.renderPrimitive(col, row);
	}
	
	renderPrimitive(col, row, value) {
		const valueSafe = value ?? row[col.field];
		return col.formatter ? col.formatter(valueSafe, row) : valueSafe;
	}
	
	renderObject(col, row) {
		const value = row[col.field],
			isDictionary = value && (value.id === 0 || value.id) && value.value;
		if (isDictionary || isDictionary === "") {
			return this.renderPrimitive(col, row, value.value);
		}
		return this.renderPrimitive(col, row);
	}

	clearFilters() {
		this.props.dispatch({type: DATA_TABLE_CLEAR_FILTERS});
	}
	
	onSearch(e) {
		this.props.dispatch({type: DATA_TABLE_SEARCH, payload: e.target.value});
	}
	
	onPageChanged(page) {
		this.props.dispatch({type: DATA_TABLE_PAGE, payload: page});
	}

	async notify(type) {
		if (type === DATA_TABLE_HIDE_COLUMNS) {
			await this.onChangeHiddenColumns();
		} else if (type === DATA_TABLE_SORT_COLUMNS) {
			this.props.dispatch({type: DATA_TABLE_SORT_COLUMNS });

		}
	}
	async onChangeHiddenColumns() {
		const hiddenColumns = localStorageService.extract(this.props.title, 'hiddenColumns', null);
		await this.props.dispatch({type: DATA_TABLE_HIDE_COLUMNS, payload: hiddenColumns });
		this.setSortedColumns();
	}

	async getFilter() {
		if(!this.props.filter) {
			return {};
		}

		return Object.keys(this.props.filter)
			.filter(key => !!this.props.filter[key])
			.reduce((obj, key) => {
				const filterValues = this.props.filter[key];
				return {...obj, ...filterValues};
			}, {});
	}

	onSortColumns (item, beforeItem) {
		const stored = localStorageService.extract(this.props.title, 'sortOrder'),
			fields = stored && stored.length ? stored : this.state.columns.map(it => it.field),
			oldIndex = fields.indexOf(item),
			newIndex = fields.indexOf(beforeItem);
		Util.moveItemInArray(fields, oldIndex, newIndex);
		localStorageService.updateObject(this.props.title, 'sortOrder', fields);
	}

	getFilterTabsKey() {
		if(this.props.filterTabs) {
			return LocalStorageService.extract(this.props.title, 'activeFilterTab') || this.props?.filterTabs[0]?.key
		} return null
	}

	getFilterTabsValue() {
		const key = this.getFilterTabsKey()
		return this.props.filterTabs?.find(value => value.key === key)?.value
	}



	async onChangeFilterTabs(key) {
		LocalStorageService.updateObject(this.props.title, 'activeFilterTab', key)
		this.setState({activeFilterTab: key})
		await this.setFilter().then(res => {
			this.props.getColumns()
			if(this.isFilterDataInHistory() === -1) {
				this.load(true, true)
			} else {
				const pages = this.state.data.find(dat => dat.filter === key).pages
				this.setState({pages})
			}
			this.setSortedColumns()
		})
	}


	async setFilter() {
		const filterTab = LocalStorageService.extract(this.props.title, 'activeFilterTab')
		const newFilter = LocalStorageService.extract(this.props.title, `${filterTab}_filter`)
		newFilter && await this.props.dispatch({ type: DATA_TABLE_FILTER, payload: newFilter });
		return newFilter || {}
	}

	renderFilterTabs() {
		return <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
			<Button style={{ height: "33px" }} variant="outline-primary"
					size="sm" onClick={() => this.load(true, true)}>
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
					 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
					<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
				</svg>
			</Button>
			<Tabs
				defaultActiveKey={LocalStorageService.extract(this.props.title, 'activeFilterTab') || this.props.filterTabs[0]?.key}
				activeKey={this.state.activeFilterTab}
				className="m-0 text-left"
				style={{fontSize: "14px", fontWeight: "500"}}
				onSelect={key => this.onChangeFilterTabs(key)}>
				{this.props.filterTabs.map(tab => <Tab disabled={this.state.loading} eventKey={tab.key} title={tab.title} />)}
			</Tabs>
		</div>
	}

	renderHeader() {
		const {isFiltered} = this.state
		return (
			<Container style={{margin: 0, maxWidth: "100%"}}>
				{this.props.filterTabs && <Row className="title" style={{display: "flex", justifyContent: "center"}}>{this.props.title}</Row>}
				<Row>
					{!this.props.filterTabs && <Col className="title text-center">{this.props.title}</Col>}
					{this.props.dataTableNotifications?.length > 0 && this.props.features?.showAlertCenter !== false &&
						<Col className="col-auto text-right">
							<AlertCenter alerts={this.props.dataTableNotifications} onClick={this.props.alertClickHandler} item={this.props.alertItemTitle}/>
						</Col>
					}
					{this.props.filterTabs && this.renderFilterTabs()}
					<Col className={`${!this.props.filterTabs && "col-auto"} text-right`}>
						{isFiltered &&
							<Button variant="link" size="sm" className="link" onClick={this.clearFilters.bind(this)}>очистить
								фильтры</Button>}
						{this.features.search !== false &&
							<Form.Control size="sm" type="text" name="search" autocomplete="off" className="search"
										  placeholder="поиск..."
										  onChange={this.onSearch} value={this.props.search}/>}
						{this.features.canChooseColumns && <ColumnsSelector title={this.props.title}
																			onSort={this.onSortColumns.bind(this)}
																			columns={this.props.columns}
																			notify={this.notify.bind(this)}/>}
						{this.features.xls &&
							<ExportImport
								noInitialPolling={true}
								isDownloadXls={this.state.isDownloadXls}
								xlsExportStatuses={this.state.xlsExportStatuses}
								onChangeXlsExportStatuses={e => this.onChangeXlsExportStatuses(e)}
								getQuoteStatuses={this.props.getQuoteStatuses}
								onChangeXlsExportInterval={e => this.onChangeXlsExportInterval(e)}
								type={ExportImport.DATATABLE_XLS_EXPORT} handlerExport={this.exportToExcel}
							/>}
						{this.features.canAdd !== false && this.props.openAddForm &&
							<Button variant="outline-primary" size="sm"
									onClick={this.props.openAddForm}>Добавить</Button>}
					</Col>
				</Row>
			</Container>
		);
	}
	
	renderTableBody() {
		if (this.props.tableBodyRenderer) {
			return this.props.tableBodyRenderer();
		}
		if (this.features.checkMinimalSearch && !this.hasMinimalSearch()) {
			return this.renderMinimalSearch();
		}
		let index = 0;
		return (
			this.state.data.map(row => !row.filter
				? (row.id && this.renderTableRow(row, index++))
				: (row.filter === this.getFilterTabsKey() && row.data.map(tabFilterRow => this.renderTableRow(tabFilterRow, index++))))

		);
	}
	
	hasMinimalSearch() {
		return this.props.search?.length > 3;
	}
	
	renderMinimalSearch() {	
		return (
			<tr>
				<td colSpan="99" className="text-center">введите более 3 символов в строку поиска</td>
			</tr>
		);
	}

	disableEvent(e) {
		e.preventDefault()
		return false;
	}

	onClickMenu(action) {
		this.setState({ menuVisible: false })
		action(this.state.menuActiveRow)
	}

	render() {
		const pageSizeElement = this.pageSizeMenu.current?.getBoundingClientRect();
		const { menuVisible, menuPosition } = this.state;
		const tableStyle = this.state.sumWidth ? {
			minWidth: this.state.sumWidth
		} : {}

		return (
			<DndProvider backend={HTML5Backend}>
				<Card className={clsx("data-table", this.props.className)} onCopy={this.disableEvent} onCut={this.disableEvent}>
					{menuVisible && (
						<ul
							ref={this.menuRef}
							className="context-menu"
							style={{ top: menuPosition.y, left: menuPosition.x, position: 'absolute', listStyle: 'none',
								backgroundColor: '#eeeeee', padding: "0", border: "1px solid #dee2e6", borderRadius: "0 5px 5px 5px", margin: 0, display: "flex", flexDirection: "column" }}
						>
							{this.props.contextMenuObj.map(obj =>
								(obj && obj.isVisible(this.state.menuActiveRow)) && <a style={{fontSize: "11px", padding: "2px 5px", cursor: "pointer", borderBottom: "1px solid #dee2e6"}} className="context-menu-item" onClick={() => this.onClickMenu(obj.action)}>{obj.title}</a>
							)}
						</ul>
					)}
					<Card.Header>{this.renderHeader()}</Card.Header>
					<Card.Body ref={this.dataTableCard} style={{overflow: "auto", maxHeight: this.props.style?.maxHeight}}>
						{this.props.showFilter && <Filter store={this.props.store}/>}
						<Table key={this.props.key || this.props.title} striped bordered hover size="sm" ref={this.dataTable} style={tableStyle} id="data-table-id" className={this.props?.tableClassName}>
							<thead>
							<tr>
								{this.state.columns.map((col, index) => <DragDropHeader
									{...this.props}
									key={col.id}
									isColumnHidden={key => this.isColumnHidden(key)}
									col={col}
									id={col.title}
									index={index}
									moveColumn={this.moveColumn}
								/>)}
							</tr>
							</thead>
							<tbody>
							{this.renderTableBody()}
							</tbody>
						</Table>
					</Card.Body>
					<div style={{padding: "10px"}}>
						<PageSizeMenu
							element={pageSizeElement}
							show={this.state.showPageSizeMenu}
							onChangePageSize={(pageSize) => this.changePageSize(pageSize)}
							onShow={(show) => this.setState({showPageSizeMenu: show})}/>
						{this.renderPagination()}
					</div>
				</Card>
			</DndProvider>
		);
	}

	renderPagination() {
		return (
			<Row>
				<Col>
					{ this.renderPageSizeOptions() }
				</Col>
				<Col>
					<Pagination page={this.props.page} pages={this.state.pages} onPageChanged={this.onPageChanged} />
				</Col>
			</Row>
		)
	}

	renderPageSizeOptions() {
		if (this.features.hidePageSizeOptions) {
			return <Fragment/>
		}
		const onShow = () => this.setState({showPageSizeMenu: !this.state.showPageSizeMenu});
		return (
			<div>
				<Button size="sm" variant="light" ref={this.pageSizeMenu} onClick={() => onShow()} >
					Показать {this.state.pageSize} записей
				</Button>
			</div>
		);
	}

	changePageSize(pageSize) {
		this.setState({pageSize: pageSize}, async () => {

			const params = {
				search: this.props.search,
				page: this.props.page,
				pageSize: this.state.pageSize,
				sort: this.props.sort,
				...await this.getFilter()}
			this.props.filterTabs
			&& (params[this.props.defaultFilterParam] = this.getFilterTabsValue())

			this.fetch(params)
				.then(response => {
					localStorage.setItem("pageSize", pageSize);
					this.setState({
						data: response.data,
						pages: response.pages,
						loading: false
					});
				})
		});
	}

	setColumnsSizes() {
		const columnsWidth = localStorageService.extract(this.props.title, "columnsWidth")
		if(columnsWidth) {
			this.setState({columnsWidth: columnsWidth})
			this.setState({sumWidth: this.visibleColumnsSumWidth()})
		}
	}

	async updateIsFiltered() {
		const filter = await this.getFilter();
		const isFiltered = Object.keys(filter).length > 0;
		this.setState({ isFiltered });
	}

	async componentDidMount() {
		await this.props.filterTabs && this.setState({activeFilterTab: this.getFilterTabsKey()})

		this.setColumnsSizes()

		const columns = this.props.columns || []
		this.setState({columns: this.getSortedColumns(columns)})

		document.addEventListener('click', this.handleClickOutside);
		this.props.contextMenuObj && document.addEventListener('mousedown', this.handleClickOutside);
		this.load(true, true)
		if (this.props.resizable) {
			this.enableResize();
		}
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {
		// save scroll position
		const dataTableCard = this.dataTableCard.current;
		return {
			scrollWidth: dataTableCard.scrollWidth,
			scrollLeft: dataTableCard.scrollLeft
		};
	}

	getSortedColumns(columns) {
		const order = localStorageService.extract(this.props.title, 'columnsOrder');
		if(order) {
			const orderMap = order.reduce((acc, item, index) => {
				acc[item] = index;
				return acc;
			}, {});

			return columns
				.filter(column => !this.isColumnHidden(column.key || column.field))  // Фильтруем скрытые колонки
				.sort((a, b) => orderMap[a.title] - orderMap[b.title]);
		}
		return columns.filter(column => !this.isColumnHidden(column.key || column.field));  // Возвращаем отфильтрованные колонки
	}

	setSortedColumns() {
		const sortedColumns = this.getSortedColumns(this.props.columns)
		this.setState({columns: sortedColumns })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.title !== this.props.title) {
			this.props.filterTabs && this.setState({activeFilterTab: this.getFilterTabsKey()})
			this.load(true).then(() => {
				this.setSortedColumns()
				this.setColumnsSizes()
				this.setState({data: []})
			})
		}
		const doReload = prevProps.filter !== this.props.filter
			|| prevProps.sort !== this.props.sort
			|| prevProps.page !== this.props.page
			|| prevProps.search !== this.props.search
		if (doReload) {
			this.load(true);
		}
		if (this.props.resizable) {
			if (this.state.resizer) {
				this.state.resizer.reset({ disable: true });
			}
			this.enableResize();
		}

		// to preserve scroll position (in width) after re-render
		if (snapshot !== null) {
			const dataTableCard = this.dataTableCard.current;
			dataTableCard.scrollLeft = dataTableCard.scrollWidth - (dataTableCard.scrollWidth - snapshot.scrollLeft);
		}

		if (!Util.deepEquals(prevProps.specialFilter, this.props.specialFilter)) {
			this.props.specialFilter.map(it => this.props.dispatch({ type: DATA_TABLE_FILTER, payload: it }));
		}
	}

	visibleColumnsSumWidth() {
		let sum = 0;
		const columnsWidth = localStorageService.extract(this.props.title, "columnsWidth")

		this.getSortedColumns(this.props.columns).map((col, index) => {
			const key = col.key || col.field;
			!this.isColumnHidden(key) && (sum += columnsWidth[index])
		})

		return sum
	}

	handleResize(event) {
		let columnsWidth = [];
		this.state.columns.map((col, index) => {
			if(col.field) {
				const docCol = document.querySelector(`table .${col.field}`)
				docCol && columnsWidth.push(docCol.offsetWidth)
			}
		})
		localStorageService.updateObject(this.props.title, "columnsWidth", columnsWidth)
	}

	handleOnResize(event) {
		if(this.state.columnsWidth) {
			this.setState({columnsWidth: null})
		}
	}


	enableResize() {
		const options = {
			resizeMode: "overflow",
			liveDrag: true,
			draggingClass: "rangeDrag",
			onDrag: this.handleOnResize,
			onResize: this.handleResize,
			widths: [...this.state.columnsWidth || []]
		};
		this.state.resizer = new ColumnResizer(ReactDOM.findDOMNode(this).querySelector("#data-table-id"), options);
	}

}

const DataTableWithStoreProps = connect(DataTable.mapStateToProps)(DataTableWithStore);
export default connect(DataTable.mapStateToProps)(DataTable);
