import {axios} from '../utils/api/axios';

class VehicleModelService {

    constructor(server) {
        this.server = server;
    }
    static BASE_PATH = "/control-panel/vehicle-model";

    async list(params) {
        const response = await axios.post(`${VehicleModelService.BASE_PATH}/list`, params);
        return response.data;
    }

    async read(id) {
        const response = await axios.get(`${VehicleModelService.BASE_PATH}/${id}`);
        return response.data;
    }

    async save(params) {
        const call = params.id ? axios.put : axios.post;
        const response = await call(`${VehicleModelService.BASE_PATH}/`, params);
        return response.data;
    }

    async options(markId) {
        const response = await axios.get(`${VehicleModelService.BASE_PATH}/options/${markId}`);
        return response.data;
    }
}

export default new VehicleModelService();