import {axios} from '../utils/api/axios';

class ContractService {

	async list(params) {
		const response = await axios.post("/api/control-panel/contracts/list", params);
		return response.data;
	}

    async save(contract) {
		const call = contract.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/contracts", contract);
 		return response.data;
    }

	async saveAll(contracts) {
		const response = await axios.post("/api/control-panel/contracts/saveAll", contracts);
		return response.data;
	}

	async delete(id) {
		const response = await axios.post('/api/control-panel/contracts/delete/' + id);
 		return response.data;
    }

    async getInitNumber() {
		const response = await axios.get("/api/control-panel/contracts/init-number");
 		return response.data;
    }

	async options(contractorId, contractorTypeId, organizationId) {
		const response = await axios.post(`/api/control-panel/contracts/options`, {contractorId, contractorTypeId, organizationId});
		return response.data;
	}

	async getDefaultContract(contractorId, contractorTypeId, organizationId) {
		const response = await axios.post(`/api/control-panel/contracts/default`, {contractorId, contractorTypeId, organizationId});
		return response.data;
	}

	async read(id) {
		const response = await axios.get(`/api/control-panel/contracts/read/` + id);
		return response.data;
	}

	async getCounter(id) {
		const response = await axios.get(`/api/control-panel/contracts/counter/` + id);
		return response.data;
	}
	
}

export default new ContractService();