import "bootstrap/dist/css/bootstrap.min.css";
import {ToastProvider} from "./app/ToastProvider/ToastProvider";
import 'react-toastify/dist/ReactToastify.css';
import {extendGlobals} from "./helpers/globalArrayExtension/globalArrayExtension";
import './App.css';
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useSocket} from "./hooks/useSocket";
import debounce from "lodash.debounce";
import useSharedWorker from "./hooks/useSharedWorker";
import {Route, Switch} from "react-router-dom";
import Login from "./components/Login";
import Registration from "./components/registration/Registration";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Layout from './layout/Layout';
import SimpleElectronicSignature from "./SimpleElectronicSignature";

function App() {
    extendGlobals();

    const user = useSelector(state => state.auth.user);
    const socket = useSelector(state => state.socket.socket);

    const [isConnected, setIsConnected] = useState(true);
    const timeoutId = useRef(null);

    useEffect(() => {
        if (!user) return;

        useSocket()
        document.addEventListener('visibilitychange', handleVisibilityChanges);

        return () => {
            if (socket) {
                socket.disconnect();
            }
            document.removeEventListener('visibilitychange', handleVisibilityChanges);
        }
    }, [user]);

    useEffect(() => {
        if(!socket) return;
        listenNotificationUpdates();
    }, [socket]);

    const handleVisibilityChanges = async () => {
        if (document.visibilityState === 'hidden') {
            const timeout = setTimeout(() => {
                setIsConnected(false);

                if (socket) {
                    socket.disconnect();
                }
            }, 5000);

            timeoutId.current = timeout
        } else {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            if (!isConnected) {
                setIsConnected(true);
                useSocket();
            }
        }
    }

    const onWorkerMessage = debounce((message) => {
        const {sharedWorker} = useSharedWorker();
        const type = message.type;
        const data = JSON.parse(message?.data);

        if (type === "notification") {
            const status = data.status;
            const auctionId = data.id;
            sharedWorker.port.postMessage({type, message: {status}, auctionId});
        }
    }, 1000);

    const listenNotificationUpdates = () => {
        const userId = user?.id;
        socket.onNotification(userId, onWorkerMessage);
    }

    return (
        <div className="App">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path={"/simple-electronic-signature"} component={SimpleElectronicSignature}/>
                <Route path="/" component={Layout} />
            </Switch>

            <ToastProvider/>
        </div>
    );
}

export default App;
