import BaseForm from "../../form/BaseForm";
import {Card, Col, Row, Form, Button} from "react-bootstrap";
import React from "react";
import {connect} from "react-redux";
import commentService from "../../../services/tasktracker/CommentService";
import moment from "moment/moment";
import CommentModal from "./CommentModal";
import {securityService} from "../../../services/SecurityService";
import TinyEditor from "../TinyEditor";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../DraftEditor/TextEditor";

function mapStateToProps(state) {
    return {
        comments: state.model.comments,
        task: {
            id: state.model.id,
            value: state.model.title,
        },
        user: { id: state.user.id, value: state.user.fio },
        errors: state.errors
    };
}

class CommentForm extends BaseForm {
    static FIELD_ID = "id";
    static FIELD_UPDATED_AT = "updatedAt";
    static FIELD_TASK_ID = "taskId";
    static FIELD_OWNER = "owner";
    static FIELD_MESSAGE = "message";

    constructor(props) {
        super(props);
        this.state = { showCommentModal: false, editModel: {}, editIndex: null};

        this.listRef = React.createRef();
        this.handleAddComment = this.handleAddComment.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
    }

    componentDidMount() {
        this.scrollToBottom();
        this.setState({message: '<p></p>'})
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        this.listRef.current.scrollTop = this.listRef.current.scrollHeight;
    }

    onSave(index, message) {
        this.setSending(true);
        const comments = this.props.comments;
        const comment = comments[index];
        comment.message = message;
        commentService.save(comment).then((response) => {
            this.setSending(false);
            comments.index = response;
            this.onChange("comments", [...comments]);
        });
    }

    handleRemove(comment, index) {
        const choice = confirm("Вы уверены, что хотите удалить комментарий?")
        if (choice) {
            this.setSending(true);
            commentService.delete(comment.id).then(() => {
                this.setSending(false);
                const comments = this.props.comments;
                comments.removeAt(index);
                this.onChange("comments", [...comments]);
            });
        }
    }

    async handleAddComment() {
        if (this.state.message?.length === 0) {
            return;
        }
        typeof this.state.message != "string" && await this.setState({message: draftToHtml(convertToRaw(this.state.message.getCurrentContent()))})
        const comments = this.props.comments || [];
        const comment = {
            [CommentForm.FIELD_TASK_ID]: this.props.task.id,
            [CommentForm.FIELD_OWNER]: this.props.user,
            [CommentForm.FIELD_MESSAGE]: this.state.message
        }
        comments.push(comment);
        this.onChange("comments", [...comments]);
        commentService.save(comment).then(response => {
            const index = comments.indexOf(comment);
            this.onChange(this.formatFieldName(CommentForm.FIELD_ID, index), response.id);
            this.onChange(this.formatFieldName(CommentForm.FIELD_UPDATED_AT, index), response.updatedAt);
            this.setState({message: ""});
        })
    }

    handleEditorChange = (content, editor) => {
        this.setState({message: content});
    };

    formatFieldName(field, index) {
        return `${this.getFieldNamePrefix(index)}.${field}`;
    }

    getFieldNamePrefix(index) {
        return `comments.${index}`;
    }

    showCommentModal(show, comment, index) {
        this.setState({showCommentModal: show, editModel: comment, editIndex: index});
    }

    onEditorStateChange (editorState) {
        this.setState({message: editorState})
    };

    renderCommentEditor() {
        return (
            <div>
                <Row className={"d-flex mt-5 mr-2 ml-2 border-top"}>
                    <Col className={"mt-3 mb-3"}>
                        <TextEditor value={this.state.message} onEditorStateChange={this.onEditorStateChange} emogi={true}/>
                    </Col>
                </Row>
                <Row className={"justify-content-center"}>
                    <Button style={{width:"95%"}} className={"mr-1"} onClick={this.handleAddComment}>Отправить</Button>
                </Row>
            </div>
        );
    }

    compare(it1, it2) { //ASC
        if (it1 > it2) return 1;
        if (it1 === it2) return 0;
        if (it1 < it2) return -1;
    }

    renderComment(comment, index) {
        const isAdmin = securityService.isAdmin();
        const isOwner = this.props.user.id === comment.owner.id;
        return (
            <Row>
                <i className="fa fa-user-circle task-comment-icon-margin" aria-hidden="true"></i>&nbsp;
                {comment.owner.value}
                <Col>
                    {isAdmin &&
                        <i className="fa fa-times icon-float icon-hover"
                           aria-hidden="true"
                           role={"button"}
                           onClick={() => this.handleRemove(comment, index)} />
                    }
                    {(isAdmin || isOwner) &&
                        <i className="fa fa-pencil-alt icon-float icon-hover"
                           aria-hidden="true"
                           role={"button"}
                           onClick={() => this.showCommentModal(true, comment, index)}>
                            &nbsp;&nbsp;
                        </i>
                    }
                </Col>
            </Row>
        );
    }

    renderListComments() {
        const comments = this.props.comments || [];
        return comments.sort((it1, it2) => this.compare(it1.id, it2.id))
            .map((comment, index) => {
                return (
                    <Card className={"task-comment mt-3"} key={comment.id}>
                        <Card.Header as="h6">
                            {this.renderComment(comment, index)}
                        </Card.Header>
                        <Card.Body>
                            <Card.Text className={"h6 font-weight-normal"}>
                                <div dangerouslySetInnerHTML={{ __html: comment.message }}></div>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            Изменено: {moment(comment.updatedAt).format("DD.MM.yyyy HH:mm")}
                        </Card.Footer>
                    </Card>
                );
            });
    }

    renderEditForm() {
        return (
            <CommentModal show={this.state.showCommentModal}
                          onSave={(index, message) => this.onSave(index, message)}
                          onHide={() => this.showCommentModal(false)}
                          model={this.state.editModel}
                          index={this.state.editIndex} />
        );
    }

    renderCommentForm() {
        return (
            <Form>
                <div style={{maxHeight: "500px", overflowX: "auto"}} ref={this.listRef}>
                    {this.renderListComments()}
                </div>
                {this.renderCommentEditor()}
            </Form>
        );
    }

    render() {
        return (
            <div style={{minWidth: "100%"}}>
                <Row className={"mt-1 ml-4 mb-4"}>
                    <h5 style={{maxHeight: "5px"}}>Комментарии</h5>
                </Row>
                {this.renderCommentForm()}
                <hr className={"mt-3"}/>
                {this.renderEditForm()}
            </div>
        );
    }
}
export default connect(mapStateToProps)(CommentForm);