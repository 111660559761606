import {axios} from '../utils/api/axios';

class NoticesService {

    async listOfNotices(data) {
        const response = await axios.post(`/api/v1/notification/list`, data);
        return response.data;
    }

    async getNotice(id) {
        const response = await axios.get(`/api/v1/notification/${id}`);
        return response.data;
    }

    async deleteNotice(id){
        const response = await axios.delete(`/api/v1/notification/${id}`)
        return response.data
    }

    async addToArchive(id) {
        const response = await axios.put(`/api/listOfMessages`, id)
        return response.data
    }

    async updateClassification(data) {
        const response = await axios.put(`/api/v1/notification/update/classification`, data)
        return response.data
    }

    async createNotice(data) {
        const response = await axios.post(`/api/v1/notification`, data);
        return response.data
    }
}

export default new NoticesService();