import {axios} from '../utils/api/axios';

class PositionHistoryService {

    async getUserPositionHistory(crmUserId) {
        const response = await axios.get(`/api/position-history/${crmUserId}`);
        return response.data;
    }

    async updatePositionHistory(data) {
        const response = await axios.put(`/api/position-history`, data);
        return response.data;
    }

    async addPositionHistory(data) {
        const response = await axios.post(`/api/position-history`, data);
        return response.data;
    }

    async deletePositionHistory(crmUserId) {
        const response = await axios.delete(`/api/position-history/${crmUserId}`);
        return response.data;
    }

    async getCurrentHeadOfDepartment(departmentId) {
        const response = await axios.get(`/api/position-history/head-of-department/${departmentId}`);
        return response.data;
    }

    async getCurrentUserDepartment(crmUserId) {
        const response = await axios.get(`/api/position-history/department/${crmUserId}`);
        return response.data;
    }
}

export default new PositionHistoryService();