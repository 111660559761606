import {Reducer} from "redux"
import {CriticalErrorState} from "./types/stateTypes";
import {CriticalErrorActions} from "./types/actionsTypes";

export const initialState: CriticalErrorState = {
    error: null
}

const reducer: Reducer<CriticalErrorState> = (
    state: CriticalErrorState = initialState,
    action
) => {
    const {
        type,
        payload
    } = action as CriticalErrorActions;

    switch (type) {
        case "@@criticalError/SET_ERROR":
            return {
                ...state,
                error: payload.error
            }
        default:
            return state;
    }
}

export default reducer;