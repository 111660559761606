import {axios} from '../utils/api/axios';

class ScriptService {

    async changePassword(type) {
        const response = await axios.put(`/api/v1/script/user/change-password?crmUserType=${type}`);
        return response.data;
    }
}

export default new ScriptService();
