import {axios} from '../utils/api/axios';

class DriverService {

	async info(id) {
		const response = await axios.get(`/api/drivers/${id}/info`);
		return response.data;
	}

	async options(params) {
		const response = await axios.post("/api/drivers/options", {...params, pageSize: 300, sort: {field: "fio"}});
		return response.data.data;
	}
	
	async list(params) {
		const response = await axios.post("/api/control-panel/drivers/list", params);
		return response.data;
	}
	
	async read(id) {
		const response = await axios.get(`/api/control-panel/drivers/${id}`);
		return response.data;
	}

	async save(driver) {
		const call = driver.id ? axios.put : axios.post,
			response = await call("/api/control-panel/drivers", driver);
		return response.data;
	}
	
	async isLicenseUnique(id, license) {
		const response = await axios.post(`/api/drivers/license/unique`, {id, value: license});
		return response.data;
	}

	async findBoundUserId(id) {
		const response = await axios.get(`/api/control-panel/drivers/${id}/user-id`);
		return response.data;
	}

	async checkBlacklistDriver(id, passportNumber) {
		const response = await axios.post(`/api/drivers/check-blacklist`, {id, value: passportNumber});
		return response.data;
	}

	async checkDriverIsFree(quoteId, driverId, start, end ) {
		const data = { quoteId, driverId, period: {start, end} };
		const response = await axios.post(`/api/driver/is-free`, data);
		return response.data;
	}
}

export default new DriverService();