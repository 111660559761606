import {TOKEN_KEY} from "../../utils/consts/localStorageConsts";

interface Tokens {
    accessToken: string;
}

export const setTokens = (tokens: Tokens): void => {
    const {accessToken} = tokens;
    localStorage.setItem(TOKEN_KEY, accessToken);
};

export const getAccessToken = (): string => {
    return localStorage.getItem(TOKEN_KEY)
}

export const removeAuthData = (): void => {
    localStorage.removeItem(TOKEN_KEY);
};

export const localStorageService = {
    setTokens,
    getAccessToken,
    removeAuthData,
};
