import {StrictMode} from 'react';
import './index.css';
import App from './App';
import {Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import {createBrowserHistory} from "history";
import 'bootstrap/dist/css/bootstrap.min.css';
import {createRoot} from 'react-dom/client';
import ErrorBoundary from "./app/ErrorBoundary/ErrorBoundary";

export const history = createBrowserHistory();

const root = createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
        <Router history={history}>
            <Provider store={store}>
                <ErrorBoundary>
                    <App/>
                </ErrorBoundary>
            </Provider>
        </Router>
    </StrictMode>
)



