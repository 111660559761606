import {axios} from '../utils/api/axios';

class AddressService {
	constructor() {
		this.cache = {};
	}

    async save(address) {    	
       	const call = address.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/addresses", address);
 		return response.data;
    }

	async options(params) {
		const key = params.contractorId;
		if (this.cache[key]) {
			return this.cache[key];
		}
		const response = await axios.post("/api/control-panel/addresses/options", params);
		this.cache[key] = response.data;
		return response.data;
	}

	clearCache(contractorId) {
		if (this.cache[contractorId]){
			delete this.cache[contractorId];
		}
	}

	isFilteredAddress(it, region, city , params) {
		return (region
			&& ((it?.cityDto?.value === ' ' && !city) || city === (it?.cityDto?.value) && region === it?.region?.value))
				|| params?.region?.value
				|| (params.city === it.city && !it?.cityDto)
	}

	async addresses(params) {
		const options = await this.options(params);
		const elementsArray = params.city.split(',').map(element => element.trim());

		let region, city;

		if (elementsArray.length === 1) {
			[region] = elementsArray;
		} else if (elementsArray.length === 2) {
			[region, city] = elementsArray;
		}

		return options
			.filter(it => (this.isFilteredAddress(it, region, city, params)))
			.map(it => {
				return {
					id: it.id,
					value: it.address,
					latitude: it.latitude,
					longitude: it.longitude
				}
			})
	}

	async regions(params) {
		const options = await this.options(params);
		const result = [];
		const uniqueNames = [];
		options.forEach(it => {
			let region
			if(it.region) {
				region = (it.cityDto?.value !== ' ' && it.cityDto?.value)
					? `${it.region.value}, ${it.cityDto?.value}`
					: `${it.region.value}`
			} else {
				region = it.city
			}
			if (uniqueNames.indexOf(region) === -1) {
				uniqueNames.push(region);
				result.push({
					id: it.id,
					value: region
				});
			}
		});
		return result;
	}

	async delete(contractorId, addressId) {
		const response = await axios.delete(`/api/control-panel/addresses/${contractorId}/${addressId}`);
 		return response.data;
    }

	async fillInISOCodesOfClientContractors() {
		const response = await axios.get(`/api/control-panel/addresses/fillEmptyIsoCodesOfClientContractor`);
		return response.data;
    }

	async getAddressDetails(data) {
		const response = await axios.post("/api/control-panel/addresses/details", data);
		return response.data
	}

	async merge(params) {
		const response = await axios.post("/api/control-panel/addresses/merge", params);
		return response.data;
	}

	async getTypedAddressOptions(substring){
		const response = await axios.get(`/api/control-panel/addresses/typed/options/${substring}`);
		return response.data;
	}

}

export default new AddressService();