import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import {Badge, Nav} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import {
	securityService,
	ADMIN,
	COMMERCIAL,
	LOGISTIC,
	HEAD_OF_DEPARTMENT,
	DOCUMENTS,
	APPROVAL,
	CLIENT,
	CARRIER,
	DRIVER,
	SUPERVISOR,
	REGIONAL_DIRECTOR,
	GUEST
} from "../services/SecurityService";
import { withRouter, matchPath } from "react-router";
import LogisticReportTable from "../components/table/LogisticReportTable";
import taskService from "../services/tasktracker/TaskService";
import {ExternalLinks} from "../utils/consts/Const";
import configService from "../services/ConfigService";
import {Forwarder} from "../utils/consts/Const"
class Sidebar extends PureComponent {

	static SIDEBAR_MINIMIZED = "SIDEBAR_MINIMIZED";
	static KNOWLEDGE_BASE = "KNOWLEDGE_BASE";
	static TECHNICAL_SUPPORT = "TECHNICAL_SUPPORT";

	constructor(props) {
		super(props);
		this.state = {
			isMinimized: localStorage.getItem(Sidebar.SIDEBAR_MINIMIZED) === "true",
			items: this.prepareSidebarItems(),
			activeCollapseKey: '',
			numberOfTasks: null
		}
	}

	prepareSidebarItems() {
		return sidebarItems.map(
			category => ({
				...category,
				items: category.items.map(
					item => ({
						...item,
						allChildrenPaths: this.getPathsRecursive(item),
					})
				)
			})
		);
	}

	getPathsRecursive(item) {
		let paths = [];
		if (item.path) {
			paths.push(item.path);
		}
		if (item.items && item.items.length) {
			paths = paths.concat(item.items.flatMap(subItem => this.getPathsRecursive(subItem)));
		}
		return paths;
	}

	isActive(item) {
		return !!matchPath(this.props.location.pathname, {
			path: item.allChildrenPaths,
		});
	}

	isOpened(key) {
		return this.state.activeCollapseKey && this.state.activeCollapseKey !== -1 && this.state.activeCollapseKey === key;
	}

	handleActiveCollapseKeyChange(activeCollapseKey) {
		if (this.state.activeCollapseKey === activeCollapseKey) {
			activeCollapseKey = -1;
		}
		this.setState({ activeCollapseKey: activeCollapseKey });
	}

	onToggle() {
		this.setState({
			isMinimized: !this.state.isMinimized
		}, () => localStorage.setItem(Sidebar.SIDEBAR_MINIMIZED, this.state.isMinimized));
	}

	componentDidMount() {
		this.updateNumberOfTasks();
		configService.load().then(config => {
			this.setState({profile: config.profile});
		});
	}

	render() {
		return (
			<Nav as="ul" className={`navbar-nav navbar-nav-font sidebar sidebar-dark accordion ${this.state.isMinimized ? 'toggled' : ''}`} activeKey={location.pathname}>
				{this.state.profile === 'qa'
					? <h3 style={{color: 'red', padding: "5px", "text-align": "center"}}>Тестовый стенд</h3>
					: <a className="sidebar-brand d-flex align-items-center justify-content-center " style={{flexDirection: "column"}} href="/">
						<div className="sidebar-brand-icon"></div>
					</a>
				}
				<hr className="sidebar-divider" />
				{this.state.items.map((category, categoryIndex) => this.renderCategory(category, categoryIndex))}
				{this.renderLogisticReport()}
			</Nav>
		);
	}

	renderCategory(category, categoryIndex) {
		if (!this.checkRoles(category)) {
			return;
		}
		return (
			<React.Fragment key={categoryIndex}>
				{category.label && 
					<div className="sidebar-heading">
						{category.label}
					</div>
				}
				{category.items.filter(i => this.checkRoles(i)).map((item, itemIndex) => {
					
					const key = `${categoryIndex}-${itemIndex}`;
					return (
						<li key={key} className={`nav-item ${this.isActive(item) ? 'active' : ''} ${item.items ? '' : 'simple'}`}>
							{item.items ? this.renderSubMenu(item, key) : this.renderSimpleLink(item, key)}
						</li>
					);
				})}
				<hr className="sidebar-divider d-none d-md-block" />
			</React.Fragment>
		);
	}


	updateNumberOfTasks() {
		taskService.numberOfTasks(this.props.user.id)
			.then(response => this.setState({numberOfTasks: response}));
	}

	renderSimpleLink(item, key) {
		if (item.component) {
			if (item.urlLink){
				return (
					<div style={{cursor:"pointer"}} className="nav-link" onClick={() =>
							window.open(item.urlLink, '_blank').focus()}>
							{item.icon && <i className={`fas fa-fw fa-${item.icon}`}></i>}
							<span>{item.label}</span>
					</div>
				);
			}
		}
		if (!item.path) {
			return;
		}
		return (
			<NavLink to={item.path && item.readOnly ? "#" : item.path} className="nav-link navbar-nav-font" key={key} onClick={() => this.handleActiveCollapseKeyChange(key)}>
				{item.icon && <i className={`fas fa-fw fa-${item.icon}`}></i>}
				<span>{item.label}</span>
				&nbsp;
				{item.label === 'Задачи' && this.state.numberOfTasks &&
					<Badge>{this.state.numberOfTasks?.untestedTasks}/{this.state.numberOfTasks?.reviewedTasks}</Badge>
				}
			</NavLink>
		)
	}

	renderSubMenu(item, key) {
		if (!this.checkRoles(item)) {
			return;
		}
		return (
			<React.Fragment>
				<Nav.Link variant="link" key={key} onClick={() => this.handleActiveCollapseKeyChange(key)} data-toggle="collapse" className={`${this.isOpened(key) ? '' : 'collapsed'}`}>
					{item.icon && <i className={`fas fa-fw fa-${item.icon}`}></i>}
					<span>{item.label}</span>
				</Nav.Link>

				<div className={`collapse ${this.isOpened(key) ? 'show' : ''}`}>
					<div className="bg-white py-2 collapse-inner rounded">
						{item.items.filter(i => this.checkRoles(i)).map((subCategory, subCategoryIndex) => {
							return (
								<React.Fragment key={`${key}-${subCategoryIndex}`}>
									{subCategory.label ? <h6 className="collapse-header">{subCategory.label}</h6> : null}
									{subCategory.items.filter(si => this.checkRoles(si)).map((subCategoryItem, subCategoryItemIndex) => {
										const linkKey = `${key}-${subCategoryIndex}-${subCategoryItemIndex}`;
										return <NavLink className="collapse-item" to={subCategoryItem.path} key={linkKey} onClick={() => this.state.isMinimized ? this.handleActiveCollapseKeyChange(key) : null}>{subCategoryItem.label}</NavLink>
									})}
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</React.Fragment>
		)
	}

	renderLogisticReport() {
		if (this.props.user?.roles?.indexOf(LOGISTIC) < 0 || !securityService.forwarderIsArttek(Forwarder.ARTTEK())) {
			return (<></>);
		}
		return <LogisticReportTable isMinimized={this.state.isMinimized}/>
	}

	checkRoles(entry) {
		if (!securityService.forwarderIsArttek(entry.forwarder)) {
			return false;
		}
		if (entry.excludeRoles) {
			return entry.excludeRoles.every(role => !securityService.hasRoleButAngel(this.props.user, role));
		} else if (entry.exceptRoles) {
			return entry.exceptRoles.every(role => !securityService.hasRoleButAngel(this.props.user, role));
		} else if (!entry.roles) {
			return true;
		}
		return entry.roles.some(role => securityService.hasRole(this.props.user, role));
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default withRouter(connect(
	mapStateToProps
)(Sidebar));

const sidebarItems = [
	{
		label: 'Анкетирование',
		items: [
			{ path: '/survey/organization', label: 'Организации', icon: 'list-alt', roles: [GUEST, CARRIER] },
			{ path: '/survey/driver', label: 'Водители', icon: 'list-alt',  roles: [CARRIER] },
			{ path: '/survey/vehicle', label: 'Транспорт', icon: 'list-alt',  roles: [CARRIER] },
			{ path: '/survey/contact-person', label: 'Контактные лица (диспетчеры)', icon: 'list-alt',  roles: [CARRIER] },
			{ path: '/survey/list', label: 'Все анкеты', icon: 'list-alt',  roles: [ADMIN, APPROVAL] }
		],
	},
	{
		label: 'Заявки',
		excludeRoles: [GUEST],
		items: [
			{ path: '/quotes', label: 'Список заявок', icon: 'chart-area', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, LOGISTIC, COMMERCIAL, DOCUMENTS, APPROVAL, CLIENT, CARRIER, DRIVER] },
			{ path: '/monitoring', label: 'Мониторинг', icon: 'chart-line', roles: [HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, LOGISTIC] },
			{ path: '/task-tracker', label: 'Задачи', icon: 'tasks', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS, COMMERCIAL, LOGISTIC, APPROVAL], forwarder: Forwarder.ARTTEK() }
		],
	},
	{
		label: 'Биржа',
		roles: [ADMIN, GUEST, CARRIER],
		items: [
			{ path: '/free-quotes', label: 'Биржа', icon: 'list-alt', roles: [ADMIN, GUEST, CARRIER] }
		],
	},
	{
		label: "Карты",
		roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, LOGISTIC, COMMERCIAL, DOCUMENTS, APPROVAL, CLIENT, CARRIER],
		items: [{ path: '/monitoring-map', label: 'Карты', icon: 'map-marked-alt' }]
	},
	{
		label: "Отчеты",
		forwarder: Forwarder.ARTTEK(),
		roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS, LOGISTIC, COMMERCIAL, CLIENT],
		items: [
			{ path: '/report/ssp', label: 'ССП', icon: 'bars', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL, LOGISTIC] },
			{ path: '/planning-report', label: 'Планирование', icon: 'chart-pie', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS, COMMERCIAL, LOGISTIC] },
			{ path: '/operational-report', label: 'Оперативный отчёт', icon: 'chart-line',  roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL, LOGISTIC] },
			{ path: "/report/statistics", label: "Статистика", icon: "chart-bar", roles: [CLIENT, ADMIN], readOnly: true },
			{ path: "/report/kpi", label: "KPI", icon: "chart-bar", roles: [CLIENT, ADMIN], readOnly: true },
			{ path: "/report/time-management", label: "Своевременность", icon: "chart-bar", roles: [CLIENT, ADMIN], readOnly: true }
		]
	},
	{
		label: "Маршрутизация",
		roles: [ADMIN, CLIENT],
		items: [
			{ path: '/route', label: 'Построение оптимальных маршрутов', icon: 'road', roles: [ADMIN, CLIENT], readOnly: true }
		]
	},
	{
		label: 'Поддержка',
		forwarder: Forwarder.ARTTEK(),
		roles: [ADMIN, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, DOCUMENTS, APPROVAL, SUPERVISOR, REGIONAL_DIRECTOR, CARRIER, DRIVER, GUEST],
		items: [
			{ component: Sidebar.KNOWLEDGE_BASE, urlLink: ExternalLinks.KNOWLEDGE_BASE_LINK(), label: 'База знаний', icon: 'book', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS, COMMERCIAL, LOGISTIC, GUEST]},
			{ component: Sidebar.TECHNICAL_SUPPORT, urlLink: ExternalLinks.TECHNICAL_SUPPORT_LINK(), label: 'Центр поддержки', icon: 'comments', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS, COMMERCIAL, LOGISTIC]}
		]
	},
	{
		label: 'Справочники',
		roles: [ADMIN, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, DOCUMENTS, APPROVAL, SUPERVISOR, REGIONAL_DIRECTOR, CLIENT, CARRIER, DRIVER],
		items: [
			{
				label: 'Справочники',
				path: '/control-panel',
				icon: 'book',
				items: [
					{
						items: [
							{ path: '/control-panel/forwarders', label: 'Экспедиторы', roles: [ADMIN] },
							{ path: '/control-panel/organizations', label: 'Организации', roles: [ADMIN] },
							{ path: '/control-panel/contractors', label: 'Контрагенты' },
							{ path: '/control-panel/contracts', label: 'Договоры', roles: [ADMIN, DOCUMENTS, CLIENT, CARRIER] },
							{ path: '/control-panel/vehicles', label: 'Транспортные ср-ва', exceptRoles: [CLIENT] },
							{ path: '/control-panel/drivers', label: 'Водители', exceptRoles: [CLIENT] },
							{ path: '/control-panel/users', label: 'Пользователи', roles: [ADMIN] },
							{ path: '/control-panel/external-users', label: 'Внешние пользователи', roles: [ADMIN] },
							{ path: '/control-panel/products', label: 'Товары', roles: [ADMIN] },
							{ path: '/control-panel/marks', label: 'Марки ТС', roles: [ADMIN] },
							{ path: '/control-panel/models', label: 'Модели ТС', roles: [ADMIN] },
						]
					},
				]
			}
		]
	},
	{
		label: 'Метрики',
		roles: [ADMIN],
		items: [
			{
				label: 'Метрики',
				path: '/control-panel/metrics',
				icon: 'chart-bar',
				items: [
					{
						items: [
							{ path: '/control-panel/carcasses', label: 'Типы кузова' },
							{ path: '/control-panel/loading-types', label: 'Типы загрузки' },
							{ path: '/control-panel/cargo-types', label: 'Груз' },
							{ path: '/control-panel/document-types', label: 'Сопровод. документы' },
							{ path: '/control-panel/departments', label: 'Подразделения' },
							{ path: '/control-panel/divisions', label: 'Отделы' },
							{ path: '/control-panel/topics', label: 'Темы задач' },
							{ path: '/control-panel/positions', label: 'Должности' },
							{ path: '/control-panel/product-types', label: 'Категории товаорв' },
						]
					},
				]
			}
		]
	},
	{
		label: "Настройки",
		roles: [ADMIN],
		items: [
			{
				label: 'Интеграции',
				path: '/control-panel/integration',
				icon: 'users-cog',
				items: [
					{
						items: [
							{ path: '/control-panel/sl-integration', label: 'Интеграция с 1C' },
							{ path: '/control-panel/settings', label: 'Настройки' },
							{ path: '/control-panel/sms-sender-settings', label: 'Отправка SMS' }
							// TODO: { path: '/control-panel/ati', label: 'ATI' },
							// TODO: { path: '/control-panel/da-data', label: 'DaData' },
						]
					},
				]
			}
		]
	},
	{
		label: 'Инструменты',
		forwarder: Forwarder.ARTTEK(),
		roles: [ADMIN, APPROVAL, LOGISTIC, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR, COMMERCIAL],
		items: [
			{
				label: 'Инструменты',
				path: '/control-panel/tools',
				icon: 'wrench',
				items: [
					{
						items: [
							{ path: '/control-panel/alert', label: 'Оповещения', roles: [ADMIN] },
							{ path: '/control-panel/motivation', label: 'Мотивация', roles: [ADMIN, LOGISTIC, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR] },
							{ path: '/control-panel/original-addresses', label: 'Нераспознанные адреса', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR] },
							{ path: '/control-panel/normalized-original-addresses', label: 'Распознанные адреса', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, SUPERVISOR, COMMERCIAL] },
							{ path: '/control-panel/quote/process', label: 'Обработка заявок', roles: [ADMIN] },
							{ path: '/control-panel/import/supply', label: 'Импорт поставок', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR] },
							{ path: '/control-panel/export/quote-route', label: 'Экспорт рейсов', roles: [ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL] },
							{ path: '/control-panel/notification-table', label: 'Уведомления', roles: [ADMIN, HEAD_OF_DEPARTMENT] },
							{ path: '/control-panel/report/quote-status-history', label: 'Статусы заявок', roles: [ADMIN, HEAD_OF_DEPARTMENT] },
							{ path: '/control-panel/sms-table', label: 'SMS-сообщения', roles: [ADMIN] },
							{ path: '/control-panel/scripts', label: 'Скрипты', roles: [ADMIN] }
						]
					},
				]
			}
		]
	},
]