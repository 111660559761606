import BaseForm from "../../../form/BaseForm";
import {Card, Col, Row, Form, Button} from "react-bootstrap";
import React from "react";
import {connect} from "react-redux";
import moment from "moment/moment";
import { securityService } from "../../../../services/SecurityService";
import TextEditor from "../../../tasktracker/DraftEditor/TextEditor";
import quoteCommentService from "../../../../services/QuoteCommentService";
import StoreWrapper from "../../../form/StoreWrapper";
import {setToastObjAC} from "../../../../reducers/toastObj";
import "./QuoteComment.css"
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import PinButton from "./PinButton";
import {ToastService} from "../../../../services/ToastService/ToastService";

function mapGlobalStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

class QuoteComment extends StoreWrapper {
    static FIELD_ID = "id";
    static FIELD_TASK_ID = "quoteId";
    static FIELD_CRM_USER = "crmUser";
    static FIELD_TEXT = "text";
    static FIELD_CREATED = "created";

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <QuoteCommentInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.model
    }
}

class QuoteCommentInner extends BaseForm {

    constructor(props) {
        super(props);
        this.state = { pinnedComment: null };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.listRef = React.createRef();
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
    }

    render() {
        const {comments} = this.props.model;
        const commentsIsNull = !comments || comments.length === 0
        return (
            <div className="fullHeightContainer">
                {commentsIsNull && <h5 className={"text-center grow mt-5"}>Здесь пока нет комментариев</h5>}
                <div className="grow" ref={this.listRef}>
                    {this.renderPinnedComment()}
                    {this.renderListComments()}
                </div>
                <div className="footer">
                    {this.renderCommentEditor()}
                </div>
            </div>
        );
    }

    renderPinnedComment() {
        const {pinnedComment} = this.state;
        return (
            pinnedComment && (
                <div className="pinned-comment">
                    <i className="fa fa-times icon-float icon-hover"
                       aria-hidden="true"
                       role={"button"}
                       onClick={() => this.handleUnpin()}/>
                    <h5>Закреплённый комментарий</h5>
                    <span className={"color-white"}>{pinnedComment.crmUser?.value || pinnedComment.crmUser?.fio}</span>
                    <div className={"color-white"} dangerouslySetInnerHTML={{__html: pinnedComment.text}}></div>
                </div>
            )
        );
    }

    async handleUnpin() {
        const {pinnedComment} = this.state;
        if (!pinnedComment) {
            return;
        }
        await quoteCommentService.unpinComment(pinnedComment.id);
        this.setState({pinnedComment : null});
        this.showToast("Комментарий откреплён");
    }

    renderListComments() {
        const comments = this.props.model.comments || [];
        const sorted = comments.sort((it1, it2) => this.compare(it1.id, it2.id));
        return (
            <Form>
                {sorted.map((comment, index) => this.renderCommentCard(comment, index))}
            </Form>
        );
    }

    renderCommentCard(comment, index) {
        return (
            <Card className="mt-3" key={comment.id}>
                <Card.Header as="h6">
                    {this.renderUser(comment)}
                </Card.Header>
                <Card.Body>
                    <Card.Text className={"h6 font-weight-normal"}>
                        <div dangerouslySetInnerHTML={{__html: comment.text}}></div>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    Добавлен: {moment(comment.created).format("DD.MM.yyyy HH:mm")}
                </Card.Footer>
            </Card>
        );
    }

    renderCommentEditor() {
        return (
            <div>
                <Row className={"d-flex mt-5 mr-2 ml-2 border-top"}>
                    <Col className={"mt-3 mb-3"}>
                        <TextEditor value={this.state.text} onEditorStateChange={this.onEditorStateChange} emogi={true}/>
                    </Col>
                </Row>
                <Row className={"justify-content-center"}>
                    <Button style={{width: "95%"}} className={"mr-1"} onClick={this.handleSubmit}>Отправить</Button>
                </Row>
            </div>
        );
    }

    compare(it1, it2) { //ASC
        if (it1 > it2) return 1;
        if (it1 === it2) return 0;
        if (it1 < it2) return -1;
    }

    renderUser(comment) {
        return (
            <Row>
                <span>
                    <i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp;
                    {comment.crmUser.value || comment.crmUser.fio}
                </span>
                <PinButton handleClick={(e) => this.handlePinComment(e, comment)}/>
            </Row>
        );
    }

    async componentDidMount() {
        await this.initialize();
    }

    async initialize() {
        this.scrollToBottom();
        await this.initEnvironments();
    }

    async initEnvironments() {
        this.setState({text: '<p></p>'});
        this.onChange("comments", await this.loadComments());
    }

    async loadComments() {
        const {quoteId} = this.props;
        if (!quoteId) {
            return [];
        }
        const comments = await quoteCommentService.listByQuoteId(quoteId);

        const pinnedComment = comments.find(comment => comment.pinned === true);
        this.setState({pinnedComment: pinnedComment});

        return comments;
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        this.listRef.current.scrollTop = this.listRef.current.scrollHeight;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(async () => {
            const comment = this.createComment();
            if (!comment) {
                return;
            }

            const response = await quoteCommentService.create(comment);
            this.afterSubmit(comment, response);
        });
    }

    createComment() {
        const {text} = this.state;
        if (text?.length === 0) {
            return {};
        }

        return {
            [QuoteComment.FIELD_TASK_ID]: this.props.quoteId,
            [QuoteComment.FIELD_CRM_USER]: securityService.getUser(),
            [QuoteComment.FIELD_TEXT]: draftToHtml(convertToRaw(text.getCurrentContent()))
        }
    }

    afterSubmit(comment, response) {
        this.pushComment(comment);
        this.setState({text: ""});

        const index = this.props.model.comments.indexOf(comment);
        this.onChange(this.formatFieldName(QuoteComment.FIELD_CREATED, index), response.created);
        this.onChange(this.formatFieldName(QuoteComment.FIELD_ID, index), response.id);

        this.showToast("Комментарий отправлен");
    }

    pushComment(comment) {
        const {comments} = this.props.model;

        comments.push(comment);
        this.onChange("comments", [...comments]);

        const {count, setCount} = this.props;
        setCount(count + 1);
    }

    formatFieldName(field, index) {
        return `${this.getFieldNamePrefix(index)}.${field}`;
    }

    getFieldNamePrefix(index) {
        return `comments.${index}`;
    }

    onEditorStateChange (editorState) {
        this.setState({text: editorState})
    };

    async handlePinComment(e, comment) {
        e.preventDefault();
        this.setState({pinnedComment: comment});
        await quoteCommentService.pinComment(this.props.quoteId, comment.id);
        this.showToast("Комментарий закреплён");
    }

    showToast(message) {
        ToastService.success({
            message: message
        });
    }

}

const QuoteCommentInnerConnected = connect(mapStateToProps)(QuoteCommentInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(QuoteComment);
